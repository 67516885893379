import React from 'react';
import ReactDOM from 'react-dom/client';
import './register.css';
import '../pages/global.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { Link, Navigate } from 'react-router-dom';
import { setUser as setTSUser } from '../../models/user';
import CustomScrollbar from '../customScrollbar/customScrollbar';
import GoogleButton from 'react-google-button';

type registerProps = {
  signup: Function;
  googleLogin: Function;
  googleLoginGetRedirect: Function;
};

type registerState = {
  email: string;
  emailError: string;
  twitch: string;
  twitchError: string;
  password: string;
  passwordHasUppercase: boolean;
  passwordHasLowercase: boolean;
  passwordHasNumber: boolean;
  passwordLongEnough: boolean;
  passwordError: string;
  repeatPassword: string;
  repeatPasswordError: string;
  submitting: boolean;
  formError: string;
  validated: boolean;
  redirectTo: string;
};
  

export default class Register extends React.Component<registerProps, registerState> {  
  constructor(props: registerProps) {
    super(props);

    this.state = {
      email: '',
      emailError: '',
      twitch: '',
      twitchError: '',
      password: '',
      passwordHasUppercase: false,
      passwordHasLowercase: false,
      passwordHasNumber: false,
      passwordLongEnough: false,
      passwordError: '',
      repeatPassword: '',
      repeatPasswordError: '',
      submitting: false,
      formError: '',
      validated: false,
      redirectTo: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
    this.runAllValidators = this.runAllValidators.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onTwitchChange = this.onTwitchChange.bind(this);
    this.validateTwitch = this.validateTwitch.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.onRepeatPasswordChange = this.onRepeatPasswordChange.bind(this);
    this.validateRepeatPassword = this.validateRepeatPassword.bind(this);
    this.handleGoogleSignIn = this.handleGoogleSignIn.bind(this);
  }
  
  public async componentDidMount() {
    this.props.googleLoginGetRedirect(
      () => {
        this.setState({ redirectTo: "/dashboard" });
      },
      (errorCode: any, errorMessage: any) => {
        let error = "Error (" + errorCode + "): " + errorMessage;
        this.setState({ formError: error, submitting: false });
      }
    );
  }

  public async componentDidUpdate(prevProps: registerProps) {

  }

  public async componentWillUnmount() {
    
  }

  public async onSubmit() {
    if (this.isValid()) {
      this.setState({ submitting: true, formError: '' });
      try {
        let newUser = await this.props.signup(this.state.email, this.state.password);
        let TSUser = {
          id: newUser.user.uid,
          email: this.state.email || '',
          isManager: false,
          isAdmin: false,
          twitchUsername: this.state.twitch,
          timesheetsVisited: []
        };
        await setTSUser(TSUser);
        this.setState({ redirectTo: "/confirmEmail" });
      } catch (err: any) {
        console.log(err.message);
        this.setState({ submitting: false, formError: "There was an error processing your request." });
      }
    }
  }

  public async handleGoogleSignIn(e: any) {
    await this.props.googleLogin();
  }

  public isValid() {
    let valid = this.runAllValidators();
    this.setState({ validated: true });
    if (valid) {
      return true;
    } else {
      this.setState({ formError: "Please fix invalid field(s)." });
      return false;
    }
  }

  public runAllValidators() {
    let emailValid = this.validateEmail(this.state.email);
    let twitchValid = this.validateTwitch(this.state.twitch);
    let passwordValid = this.validatePassword(this.state.password);
    let repeatPasswordValid = this.validateRepeatPassword(this.state.repeatPassword);
    return emailValid && twitchValid && passwordValid && repeatPasswordValid;
  }

  public onEmailChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let newValue = event.target.value;
    this.validateEmail(newValue);
    this.setState({ email: newValue || '' });
  }

  public validateEmail(value?: string) {
    if (value && value != "" && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      let formError = this.state.formError;
      if (this.state.twitchError == '' && this.state.passwordError == '' && this.state.repeatPasswordError == '') {
        formError = '';
      }
      this.setState({ emailError: "", formError: formError });
      return true;
    } else {
      this.setState({ emailError: "Please enter a valid email." });
      return false;
    }
  }

  public onTwitchChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let newValue = event.target.value;
    this.validateTwitch(newValue);
    this.setState({ twitch: newValue || '' });
  }

  public validateTwitch(value?: string) {
    if (value && value != "" && /^[a-zA-Z0-9][\w]{1,24}$/.test(value)) {
      let formError = this.state.formError;
      if (this.state.emailError == '' && this.state.passwordError == '' && this.state.repeatPasswordError == '') {
        formError = '';
      }
      this.setState({ twitchError: "", formError: formError });
      return true;
    } else {
      this.setState({ twitchError: "Please enter a valid twitch username." });
      return false;
    }
  }

  public onPasswordChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let newValue = event.target.value;
    this.validatePassword(newValue);
    this.setState({ password: newValue || '' });
  }

  public validatePassword(value?: string) {
    let hasLower = false;
    let hasUpper = false;
    let hasNumber = false;
    let longEnough = false;
    if (value && value != '') {
      if (/[a-z]/.test(value)) {
        hasLower = true;
      }
      if (/[A-Z]/.test(value)) {
        hasUpper = true;
      }
      if (/[0-9]/.test(value)) {
        hasNumber = true;
      }
      if (value.length >= 10) {
        longEnough = true;
      }
    }

    let isValid;
    let passwordError;
    let formError = this.state.formError;
    if (hasLower && hasUpper && hasNumber && longEnough) {
      if (this.state.emailError == '' && this.state.twitchError == '' && this.state.repeatPasswordError == '') {
        formError = '';
      }
      passwordError = '';
      isValid = true;
    } else {
      passwordError = 'Your password is invalid.';
      isValid = false;
    }

    this.setState({
      passwordHasLowercase: hasLower,
      passwordHasUppercase: hasUpper,
      passwordHasNumber: hasNumber,
      passwordLongEnough: longEnough,
      passwordError: passwordError,
      formError: formError
    });
    return isValid;
  }

  public onRepeatPasswordChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let newValue = event.target.value;
    this.validateRepeatPassword(newValue);
    this.setState({ repeatPassword: newValue || '' });
  }

  validateRepeatPassword(value?: string) {
    let formError = this.state.formError;
    
    if (value && value != '') {
      if (value == this.state.password) {
        if (this.state.emailError == '' && this.state.twitchError == '' && this.state.repeatPasswordError == '') {
          formError = '';
        }
        this.setState({ repeatPasswordError: '', formError: formError });
        return true;
      } else {
        this.setState({ repeatPasswordError: 'Passwords do not match.' });
        return false;
      }
    } else if (!this.state.password || this.state.password == '') {
      if (this.state.emailError == '' && this.state.twitchError == '' && this.state.repeatPasswordError == '') {
        formError = '';
      }
      this.setState({ repeatPasswordError: '', formError: formError });
      return true;
    } else {
      this.setState({ repeatPasswordError: 'Passwords do not match.' });
      return false;
    }
  }


  public render(): React.ReactElement<registerProps> {
    return (
      <>
      <CustomScrollbar addClass='scrollbarDefault'>
        <Container className="topPagePadding bottomPagePadding">
          <Row className="justify-content-center">
            <Col className="small floatCol">
            <h2 className='mb-4'>Register</h2>
            <Alert variant="danger" hidden={this.state.formError == ''} onClose={() => this.setState({ formError: '' })} dismissible>
                {this.state.formError}
            </Alert>
              <Form>
                <Form.Group className="loginForm mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address</Form.Label>&nbsp;&nbsp;
                  <Form.Label
                    hidden={!this.state.validated || this.state.emailError == ''}
                    className="text-muted form-text invalid">
                    {this.state.emailError}
                  </Form.Label>
                  <Form.Control type="email" placeholder="email@email.com" value={this.state.email} onChange={this.onEmailChange} />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>
                <Form.Group className="loginForm mb-3" controlId="formBasicTwitchUsername">
                  <Form.Label>Twitch Username</Form.Label>&nbsp;&nbsp;
                  <Form.Label
                    hidden={!this.state.validated || this.state.twitchError == ''}
                    className="text-muted form-text invalid">
                    {this.state.twitchError}
                  </Form.Label>
                  <div className="twitchInputContainer">
                    <div className="twitchLabel">twitch.tv/</div>
                    <div className="twitchInput"><Form.Control type="text" placeholder="MyTwitchUsername" value={this.state.twitch} onChange={this.onTwitchChange} /></div>
                  </div>
                  <Form.Text className="text-muted">
                    Enter your Twitch Username as it appears after "twitch.tv/" so that we can use that information for the events you sign up for. We will never share your twitch username with anyone (even if you want us too 😉).
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>&nbsp;&nbsp;
                  <Form.Label
                    hidden={!this.state.validated || this.state.passwordError == ''}
                    className="text-muted form-text invalid">
                    {this.state.passwordError}
                  </Form.Label>
                  <Form.Control type="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange} />
                  <Form.Text className="text-muted">
                    Your password must meet this criteria:<br/>
                    <span className={this.state.passwordHasLowercase ? "valid" : ""}>1 lowercase letter</span><br/>
                    <span className={this.state.passwordHasUppercase ? "valid" : ""}>1 uppercase letter</span><br/>
                    <span className={this.state.passwordHasNumber ? "valid" : ""}>1 number</span><br/>
                    <span className={this.state.passwordLongEnough ? "valid" : ""}>10 characters total</span>
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicRepeatPassword">
                  <Form.Label>Repeat Password</Form.Label>&nbsp;&nbsp;
                  <Form.Label
                    hidden={!this.state.validated || this.state.repeatPasswordError == ''}
                    className="text-muted form-text invalid">
                    {this.state.repeatPasswordError}
                  </Form.Label>
                  <Form.Control type="password" placeholder="Password" value={this.state.repeatPassword} onChange={this.onRepeatPasswordChange} />
                </Form.Group>
                <Button hidden={this.state.submitting} variant="primary" type="button" onClick={this.onSubmit}>
                  Submit
                </Button>
                <Button hidden={!this.state.submitting} variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
                </Button><br/><br/>
                <div>
                  <GoogleButton
                    className="g-btn"
                    type="dark"
                    onClick={this.handleGoogleSignIn}
                  />
                </div><br/>
                <Form.Text className="text-muted">
                  Do you already have an account? <Link to={{ pathname: "/login" }}>Please login here</Link>.
                </Form.Text>
              </Form>
            </Col>
          </Row>
        </Container>
        </CustomScrollbar>
        { 
          this.state.redirectTo != '' && <Navigate to={this.state.redirectTo} replace={true}/>
        }
      </>
    )
  };
};