import React from 'react';
import ReactDOM from 'react-dom/client';
import './viewLineup.css';
import '../pages/global.css';
import * as moment from 'moment-timezone'
import { Moment } from 'moment';
import { LineupGridCell } from '../pages/setLineup';
import Lineup from '../../models/userLineup';
import _, { last } from 'lodash';
import TSUser, { getUser } from '../../models/user';
import { TimeGridDataCell } from '../../models/userSignup';
import { Form } from 'react-bootstrap';
import { Timestamp } from 'firebase/firestore';
import TimeSheet, { getTimesheet, updateTimesheet } from '../../models/timesheet';
import { User } from 'firebase/auth';
import { confirmAlert } from 'react-confirm-alert';
import { Navigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import CustomScrollbar from '../customScrollbar/customScrollbar';
import TimeSheetTemplate, { getTimesheetTemplate, updateTimesheetTemplate } from '../../models/timesheetTemplate';

export interface viewLineupProps {
  user: User;
  userData: TSUser;
};
export interface viewLineupState {
  gridData: LineupGridCell[];
  timesheet: TimeSheet | null;
  userRows: number[];
  redirectTo: string;
  botText: string;
  botTextEditor: string;
  botTextHeight: number;
  isOwner: boolean;
  fromTemplate: TimeSheetTemplate | null;
  botTextSaveDisabled: boolean;
  botTextTemplateSaveDisabled: boolean;
  viewTimezone: string;
};
export default class ViewLineup extends React.Component<viewLineupProps, viewLineupState> {
  timesheetId: string | null;
  emptyLineupCell: LineupGridCell;
  dummyMoment: Moment;
  ownerData: TSUser | null;
  resizeObserver: any;
  botTextRef: any;
  userTimezone: string;
  timesheetTimezone: string;

  constructor(props: viewLineupProps) {
    super(props);
    
    this.botTextRef = React.createRef();
    this.userTimezone = moment.tz.guess();
    this.timesheetTimezone = "";

    this.dummyMoment = moment.default();
    this.ownerData = null;
    this.emptyLineupCell = {
      timeslot: this.dummyMoment,
      timeDisplay: "",
      numberPrefered: -1,
      numberAvailable: -1
    }

    this.state = {
      gridData: [],
      timesheet: null,
      redirectTo: "",
      userRows: [],
      botText: "",
      botTextEditor: "",
      botTextHeight: 0,
      isOwner: false,
      fromTemplate: null,
      botTextSaveDisabled: true,
      botTextTemplateSaveDisabled: true,
      viewTimezone: this.userTimezone
    };
    
    let urlParams = new URLSearchParams(window.location.search);
    this.timesheetId = urlParams.get('timesheetId');
    let hasTimesheetId = this.timesheetId && this.timesheetId != "";
    if (!hasTimesheetId) {
      this.alert();
    }

    this.onChangeBotTextTemplate = this.onChangeBotTextTemplate.bind(this);
    this.resetBotTextEditor = this.resetBotTextEditor.bind(this);
    this.toggleTimezone = this.toggleTimezone.bind(this);
  }
  
  public async componentDidMount() {
    if (this.props.userData.id && this.props.userData.id != "") {
      this.getData();
    }
    this.resizeObserver = new ResizeObserver((entries) => {
      if (this.botTextRef.current && this.botTextRef.current.offsetHeight != this.state.botTextHeight) {
        this.setState({ botTextHeight: this.botTextRef.current.offsetHeight });
      }
    });
    if (this.botTextRef.current) {
      this.resizeObserver.observe(this.botTextRef.current);
    }
  }

  public async componentDidUpdate(prevProps: viewLineupProps) {
    if (this.props.userData != prevProps.userData) {
      await this.getData();
    }
    if (this.botTextRef.current) {
      this.resizeObserver.observe(this.botTextRef.current);
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
  
  public alert(type: string = "") {
    if (type == "timesheet") {
      confirmAlert({
        title: 'Save Bot Text Template',
        message: 'Are you sure you want overwrite your Timesheet\'s Bot Text Template?',
        buttons: [
          {
            label: 'Save',
            onClick: () => this.saveBotTextEditorToTimesheet()
          },
          {
            label: 'Cancel',
          }
        ]
      });
    } else if (type == "template") {
      confirmAlert({
        title: 'Save Bot Text Template',
        message: 'Are you sure you want overwrite your Timesheet Template\'s Bot Text Template?',
        buttons: [
          {
            label: 'Save',
            onClick: () => this.saveBotTextEditorToTemplate()
          },
          {
            label: 'Cancel',
          }
        ]
      });
    } else if (type == "noLineupOwner") {
      confirmAlert({
        title: 'No Lineup',
        message: 'Lineup hasn\'t been set. Redirecting to Set Lineup.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => this.setState({ redirectTo: "/setLineup?timesheetId=" + this.timesheetId })
          }
        ]
      });
    } else if (type == "noLineup") {
      confirmAlert({
        title: 'No Lineup',
        message: 'Lineup hasn\'t been set. Redirecting to Signup.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => this.setState({ redirectTo: "/signup?timesheetId=" + this.timesheetId })
          }
        ]
      });
    } else {
      confirmAlert({
        title: 'Invalid ID',
        message: 'Invalid Timesheet ID. The event may have been deleted.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => this.setState({ redirectTo: "/dashboard" })
          }
        ]
      });
    }
  }

  public async getData() {
    if (this.timesheetId && this.timesheetId != "") {
      let timesheet = await getTimesheet(this.timesheetId, false, true);
      if (timesheet) {
        this.timesheetTimezone = timesheet.timezone;
        let isOwner = timesheet.owner == this.props.userData.id;
        this.ownerData = await getUser(timesheet.owner);
        let fromTemplate = null;
        if (timesheet.fromTemplate != null) {
          fromTemplate = await getTimesheetTemplate(timesheet.fromTemplate);
        }
        let lineupGrid = this.getLineupData(timesheet);
        let botText = this.getBotText(timesheet, timesheet.botTextTemplate, lineupGrid);
        let botTextEditor = timesheet.botTextTemplate;
        let botTextSaveDisabled = botTextEditor == timesheet.botTextTemplate;
        let botTextTemplateSaveDisabled = fromTemplate ? botTextEditor == fromTemplate.template.botTextTemplate : true;
        this.setState({
          gridData: lineupGrid,
          timesheet: timesheet,
          botText: botText,
          botTextEditor: botTextEditor,
          isOwner: isOwner,
          fromTemplate: fromTemplate,
          botTextSaveDisabled: botTextSaveDisabled,
          botTextTemplateSaveDisabled: botTextTemplateSaveDisabled
        });
      } else {
        this.alert();
      }
    }
  }

  private getLineupData(timesheet: TimeSheet | null): LineupGridCell[] {
    let lineupGridData: LineupGridCell[] = [];
    let userRows: number[] = [];
    if (timesheet && timesheet.start && timesheet.end) {
      let start = moment.tz(timesheet.start.toDate(), timesheet.timezone).local();
      let end = moment.tz(timesheet.end.toDate(), timesheet.timezone).local();
      let gridInterval = timesheet.interval; // time gridInterval of grid in minutes
      let numRows = (moment.duration(end.diff(start)).asMinutes() / gridInterval) + 1;

      let lastCell: LineupGridCell = this.emptyLineupCell;
      for (let i = 0; i < numRows; i++) {
        let datetime = start.clone().local();
        let time = datetime.add(i * gridInterval, 'minutes'); // .add adds time to the original datetime object
        let lineups = timesheet.lineup.lineups;
        
        let thisData: LineupGridCell = {
          timeslot: datetime,
          timeDisplay: time.tz(this.state.viewTimezone).format('h:mm a'),
          numberPrefered: -1,
          numberAvailable: -1,
        }

        let lineupFound = false;
        for (const lineup of lineups) {
          let found = _.find(lineup.timeslots, function(x: Moment | Timestamp) {
            if (x instanceof Timestamp) {
              return datetime.diff(moment.tz(x.toDate(), timesheet.timezone).local()) == 0;
            } else {
              return datetime.diff(x.local()) == 0;
            }
          });
          if (found) {
            lineupFound = true;
            thisData.userId = lineup.userId;
            thisData.userTwitch = lineup.userTwitch;
            thisData.lineupId = lineup.id;

            if (lastCell.userId && lastCell.userId != thisData.userId) {
              lineupGridData[lineupGridData.length - 1].endDisplay = time.format('h:mm a');
              lastCell = thisData;
              lineupGridData.push(thisData);
              if (thisData.userId == this.props.user.uid) {
                userRows.push(lineupGridData.length - 1);
              }
            } else if (!lastCell.userId) {
              lastCell = thisData;
              lineupGridData.push(thisData);
              if (thisData.userId == this.props.user.uid) {
                userRows.push(lineupGridData.length - 1);
              }
            }
            break;
          }
        };

        if (!lineupFound) {
          if (lastCell.userId && lastCell.userId) {
            lineupGridData[lineupGridData.length - 1].endDisplay = time.format('h:mm a');
          }
          lastCell = this.emptyLineupCell;
        }

      }
    }
    if (lineupGridData.length == 0) {
      if (timesheet?.owner == this.props.user.uid) {
        this.alert("noLineupOwner");
      } else {
        this.alert("noLineup");
      }
    } else if (userRows != this.state.userRows) {
      this.setState({ userRows });
    }
    return lineupGridData;
  }

  
  public getBotText(timesheet: TimeSheet, template: string, lineupGrid: LineupGridCell[]) {
    let botText = template;
    if (this.ownerData) {
      botText = botText.replaceAll("{OwnerUrl}", "twitch.tv/" + this.ownerData?.twitchUsername);
      botText = botText.replaceAll("{OwnerUsername}", this.ownerData?.twitchUsername);
    }
    botText = botText.replaceAll("{EventTitle}", timesheet.title);
    botText = botText.replaceAll("{Timezone}", moment.tz(moment.tz.guess()).zoneAbbr());
    let regexRepeatPerSignup = /{{RepeatPerSignup (.*)}}/gm;
    let matched;
    while(matched = regexRepeatPerSignup.exec(botText)){
      let rowTemplate = matched[1];
      let replaceText = "";
      for (let i = 0; i < lineupGrid.length; i++) {
        let thisRowText = rowTemplate;
        let lineup = lineupGrid[i];
        if (lineup.userTwitch) {
          thisRowText = thisRowText.replaceAll("{TwitchUrl}", "twitch.tv/" + lineup.userTwitch);
          thisRowText = thisRowText.replaceAll("{TwitchUsername}", lineup.userTwitch);
        }
        if (lineup.timeDisplay && lineup.endDisplay) {
          thisRowText = thisRowText.replaceAll("{StartTime}", lineup.timeDisplay);
          thisRowText = thisRowText.replaceAll("{EndTime}", lineup.endDisplay);
        }
        replaceText = replaceText + thisRowText;
        if (i < lineupGrid.length - 1) {
          //add new line if not the last line
          replaceText = replaceText + `
`;
        }
      }
      botText = botText.replace(matched[0], replaceText);
    }
    return botText;
  }

  getLineupContent() {
    let content = [];
    for (let i = 0; i < this.state.gridData.length; i++) {

      content.push(
        <tr
        data-i={i}
        key={"row" + i}
        className={"viewLineupRow time" + (this.state.userRows.includes(i) ? " currentUser" : "")}
        >

          <td id={"time" + i} className={'lineupCell timeCell'}>
            {this.state.gridData[i].timeDisplay + " - " + this.state.gridData[i].endDisplay}
          </td>

          <td id={"lineupSlot" + i} className={'lineupCell lineupSlotCell'}>
            {this.state.gridData[i].userTwitch ? this.state.gridData[i].userTwitch : ""}
          </td>

        </tr>
      );
    }
    return content;
  };

  private getStartDate() {
    if (this.state.gridData.length > 0) {
      let startDate = this.state.gridData[0].timeslot;
      let formatString = "ddd M/D/YY";
      if (startDate instanceof Timestamp) {
        return moment.default(startDate.toDate()).local().format(formatString);
      } else {
        return startDate.local().format(formatString);
      }
    } else {
      return "";
    }
  }

  public onChangeBotTextTemplate(event: any) {
    let newValue = event.target.value;
    if (this.state.timesheet) {
      let botText = this.getBotText(this.state.timesheet, newValue, this.state.gridData);
      let botTextSaveDisabled = newValue == this.state.timesheet.botTextTemplate;
      let botTextTemplateSaveDisabled = newValue == this.state.fromTemplate?.template.botTextTemplate;
      this.setState({ botTextEditor: newValue || '', botText: botText || '', botTextSaveDisabled: botTextSaveDisabled, botTextTemplateSaveDisabled: botTextTemplateSaveDisabled });
    }
  }

  public resetBotTextEditor() {
    if (this.state.timesheet) {
      let botText = this.getBotText(this.state.timesheet, this.state.timesheet.botTextTemplate, this.state.gridData);
      this.setState({ botText: botText, botTextEditor: this.state.timesheet.botTextTemplate });
    }
  }

  public async saveBotTextEditorToTimesheet() {
    this.setState({ botTextSaveDisabled: true });
    if (this.state.timesheet && this.state.timesheet.id) {
      await updateTimesheet(this.state.timesheet.id, {
        botTextTemplate: this.state.botTextEditor
      });
    }
  }

  public async saveBotTextEditorToTemplate() {
    this.setState({ botTextTemplateSaveDisabled: true });
    if (this.state.fromTemplate && this.state.fromTemplate.id) {
      await updateTimesheetTemplate(this.state.fromTemplate.id, {
        "template.botTextTemplate": this.state.botTextEditor
      });
    }
  }

  private toggleTimezone() {
    if (this.state.viewTimezone == this.userTimezone) {
      this.setState({ viewTimezone: this.timesheetTimezone });
    } else {
      this.setState({ viewTimezone: this.userTimezone });
    }
    this.getData();
  }

  render() {

    return (
      <>
        <CustomScrollbar addClass='scrollbarDefault'>
          <Container className="topPagePadding bottomPagePadding">
            <Row className="justify-content-center">
              <Col className="small floatCol textCenter">
              <h2>{this.state.timesheet ? this.state.timesheet.title : ""}</h2>
              {this.getStartDate()}&nbsp;&nbsp;&nbsp;Timezone: <a className="linkBtn" onClick={this.toggleTimezone}>{this.state.viewTimezone}</a><br/><br/>
                <span className={"viewLineup textLeft"}>
                  <span className='viewLineupRow header'>
                    Lineup
                  </span>
                  <table>
                    <tbody>
                      {this.getLineupContent()}
                    </tbody>
                  </table>
                </span>
                <br/><br/>
                { this.state.timesheet && this.state.timesheet?.lineup.usersLinedUp.length == 0 && 
                  <>
                    <Button variant="primary" onClick={() => this.setState({ redirectTo: "/signup?timesheetId=" + this.timesheetId })}>Sign Up</Button>&nbsp;&nbsp;
                  </>
                }
                { this.state.timesheet && this.state.timesheet?.owner == this.props.user.uid && 
                  <>
                    <Button variant="primary" onClick={() => this.setState({ redirectTo: "/setLineup?timesheetId=" + this.timesheetId })}>Set Lineup</Button>&nbsp;&nbsp;
                  </>
                }
                <br/><br/><br/>
              <h3>Bot Text</h3><br/>
                <textarea disabled ref={this.botTextRef} className="textareaPreserveWhitespace botTextTemplateInput botText"
                  rows={this.state.botText.split(/\r\n|\r|\n/).length > 5 ? this.state.botText.split(/\r\n|\r|\n/).length + 1 : 6}
                  value={this.state.botText != "" ? this.state.botText : "Bot Text Editor is empty."}
                >
                </textarea><br/><br/>
                <Button variant="primary" onClick={() => {navigator.clipboard.writeText(this.state.botText)}}>Copy to Clipboard</Button>&nbsp;&nbsp;
                <br/><br/><br/>
              <h3>Edit Bot Text</h3><br/>
              <Form.Group className="mb-3" controlId="formBasicBotTextTemplate">
                  <Form.Control
                    className="textareaPreserveWhitespace botTextTemplateInput"
                    as="textarea"
                    placeholder={this.state.timesheet ? this.state.timesheet.botTextTemplate : ""}
                    rows={this.state.botTextEditor.split(/\r\n|\r|\n/).length > 5 ? this.state.botTextEditor.split(/\r\n|\r|\n/).length + 1 : 6}
                    value={this.state.botTextEditor}
                    onChange={this.onChangeBotTextTemplate} 
                  /><br/>
                  <Button variant="primary" disabled={this.state.botTextSaveDisabled} onClick={this.resetBotTextEditor}>Reset</Button>&nbsp;&nbsp;
                  { this.state.isOwner &&
                    <>
                      <Button variant="primary" disabled={this.state.botTextSaveDisabled} onClick={() => this.alert("timesheet")}>Save</Button>&nbsp;&nbsp;
                      { this.state.fromTemplate != null &&
                        <>
                          <Button variant="primary" disabled={this.state.botTextTemplateSaveDisabled} onClick={() => this.alert("template")}>Save to Template</Button>&nbsp;&nbsp;
                        </>
                      }
                    </>
                  }
                  <br/>
                  <Form.Label className="text-muted form-text textLeft">
                    <strong>Variable Legend:</strong><br/>
                    &#123;OwnerUrl&#125; - Prints the owner's twitch url i.e. "twitch.tv/skrillex"<br/>
                    &#123;OwnerUsername&#125; - Prints the owner's twitch username i.e. "@skrillex"<br/>
                    &#123;EventTitle&#125; - Prints the title of your event.<br/>
                    &#123;Timezone&#125; - Prints the timezone of your event.<br/>
                    &#123;&#123;RepeatPerSignup &#125;&#125; - This allows you to repeat a line for every signup for your event. Place text and variables after "RepeatPerSignup". The below variables can only be used in this variable.<br/>
                    &#123;TwitchUrl&#125; - Prints the streamer's twitch url i.e. "twitch.tv/skrillex"<br/>
                    &#123;TwitchUsername&#125; - Prints the streamer's twitch username i.e. "@skrillex".<br/>
                    &#123;StartTime&#125; - Prints the streamer's start time.<br/>
                    &#123;EndTime&#125; - Prints the streamer's end time.
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </CustomScrollbar>
        { 
          this.state.redirectTo != '' && <Navigate to={this.state.redirectTo} replace={true}/>
        }
      </>
    );
  }
}