import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import { doc, getDocs, setDoc, getDoc, updateDoc, query, where, deleteDoc } from 'firebase/firestore';
import { timesheetsCol, timesheetTemplatesCol } from '../services/database';
import TSUser from './user';
import TimeSheet from './timesheet';


export default interface TimeSheetTemplate {
  id?: string | null;
  name: string;
  owner: string;
  template: TimeSheet;
}

export const getTimesheetTemplate = async (id: string) => {
  const tstRef = doc(timesheetTemplatesCol, id);
  const tstSnap = await getDoc(tstRef);

  if (tstSnap.exists()) {
    return tstSnap.data();
  } else {
    return null;
  }
}

export const getAllTimesheetTemplates = async () => {
  const tstDocs = await getDocs(timesheetTemplatesCol);
  let tstList: TimeSheetTemplate[] = [];
  tstDocs.docs.forEach((doc) => {
    tstList.push(doc.data());
  });
  return tstList;
}

export const getTimesheetTemplatesByOwner = async (id: string) => {
  const q = query(timesheetTemplatesCol, where("owner", "==", id));
  let tstList: TimeSheetTemplate[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    tstList.push(doc.data());
  });
  return tstList;
}

export const setTimesheetTemplate = async (data: TimeSheetTemplate) => {
  const tstRef = doc(timesheetTemplatesCol);
  data.id = tstRef.id;
  await setDoc(tstRef, data);
  return tstRef.id;
}

export const updateTimesheetTemplate = async (id: string | null | undefined, data: {}) => {
  if (id && id != '') {
    const tstDocRef = doc(timesheetTemplatesCol, id);
    await updateDoc(tstDocRef, data);
    return tstDocRef.id;
  } else {
    return "";
  }
}

export const deleteTimesheetTemplate = async (id: string) => {
  if (id && id != '') {
    const tstDocRef = doc(timesheetTemplatesCol, id);
    await deleteDoc(tstDocRef);
  }
}

export const deleteUserTimesheetTemplates = async (id: string) => {
  let q = query(timesheetTemplatesCol, where("owner", "==", id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    deleteDoc(doc.ref);
  });
}