import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './confirmEmail.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { userAuthContext, userAuthContextType } from '../../context/UserAuthContext';
import CustomScrollbar from '../customScrollbar/customScrollbar';

const ConfirmEmail = () => {

  const [emailSent, setEmailSent] = useState(false);
  const [initLoadComplete, setInitLoadComplete] = useState(false);
  const [error, setError] = useState('');
  const [gotUser, setGotUser] = useState(false);
  const UserAuth: userAuthContextType = useContext(userAuthContext);
  const urlParams = new URLSearchParams(window.location.search);
  const isConfirmingEmail = urlParams.get('confirm_email')?.toLowerCase() == 'true';
  const [submitting, setSubmitting] = useState(!isConfirmingEmail);

  const hostname = window.location.origin;
  const actionCode = {
    url: hostname + "/confirmEmail?confirm_email=true",
  }

  async function onSubmit() {
    setError('');
    setSubmitting(true);
      try {
        await UserAuth.sendConfirmationEmail(UserAuth.user, actionCode);
        setEmailSent(true);
        setSubmitting(false);
      } catch (err: any) {
        setError(err.message);
        setSubmitting(false);
      }
    }
    
    useEffect(() => {
      if (UserAuth.user != null && UserAuth.user.emailVerified != undefined) {
        setGotUser(true);
      }
      if (gotUser && !initLoadComplete) {
        if (!isConfirmingEmail) {
          onSubmit();
        } else {
          try {
            UserAuth.refreshUser(UserAuth.user);
          } catch (err: any) {
            setError(err.message);
          }
        }
        setInitLoadComplete(true);
      }
    });

  return (
    <CustomScrollbar addClass='scrollbarDefault'>
    <Container className="topPagePadding bottomPagePadding">
      <Row className="justify-content-center">
        <Col className="small floatCol">
          <h2>Confirm Email</h2>
          <Alert variant="success" hidden={!emailSent} onClose={() => setEmailSent(false)} dismissible>
              Email sent!
          </Alert>
          <Alert variant="danger" hidden={error == ''} onClose={() => setError('')} dismissible>
              {error}
          </Alert>
          <p>Please check your email for an email from "firebase" with a verification code. If you cannot find it, please check your spam folder. Click the link in the email to unlock your account.</p>
          <Button hidden={submitting} variant="primary" type="button" onClick={onSubmit}>
            Send Again
          </Button>
          <Button hidden={!submitting} variant="primary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </Button><br/><br/>
        </Col>
      </Row>
    </Container>
    </CustomScrollbar>
  );
}

export default ConfirmEmail;