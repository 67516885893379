import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './confirmEmail.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from "react-router";
import { userAuthContext, userAuthContextType } from '../../context/UserAuthContext';
import { LinkContainer } from 'react-router-bootstrap';
import CustomScrollbar from '../customScrollbar/customScrollbar';

const Logout = () => {

  const navigate = useNavigate();
  const [logoutError, setLogoutError] = useState("");
  const UserAuth: userAuthContextType = useContext(userAuthContext);

  const handleLogout = async () => {
    try {
      await UserAuth.logout();
      navigate("/login");
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handleLogout();
  });

  return (
    <>
    <CustomScrollbar addClass='scrollbarDefault'>
      <Container className="topPagePadding bottomPagePadding">
          <Row className="justify-content-center">
            <Col className="small floatCol">
            <h2>Logging Out...</h2>
            <Alert variant="danger" hidden={logoutError == ''}>
              <Alert.Heading>Error Loging Out!</Alert.Heading>
              <p>
                { logoutError }
              </p>
              <hr/>
              <LinkContainer to="/logout">
                <Alert.Link>Click here to try again.</Alert.Link>
              </LinkContainer>
            </Alert>
          </Col>
        </Row>
      </Container>
      </CustomScrollbar>
    </>
  );
}

export default Logout;