import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './resetPassword.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { userAuthContext, userAuthContextType } from '../../context/UserAuthContext';
import { updateUser } from '../../models/user';
import { updateTwitchOnSignups } from '../../models/userSignup';
import CustomScrollbar from '../customScrollbar/customScrollbar';
import { updateTwitchOnLineups } from '../../models/userLineup';

const EditTwitchUsername = () => {

  const [twitchError, setTwitchError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const UserAuth: userAuthContextType = useContext(userAuthContext);
  const [twitch, setTwitch] = useState(UserAuth.userData.twitchUsername);
  const [currentUsername, setCurrentUsername] = useState(UserAuth.userData.twitchUsername);

  useEffect(() => {
    if (UserAuth.userData.twitchUsername != currentUsername) {
      setTwitch(UserAuth.userData.twitchUsername);
      setCurrentUsername(UserAuth.userData.twitchUsername);
    }
  });

  async function onSubmit() {
    if (validateTwitch(twitch)) {
      setError('');
      setSubmitting(true);
        try {
          await updateUser(UserAuth.user.uid, { "twitchUsername": twitch });
          await updateTwitchOnSignups(UserAuth.user.uid, twitch);
          await updateTwitchOnLineups(UserAuth.user.uid, twitch);
          await UserAuth.refreshUserData();
          setSuccess(true);
          setCurrentUsername(twitch);
          setSubmitting(false);
        } catch (err: any) {
          setError(err.message);
          setSubmitting(false);
        }
    }
    setValidated(true);
  }

  function onTwitchChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let newValue = event.target.value;
    validateTwitch(newValue);
    setTwitch(newValue || '');
  }

  function validateTwitch(value?: string) {
    if (value && value != "" && /^[a-zA-Z0-9][\w]{1,24}$/.test(value)) {
      setTwitchError("");
      return true;
    } else {
      setTwitchError("Please enter a valid email.");
      return false;
    }
  }

  return (
    
    <CustomScrollbar addClass='scrollbarDefault'>
    <Container className="topPagePadding bottomPagePadding">
      <Row className="justify-content-center">
        <Col className="small floatCol">
          <h2>Update Twitch Username</h2>
          <Alert variant="success" hidden={!success} onClose={() => setSuccess(false)} dismissible>
              Twitch Username Updated!
          </Alert>
          <Alert variant="danger" hidden={error == ''} onClose={() => setError('')} dismissible>
              {error}
          </Alert>
          { currentUsername == "" &&
            <p>You must set your Twitch Username to use the site. Enter it below and click submit.</p>
          }
          { currentUsername != "" &&
            <p>Enter your updated twitch username and click submit. Your current twitch username is <strong>{currentUsername}</strong>.</p>
          }
          <Form>
            <Form.Group className="loginForm mb-3" controlId="formBasicEmail">
              <Form.Label>New Twitch Username</Form.Label>&nbsp;&nbsp;
              <Form.Label
                hidden={!validated || twitchError == ''}
                className="text-muted form-text invalid">
                {twitchError}
              </Form.Label>
              <div className="twitchInputContainer">
                <div className="twitchLabel">twitch.tv/</div>
                <div className="twitchInput"><Form.Control type="text" placeholder="MyTwitchUsername" value={twitch} onChange={onTwitchChange} /></div>
              </div>
            </Form.Group>
            <Button hidden={submitting} disabled={twitch == currentUsername} variant="primary" type="button" onClick={onSubmit}>
              Submit
            </Button>
            <Button hidden={!submitting} variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button><br/><br/>
          </Form>
        </Col>
      </Row>
    </Container>
    </CustomScrollbar>
  );
}

export default EditTwitchUsername;