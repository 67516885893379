import { doc, getDocs, setDoc, getDoc, updateDoc, arrayUnion, arrayRemove, deleteDoc } from 'firebase/firestore';
import { usersCol } from '../services/database';
import { deleteUserTimesheets } from './timesheet';
import { deleteUserTimesheetTemplates } from './timesheetTemplate';
import { deleteUserLineups } from './userLineup';
import { deleteUserSignups } from './userSignup';

export default interface TSUser {
  id: string;
  email: string;
  isManager: boolean;
  isAdmin: boolean;
  twitchUsername: string;
  timesheetsVisited: string[];
}

export const getUser = async (id: string) => {
  const userRef = doc(usersCol, id);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data();
  } else {
    return null;
  }
}

export const getAllUsers = async () => {
  const userDocs = await getDocs(usersCol)
  let usersList: TSUser[] = [];
  userDocs.docs.forEach((userDoc) => {
    usersList.push(userDoc.data());
  });
  return usersList;
}

export const setUser = async (data: TSUser) => {
  const userRef = doc(usersCol, data.id);
  await setDoc(userRef, data);
  return userRef.id;
}

export const updateUser = async (id: string, data: {}) => {
  const userDocRef = doc(usersCol, id);
  await updateDoc(userDocRef, data);
  return userDocRef.id;
}

export const deleteUser = async (id: string) => {
  const userDocRef = doc(usersCol, id);
  await deleteDoc(userDocRef);
  await deleteUserTimesheets(id);
  await deleteUserTimesheetTemplates(id);
  await deleteUserSignups(id);
  await deleteUserLineups(id);
}

export const addViewedTimesheet = async(id: string, timesheetId: string) => {
  const userRef = doc(usersCol, id);
  await updateDoc(userRef, {
    timesheetsVisited: arrayUnion(timesheetId)
  });
}

export const removeViewedTimesheetFromAllUsers = async(timesheetId: string) => {
  const userDocs = await getDocs(usersCol)
  userDocs.docs.forEach((userDoc) => {
    updateDoc(userDoc.ref, {
      timesheetsVisited: arrayRemove(timesheetId)
    })
  });
}