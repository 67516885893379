import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { userAuthContext, userAuthContextType } from '../../context/UserAuthContext';
import { User } from 'firebase/auth';
import TSUser, { addViewedTimesheet } from '../../models/user';
import useLocalStorage from "../../services/useLocalStorage";

type protectedRouteProps = {
  user: User;
  userData: TSUser;
  refreshUserData: Function;
  children?: any;
  authTypeAllowed: string;
};

const ProtectedRoute = (props: protectedRouteProps) => {
  const [loggedIn, setLoggedIn] = useLocalStorage('loggedIn', false);
  const [timesheetIdVisited, setTimesheetIdVisited] = useLocalStorage('timesheetIdVisited', "");
  const [redirectAfterLogin, setRedirectAfterLogin] = useLocalStorage('redirectAfterLogin', '');

  let authenticated = props.user != null && props.user.emailVerified != undefined;
  let emailVerified = authenticated ? props.user.emailVerified : false;
  let hasTwitchUsername = props.userData.twitchUsername && props.userData.twitchUsername != "";
  let isManager = props.userData.isManager;
  let isAdmin = props.userData.isAdmin;
  let queryString = window.location.search || "";
  let redirect = "?redirectTo=" + window.location.pathname + queryString;
  let timesheetIdMatch = queryString.match(/timesheetId=([^&]*)/);
  let timesheetId:string;
  if (timesheetIdMatch) {
    timesheetId = timesheetIdMatch[1];
  }
  
  useEffect(() => {
    if (timesheetId && !authenticated) {
      if (timesheetIdVisited != timesheetId) {
        setTimesheetIdVisited(timesheetId);
      }
    } else if (timesheetId && (!emailVerified || !hasTwitchUsername) && !props.userData.timesheetsVisited.includes(timesheetId)) {
      addViewedTimesheet(props.user.uid, timesheetId);
      
      props.refreshUserData();
      if (timesheetIdVisited == timesheetId) {
        setTimesheetIdVisited("");
      }
    } else if (timesheetIdVisited && authenticated && !props.userData.timesheetsVisited.includes(timesheetIdVisited)) {
      addViewedTimesheet(props.user.uid, timesheetIdVisited);
      
      props.refreshUserData();
      setTimesheetIdVisited("");
    } else if (timesheetIdVisited && authenticated && props.userData.timesheetsVisited.includes(timesheetIdVisited)) {
      setTimesheetIdVisited("");
    }
  });
  
  if ((loggedIn) && (Object.keys(props.user).length === 0 || props.userData.id == "")) {
    return props.children ? props.children : <Outlet/>;
  } else {  
    if (props.authTypeAllowed == "emailVerified" && !authenticated) {
      return <Navigate to={"/login" + redirect} />;
    } else if (props.authTypeAllowed == "emailVerified" && !emailVerified) {
      return <Navigate to="/confirmEmail" />;
    } else if (props.authTypeAllowed == "emailVerified" && !hasTwitchUsername) {
      return <Navigate to="/editTwitchUsername" />;
    } else if (props.authTypeAllowed == "authenticated" && !authenticated) {
      return <Navigate to={"/login" + redirect} />;
    } else if (props.authTypeAllowed == "!emailVerified" && (!authenticated || emailVerified)) {
      return <Navigate to="/" />
    } else if (props.authTypeAllowed == "!authenticated" && authenticated) {
      return <Navigate to="/" />
    } else if (props.authTypeAllowed == "manager" && !authenticated) {
      return <Navigate to={"/login" + redirect} />;
    } else if (props.authTypeAllowed == "manager" && !emailVerified) {
      return <Navigate to="/confirmEmail" />;
    } else if (props.authTypeAllowed == "manager" && !isManager) {
      return <Navigate to="/dashboard" />;
    } else if (props.authTypeAllowed == "admin" && !authenticated) {
      return <Navigate to={"/login" + redirect} />;
    } else if (props.authTypeAllowed == "admin" && !emailVerified) {
      return <Navigate to="/confirmEmail" />;
    } else if (props.authTypeAllowed == "admin" && !isAdmin) {
      return <Navigate to="/dashboard" />;
    }
    return props.children ? props.children : <Outlet/>;
  }
};

export default ProtectedRoute;