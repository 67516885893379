import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './resetPassword.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { userAuthContext, userAuthContextType } from '../../context/UserAuthContext';
import CustomScrollbar from '../customScrollbar/customScrollbar';

const ResetPassword = () => {

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const UserAuth: userAuthContextType = useContext(userAuthContext);
  //const urlParams = new URLSearchParams(window.location.search);

  const hostname = window.location.origin;
  const actionCode = {
    url: hostname + "/login",
  }

  async function onSubmit() {
    if (validateEmail(email)) {
      setError('');
      setSubmitting(true);
        try {
          await UserAuth.sendResetPasswordEmail(email, actionCode);
          setEmailSent(true);
          setSubmitting(false);
        } catch (err: any) {
          setError(err.message);
          setSubmitting(false);
        }
    }
    setValidated(true);
  }

  function onEmailChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let newValue = event.target.value;
    validateEmail(newValue);
    setEmail(newValue || '');
  }

  function validateEmail(value?: string) {
    if (value && value != "" && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Please enter a valid email.");
      return false;
    }
  }

  return (
    <CustomScrollbar addClass='scrollbarDefault'>
    <Container className="topPagePadding bottomPagePadding">
      <Row className="justify-content-center">
        <Col className="small floatCol">
          <h2>Reset Password</h2>
          <Alert variant="success" hidden={!emailSent} onClose={() => setEmailSent(false)} dismissible>
              Email sent!
          </Alert>
          <Alert variant="danger" hidden={error == ''} onClose={() => setError('')} dismissible>
              {error}
          </Alert>
          <p>Enter your email and click submit to recieve an email with a link to reset your password.</p>
          <Form>
            <Form.Group className="loginForm mb-3" controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>&nbsp;&nbsp;
              <Form.Label
                hidden={!validated || emailError == ''}
                className="text-muted form-text invalid">
                {emailError}
              </Form.Label>
              <Form.Control type="email" placeholder="email@email.com" value={email} onChange={onEmailChange} />
            </Form.Group>
            <Button hidden={submitting} variant="primary" type="button" onClick={onSubmit}>
              Submit
            </Button>
            <Button hidden={!submitting} variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button><br/><br/>
          </Form>
        </Col>
      </Row>
    </Container>
    </CustomScrollbar>
  );
}

export default ResetPassword;