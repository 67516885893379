import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { userAuthContext } from "./context/UserAuthContext";
import './components/pages/global.css';
import axios from 'axios';

import MyNav from './components/myNav/myNav';
import Home from './components/pages/home';
import Signup from './components/pages/signup';
import Register from './components/pages/register';
import Login from './components/pages/login';
import ConfirmEmail from './components/pages/confirmEmail';
import Logout from './components/pages/logout';
import ProtectedRoute from './components/protectedRoutes/protectedRoute';
import ResetPassword from './components/pages/resetPassword';
import EditTwitchUsername from './components/pages/editTwitchUsername';
import Dashboard from './components/pages/dashboard';
import EventSetup from './components/pages/eventSetup';
import SetLineup from './components/pages/setLineup';
import ViewLineup from './components/pages/viewLineup';
import Error from './components/pages/error';
import Admin from './components/pages/admin';

const App = () => {
  const {
    user,
    userToken,
    userData,
    login,
    signup,
    logout,
    sendConfirmationEmail,
    refreshUser,
    refreshUserData,
    sendResetPasswordEmail,
    googleLogin,
    googleLoginGetRedirect
  } = React.useContext(userAuthContext);

  let hostname = "";
  if (window.location.hostname.includes("localhost")) {
    hostname = "https://twitch-scheduler.com";
  }
  axios.defaults.baseURL = hostname + '/api';
  axios.defaults.headers.common = {'Authorization': `Bearer ${userToken}`};

  return (
    
    <Router>
        <div className="App">
          <MyNav user={user} userData={userData}/>
          <Routes>
              <Route path='/' element={ <Home/> } />
              <Route path='/register' element={<Register signup={signup} googleLogin={googleLogin} googleLoginGetRedirect={googleLoginGetRedirect} />} />
              <Route element={<ProtectedRoute user={user} userData={userData} refreshUserData={refreshUserData} authTypeAllowed="emailVerified"/>}>
                <Route path='/dashboard' element={<Dashboard user={user} userData={userData}/>} />
                <Route path='/signup' element={<Signup user={user} userData={userData} refreshUserData={refreshUserData} />} />
                <Route path='/viewLineup' element={<ViewLineup user={user} userData={userData} />} />
              </Route>
              <Route element={<ProtectedRoute user={user} userData={userData} refreshUserData={refreshUserData} authTypeAllowed="!emailVerified"/>}>
                <Route path='/confirmEmail' element={<ConfirmEmail />} />
              </Route>
              <Route element={<ProtectedRoute user={user} userData={userData} refreshUserData={refreshUserData} authTypeAllowed="authenticated"/>}>
                <Route path="/editTwitchUsername" element={<EditTwitchUsername />} />
                <Route path='/logout' element={<Logout/>} />
              </Route>
              <Route element={<ProtectedRoute user={user} userData={userData} refreshUserData={refreshUserData} authTypeAllowed="!authenticated"/>}>
                <Route path='/login' element={<Login login={login} googleLogin={googleLogin} googleLoginGetRedirect={googleLoginGetRedirect} />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
              </Route>
              <Route element={<ProtectedRoute user={user} userData={userData} refreshUserData={refreshUserData} authTypeAllowed="manager"/>}>
                <Route path='/eventSetup' element={<EventSetup user={user} userData={userData}/>} />
                <Route path='/setLineup' element={<SetLineup user={user} userData={userData}/>} />
              </Route>
              <Route element={<ProtectedRoute user={user} userData={userData} refreshUserData={refreshUserData} authTypeAllowed="admin"/>}>
                <Route path='/admin' element={<Admin user={user} userData={userData} axios={axios}/>} />
              </Route>
              <Route path='*' element={<Error />}/>
            </Routes>
        </div>
      </Router>
  )
}

export default App;