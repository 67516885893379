import React, { Component } from 'react';
import './mobileDropdown.css';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';

export interface MobileDropdownOption {
  addClass?: string;
  style?: React.CSSProperties;
  value: string;
  text: string;
}

interface MobileDropdownProps {
  addClass?: string;
  options: MobileDropdownOption[];
  value: string;
  onChange: Function;
  rowNumber: number;
  touchEnd: Moment;
  updateTouchEnd: Function;
}

interface MobileDropdownState {
  open: boolean;
  selectedOption: MobileDropdownOption;
  highlightedOptionIndex: number;
}

export default class MobileDropdown extends Component<MobileDropdownProps, MobileDropdownState> {
  touchStart = false;
  touchMove = false;
  wrapperRef: any;
  lastKeyDown = moment.default().subtract(1, "days");
  search = "";
  constructor(props: MobileDropdownProps) {
    super(props);

    this.state = {
      open: false,
      selectedOption: this.props.options[0],
      highlightedOptionIndex: 0
    };
    
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onOptionTouchEnd = this.onOptionTouchEnd.bind(this);
    this.onOptionMouseDown = this.onOptionMouseDown.bind(this);
    this.onSelectedTouchEnd = this.onSelectedTouchEnd.bind(this);
    this.onSelectedMouseDown = this.onSelectedMouseDown.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }
  
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && this.state.open) {
      this.setState({ open: false });
    }
  }

  public async componentDidUpdate(prevProps: MobileDropdownProps) {
    if (prevProps != this.props) {
      let propsValue = this.props.value;
      if (propsValue == '{"userId": "undefined", "userTwitch": "undefined"}') {
        propsValue = '';
      }
      for (let i = 0; i < this.props.options.length; i++) {
        let option = this.props.options[i];
        if (option.value == propsValue && option.value != this.state.selectedOption.value) {
          this.setState({ selectedOption: option});
        }
      }
    }
  }

  private onTouchStart() {
    this.touchStart = true;
  }

  private onTouchMove() {
    if (this.touchStart && !this.touchMove) {
      this.touchMove = true;
    }
  }
  
  private onOptionTouchEnd(e: React.TouchEvent<HTMLElement>, option: MobileDropdownOption) {
    this.touchStart = false;
    this.props.updateTouchEnd(moment.default());

    if (this.touchMove) {
      this.touchMove = false;
    } else if (option) {
      this.props.onChange(option.value, this.props.rowNumber);
      this.setState({ open: false });
    }
  }
  
  private onOptionMouseDown(e: React.MouseEvent<HTMLDivElement | HTMLSpanElement>, option: MobileDropdownOption) {
    let sinceLastTouchEnd = moment.default().diff(this.props.touchEnd);
    if (this.touchStart || sinceLastTouchEnd < 500) return; 
    if (option) {
      this.props.onChange(option.value, this.props.rowNumber);
      this.setState({ open: false });
    }
  }
  
  private onSelectedTouchEnd(e: React.TouchEvent<HTMLElement>) {
    this.touchStart = false;
    this.props.updateTouchEnd(moment.default());

    if (this.touchMove) {
      this.touchMove = false;
    } else {
      this.setState({ open: !this.state.open });
    }
  }
  
  private onSelectedMouseDown(e: React.MouseEvent<HTMLDivElement | HTMLSpanElement>) {
    let sinceLastTouchEnd = moment.default().diff(this.props.touchEnd);
    if (this.touchStart || sinceLastTouchEnd < 500) return;
    let highlightedOptionIndex = 0;
    if (!this.state.open) {
      highlightedOptionIndex = this.state.highlightedOptionIndex;
    }
    this.setState({ open: !this.state.open, highlightedOptionIndex: highlightedOptionIndex });
  }

  private onKeyDown(event: any) {
    //console.log("key pressed: " + event.key);
    event.preventDefault();
    let sinceLastKeyDown = moment.default().diff(this.lastKeyDown);
    if (sinceLastKeyDown >= 500) {
      this.search = "";
    }
    let usedKeys = ["Escape", "Enter", "ArrowUp", "ArrowDown"];
    if (!usedKeys.includes(event.key)) {
      this.search = this.search + event.key;
      for (let i = 0; i < this.props.options.length; i++) {
        let option = this.props.options[i];
        if (option.text.toLowerCase().startsWith(this.search.toLowerCase())) {
          this.setState({ highlightedOptionIndex: i });
          break;
        }
      }
    } else if (event.key === "Escape" && this.state.open) {
      this.setState({ open: false });
    } else if(event.key === 'Enter'){
      this.props.onChange(this.props.options[this.state.highlightedOptionIndex].value, this.props.rowNumber);
      this.setState({ open: false });
    } else if (event.key === "ArrowUp") {
      if (this.state.highlightedOptionIndex == 0) {
        return
      } else {
        this.setState({ highlightedOptionIndex: this.state.highlightedOptionIndex - 1 });
      }
    } else if (event.key === "ArrowDown") {
      if (this.state.highlightedOptionIndex == this.props.options.length - 1) {
        return
      } else {
        this.setState({ highlightedOptionIndex: this.state.highlightedOptionIndex + 1 });
      }
    }
    this.lastKeyDown = moment.default();
  }

  private getOptions() {
    let content = [];
    for (let i = 0; i < this.props.options.length; i++) {
      let option = this.props.options[i];
      let highlightedClass = "";
      if (this.state.highlightedOptionIndex == i) highlightedClass = "highlighted ";
      content.push(
        <li
          key={this.props.rowNumber + option.value}
          className={highlightedClass + (option.addClass ? option.addClass : "")}
          onMouseDown={(e) => this.onOptionMouseDown(e, option)}
          onTouchEnd={(e) => this.onOptionTouchEnd(e, option)}
          onMouseEnter={() => this.setState({ highlightedOptionIndex: i })}
        >
          {option.text}
        </li>
      );
    }
    return content;
  }

  render() {
    const optionsStyles = {
      bottom: -29 * this.props.options.length
   }
    return (
      <div
        ref={this.wrapperRef}
        className={'mobileDropdown ' + (this.props.addClass ? this.props.addClass : "")}
        onTouchStart={() => this.onTouchStart()}
        onTouchMove={() => this.onTouchMove()}
        onKeyDown={this.onKeyDown}
        tabIndex={this.props.rowNumber}
      >
        <div
        className={'mobileDropdownSelected ' + (this.state.selectedOption.addClass ? this.state.selectedOption.addClass : "")}
        onMouseDown={(e) => this.onSelectedMouseDown(e)}
        onTouchEnd={(e) => this.onSelectedTouchEnd(e)}
        >
          {this.state.selectedOption.text}
        </div>
        <ul
          hidden={!this.state.open}
          className={'mobileDropdownOptions'}
          style={optionsStyles}
          >
          {this.getOptions()}
        </ul>
      </div>
    );
  }
}