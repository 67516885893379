import React from 'react';
import ReactDOM from 'react-dom/client';
import "react-datepicker/dist/react-datepicker.css";
import './global.css';
import './eventSetup.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { BiInfoCircle } from 'react-icons/bi';
import { Navigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as moment from 'moment-timezone';
import { Timestamp } from 'firebase/firestore';
import { User } from 'firebase/auth';
import TSUser from '../../models/user';
import TimeSheet, { setTimesheet } from '../../models/timesheet';
import TimeSheetTemplate, {
  getAllTimesheetTemplates,
  getTimesheetTemplatesByOwner,
  setTimesheetTemplate,
  updateTimesheetTemplate,
  deleteTimesheetTemplate
} from '../../models/timesheetTemplate';
import CustomScrollbar from '../customScrollbar/customScrollbar';

import TimezoneSelect from 'react-timezone-select';

type eventSetupProps = {
  user: User;
  userData: TSUser;
};

type eventSetupState = {
  title: string;
  titleError: string;
  interval: string;
  startDate: Date;
  startHour: string;
  startAmPm: string;
  startError: string;
  endDate: Date;
  endHour: string;
  endAmPm: string;
  endError: string;
  timezone: string;
  botTextTemplate: string;
  botTextTemplateError: string;
  templates: TimeSheetTemplate[];
  selectedTemplate: string;
  templateName: string;
  selectedTemplateAndNameMatch: boolean;
  templateNameMatchesTemplate: boolean;
  templateSubmitting: boolean;
  templateSuccess: string;
  formError: string;
  templateError: string;
  submitting: boolean;
  validated: boolean;
  redirectTo: string;
  showTemplateTooltip: boolean;
  appliedTemplateId: string;
};

type StateDateObject = {
  date: Date;
  hour: string;
  AmPm: string;
}

class EventSetup extends React.Component<eventSetupProps, eventSetupState> {
  botTextTemplateDefault: string;
  defaultTemplate: TimeSheetTemplate;
  tooltipTarget: any;

  constructor(props: eventSetupProps) {
    super(props);

    this.tooltipTarget = React.createRef();

    let userTimezone = moment.tz.guess();
    let now = moment.tz(userTimezone).toDate();
    let tomorrow = moment.tz(userTimezone).add(1, "days").toDate();

    this.botTextTemplateDefault = `Welcome to {OwnerUsername}'s {EventTitle}!

{{RepeatPerSignup {TwitchUrl} - {StartTime} - {EndTime}}}
    
All times {Timezone}`;

    this.defaultTemplate = {
      name: '',
      owner: '',
      template: {
        title: '',
        start: null,
        end: null,
        interval: 60,
        timezone: userTimezone,
        botTextTemplate: this.botTextTemplateDefault,
        signups: {
          usersSignedUp: [],
          signups: []
        },
        owner: '',
        lineup: {
          usersLinedUp: [],
          lineups: []
        },
        fromTemplate: null
      }
    }

    this.state = {
      title: this.defaultTemplate.template.title,
      titleError: '',
      interval: this.defaultTemplate.template.interval.toString(),
      startDate: now,
      startHour: '12',
      startAmPm: 'AM',
      startError: '',
      endDate: tomorrow,
      endHour: '6',
      endAmPm: 'AM',
      endError: '',
      timezone: this.defaultTemplate.template.timezone,
      botTextTemplate: this.defaultTemplate.template.botTextTemplate,
      botTextTemplateError: '',
      selectedTemplate: "-1",
      templates: [],
      selectedTemplateAndNameMatch: false,
      templateNameMatchesTemplate: false,
      templateName: '',
      templateSubmitting: false,
      templateSuccess: '',
      formError: '',
      templateError: '',
      submitting: false,
      validated: false,
      redirectTo: '',
      showTemplateTooltip: false,
      appliedTemplateId: ""
    };

    this.getTemplates = this.getTemplates.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
    this.runAllValidators = this.runAllValidators.bind(this);
    this.runTemplateValidators = this.runTemplateValidators.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.validateTitle = this.validateTitle.bind(this);
    this.onChangeInterval = this.onChangeInterval.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeStartHour = this.onChangeStartHour.bind(this);
    this.toggleStartAmPm = this.toggleStartAmPm.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onChangeEndHour = this.onChangeEndHour.bind(this);
    this.toggleEndAmPm = this.toggleEndAmPm.bind(this);
    this.validateDates = this.validateDates.bind(this);
    this.onChangeTimezone = this.onChangeTimezone.bind(this);
    this.onChangeBotTextTemplate = this.onChangeBotTextTemplate.bind(this);
    this.validateBotTextTemplate = this.validateBotTextTemplate.bind(this);
    this.getTemplateOptions = this.getTemplateOptions.bind(this);
    this.onChangeSelectedTemplate = this.onChangeSelectedTemplate.bind(this);
    this.onChangeTemplateName = this.onChangeTemplateName.bind(this);
    this.onTemplateApply = this.onTemplateApply.bind(this);
    this.onTemplateDelete = this.onTemplateDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.onTemplateSave = this.onTemplateSave.bind(this);
    this.getTimesheetObject = this.getTimesheetObject.bind(this);
    this.getTimesheetTemplateObject = this.getTimesheetTemplateObject.bind(this);
    this.convertDateToTimezone = this.convertDateToTimezone.bind(this);
    this.onChangeTemplateName = this.onChangeTemplateName.bind(this);
    this.onChangeSelectedTemplate = this.onChangeSelectedTemplate.bind(this);
    this.onTemplateApply = this.onTemplateApply.bind(this);
    this.onTemplateDelete = this.onTemplateDelete.bind(this);
    this.onTemplateSave = this.onTemplateSave.bind(this);
    this.getTemplateOptions = this.getTemplateOptions.bind(this);
    this.getTimesheetObject = this.getTimesheetObject.bind(this);
    this.getTimesheetTemplateObject = this.getTimesheetTemplateObject.bind(this);
    this.templateNameMatches = this.templateNameMatches.bind(this);
    this.orderTemplates = this.orderTemplates.bind(this);
    this.onTemplateRename = this.onTemplateRename.bind(this);
    this.templateSaveable = this.templateSaveable.bind(this);
  }

  
  public async componentDidMount() {
    if (this.props.userData.id && this.props.userData.id != "") {
      await this.getTemplates();
    }
  }

  public async componentDidUpdate(prevProps: eventSetupProps) {
    if (this.props.userData != prevProps.userData) {
      await this.getTemplates();
    }
  }

  public async getTemplates() {
    let templates: TimeSheetTemplate[] = [];
    if (this.props.userData.isAdmin) {
      templates = await getAllTimesheetTemplates();
    } else if (this.props.userData.isManager) {
      templates = await getTimesheetTemplatesByOwner(this.props.user.uid);
    }
    if (templates != this.state.templates) {
      this.setState({ templates: templates });
    }
  }

  public async onSubmit() {
    if (this.isValid()) {
      this.setState({ submitting: true, formError: '', templateError: '' });
      try {
        let templateId = this.state.appliedTemplateId || null;
        let timesheet = this.getTimesheetObject("", templateId);
        await setTimesheet(timesheet);
        this.setState({ redirectTo: "/dashboard" });
      } catch (err: any) {
        this.setState({ formError: err.message, submitting: false });
      }
    }
  }

  public isValid(forTemplate: boolean = false) {
    this.setState({ validated: true });
    if (forTemplate) {
      let valid = this.runTemplateValidators();
      if (valid) {
        return true;
      } else {
        this.setState({ startError: '', endError: '', templateError: "Please fix invalid field(s)." });
        return false;
      }
    } else {
      let valid = this.runAllValidators();
      if (valid) {
        return true;
      } else {
        this.setState({ formError: "Please fix invalid field(s) above." });
        return false;
      }
    }
    
  }

  public runAllValidators() {
    let titleValid = this.validateTitle(this.state.title);
    let datesValid = this.validateDates(
      {
        date: this.state.startDate,
        hour: this.state.startHour,
        AmPm: this.state.startAmPm
      },
      {
        date: this.state.endDate,
        hour: this.state.endHour,
        AmPm: this.state.endAmPm
      },
      this.state.timezone
    );
    let botTextTemplateValid = this.validateBotTextTemplate(this.state.botTextTemplate);
    return titleValid && datesValid && botTextTemplateValid;
  }
  
  public runTemplateValidators() {
    let titleValid = this.validateTitle(this.state.title);
    let botTextTemplateValid = this.validateBotTextTemplate(this.state.botTextTemplate);
    return titleValid && botTextTemplateValid;
  }

  public onChangeTitle(event: any) {
    let newValue = event.target.value;
    this.validateTitle(newValue);
    this.setState({ title: newValue || '' });
  }

  public validateTitle(value?: string) {
    if (value && value != "") {
      let formError = this.state.formError;
      if (this.state.startError == '' && this.state.endError == '' && this.state.botTextTemplateError == '') {
        formError = '';
      }
      this.setState({ titleError: "", formError: formError });
      return true;
    } else {
      this.setState({ titleError: "Please enter a title." });
      return false;
    }
  }
  
  public onChangeInterval(event: any) {
    let newValue = event.target.value;
    this.setState({ interval: newValue || '' });
  }

  public onChangeStartDate(date: Date) {
    this.validateDates({
      date: date,
      hour: this.state.startHour,
      AmPm: this.state.startAmPm
    },
    {
      date: this.state.endDate,
      hour: this.state.endHour,
      AmPm: this.state.endAmPm
    },
    this.state.timezone);
    this.setState({ startDate: date });
  }

  public onChangeEndDate(date: Date) {
    this.validateDates({
      date: this.state.startDate,
      hour: this.state.startHour,
      AmPm: this.state.startAmPm
    },
    {
      date: date,
      hour: this.state.endHour,
      AmPm: this.state.endAmPm
    },
    this.state.timezone);
    this.setState({ endDate: date });
  }

  public onChangeStartHour(event: any) {
    let newValue = event.target.value;
    this.validateDates({
      date: this.state.startDate,
      hour: newValue,
      AmPm: this.state.startAmPm
    },
    {
      date: this.state.endDate,
      hour: this.state.endHour,
      AmPm: this.state.endAmPm
    },
    this.state.timezone);
    this.setState({ startHour: newValue || '' });
  }
  
  public onChangeEndHour(event: any) {
    let newValue = event.target.value;
    this.validateDates({
      date: this.state.startDate,
      hour: this.state.startHour,
      AmPm: this.state.startAmPm
    },
    {
      date: this.state.endDate,
      hour: newValue,
      AmPm: this.state.endAmPm
    },
    this.state.timezone);
    this.setState({ endHour: newValue || '' });
  }

  public toggleStartAmPm() {
    let newValue;
    if (this.state.startAmPm == "AM") {
      newValue = "PM";
    } else {
      newValue = "AM";
    }
    this.validateDates({
      date: this.state.startDate,
      hour: this.state.startHour,
      AmPm: newValue
    },
    {
      date: this.state.endDate,
      hour: this.state.endHour,
      AmPm: this.state.endAmPm
    },
    this.state.timezone);
    this.setState({ startAmPm: newValue });
  }

  public toggleEndAmPm() {
    let newValue;
    if (this.state.endAmPm == "AM") {
      newValue = "PM";
    } else {
      newValue = "AM";
    }
    this.validateDates({
      date: this.state.startDate,
      hour: this.state.startHour,
      AmPm: this.state.startAmPm
    },
    {
      date: this.state.endDate,
      hour: this.state.endHour,
      AmPm: newValue
    },
    this.state.timezone);
    this.setState({ endAmPm: newValue });
  }
  
  public onChangeTimezone(timezone: any) {
    let newValue = timezone.value;
    this.validateDates({
      date: this.state.startDate,
      hour: this.state.startHour,
      AmPm: this.state.startAmPm
    },
    {
      date: this.state.endDate,
      hour: this.state.endHour,
      AmPm: this.state.endAmPm
    },
    newValue);
    this.setState({ timezone: newValue || '' });
  }

  public validateDates(start: StateDateObject, end: StateDateObject, timezone: string) {
    let now = moment.tz(timezone);
    let startDateTime = this.convertDateToTimezone(start.date, start.hour, start.AmPm, timezone);
    let endDateTime = this.convertDateToTimezone(end.date, end.hour, end.AmPm, timezone);

    let isValid = true;
    let formError = this.state.formError;
    let startError = '';
    let endError = '';
    if (startDateTime.isBefore(now)) {
      startError = 'Please select a future start date.';
      isValid = false;
    }
    if (endDateTime.isBefore(startDateTime)) {
      endError = 'Please select an end date after your start date.';
      isValid = false;
    }
    if (isValid && this.state.titleError == '' && this.state.botTextTemplateError == '') {
      formError = '';
    }
    this.setState({ formError: formError, startError: startError, endError: endError });
    return isValid;
  }

  public convertDateToTimezone(date: Date, hour: string, AmPm: string, timezone: string) {
    let dateString = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear() + " " + hour + ":00:00 " + AmPm;
    return moment.tz(dateString, "M-D-YYYY h:mm:ss A", timezone);
  }

  public onChangeBotTextTemplate(event: any) {
    let newValue = event.target.value;
    this.validateBotTextTemplate(newValue);
    this.setState({ botTextTemplate: newValue || '' });
  }

  public validateBotTextTemplate(value?: string) {
    if (value && value != "") {
      let formError = this.state.formError;
      if (this.state.titleError == '' && this.state.startError == '' && this.state.endError == '') {
        formError = '';
      }
      this.setState({ botTextTemplateError: "", formError: formError });
      return true;
    } else {
      this.setState({ botTextTemplateError: "Please enter a title." });
      return false;
    }
  }

  public getTemplateOptions() {
    let items = []; 
     for (let i = 0; i < this.state.templates.length; i++) {
          let thisTemplate = this.state.templates[i];
          items.push(<option key={thisTemplate.id} value={i}>{thisTemplate.name}</option>);
     }
     return items;
  }
  
  public onChangeSelectedTemplate(event: any) {
    let newValue = event.target.value;
    let newValueInt = parseInt(newValue);
    let matchesSelected = false;
    if (newValueInt != -1) {
      matchesSelected = true;
    }
    let matchesTemplateName = false;
    if (newValueInt != -1) {
      matchesTemplateName = this.templateNameMatches(this.state.templates[newValueInt].name, this.state.templates);
    }
    this.setState({ selectedTemplate: newValue || "-1", templateName: newValueInt == -1 ? "" : this.state.templates[newValueInt].name, selectedTemplateAndNameMatch: matchesSelected, templateNameMatchesTemplate: matchesTemplateName });
  }

  public onChangeTemplateName(event: any) {
    let newValue = event.target.value;
    let matchesSelected = false;
    let selectedTemplateInt = parseInt(this.state.selectedTemplate)
    if (this.state.selectedTemplate != "-1" && this.state.templates[selectedTemplateInt].name == newValue) {
      matchesSelected = true;
    }
    let matchesTemplateName = false;
    if (this.state.selectedTemplate != "-1") {
      matchesTemplateName = this.templateNameMatches(newValue, this.state.templates);
    }
    this.setState({ templateName: newValue || '', selectedTemplateAndNameMatch: matchesSelected, templateNameMatchesTemplate: matchesTemplateName });
  }

  public templateNameMatches(name: string, templates: TimeSheetTemplate[]) {
    let match = false;
    if (name && name != '') {
      for (let i = 0; i < templates.length; i++) {
        if (name == templates[i].name) {
          match = true;
        }
      }
    }
    return match;
  }

  public onTemplateApply() {
    let selectedTemplate: TimeSheetTemplate;
    if (this.state.selectedTemplate == "-1") {
      selectedTemplate = this.defaultTemplate;
    } else {
      selectedTemplate = this.state.templates[parseInt(this.state.selectedTemplate)];
    }
    this.setState({
      title: selectedTemplate.template.title,
      interval: selectedTemplate.template.interval.toString(),
      timezone: selectedTemplate.template.timezone,
      botTextTemplate: selectedTemplate.template.botTextTemplate,
      appliedTemplateId: selectedTemplate.id ? selectedTemplate.id : ""
    });
  }

  public confirmDelete() {
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete the selected template?',
      buttons: [
        {
          label: 'Delete',
          onClick: this.onTemplateDelete
        },
        {
          label: 'Cancel'
        }
      ]
    });
  }

  public async onTemplateDelete() {
    this.setState({ templateSubmitting: true, formError: '', templateError: '' });
    try {
      let templateSuccess = '';
      let templateId = "";
      let templateIndex = -1;
      let templates = this.state.templates;
      let selectedTemplateInt = parseInt(this.state.selectedTemplate);
      if (selectedTemplateInt != -1) {
        templateId = this.state.templates[selectedTemplateInt].id || "";
        if (templateId != '') {
          await deleteTimesheetTemplate(templateId);
          templates.splice(selectedTemplateInt, 1);
          templateSuccess = "Your template has been deleted!";
        }
      }
      this.setState({templateSubmitting: false, templateSuccess: templateSuccess, templates: templates, selectedTemplate: "-1", templateName: '', selectedTemplateAndNameMatch: false, templateNameMatchesTemplate: false});
    } catch (err: any) {
      this.setState({ templateError: err.message, templateSubmitting: false });
    }
  }

  public async onTemplateSave() {
    if (this.isValid(true)) {
      this.setState({ templateSubmitting: true, formError: '', templateError: '' });
      try {
        let templateSuccess = '';
        let templateId;
        let templateIndex = -1;
        let templates = this.state.templates;
        let selectedTemplateInt = parseInt(this.state.selectedTemplate);
        if (selectedTemplateInt != -1 && this.state.templateName == this.state.templates[selectedTemplateInt].name) {
          templateId = this.state.templates[selectedTemplateInt].id;
          let template = this.getTimesheetTemplateObject(templateId);
          await updateTimesheetTemplate(templateId, template);
          var foundIndex = templates.findIndex(x => x.id == template.id);
          templates[foundIndex] = template;
          templates = this.orderTemplates(templates);
          templateIndex = templates.findIndex(x => x.id == template.id);
          templateSuccess = "Your template has been updated!";
        } else {
          let template = this.getTimesheetTemplateObject();
          templateId = await setTimesheetTemplate(template);
          template.id = templateId;
          templates.push(template);
          templates = this.orderTemplates(templates);
          templateIndex = templates.findIndex(x => x.id == template.id);
          templateSuccess = "Your template has been created!";
        }
        this.setState({templateSubmitting: false, templateSuccess: templateSuccess, templates: templates, selectedTemplate: templateIndex.toString(), templateNameMatchesTemplate: true});
      } catch (err: any) {
        this.setState({ templateError: err.message, templateSubmitting: false });
      }
    }
  }

  public async onTemplateRename() {
    this.setState({ templateSubmitting: true, formError: '', templateError: '' });
    try {
      let templateSuccess = '';
      let templateId = "";
      let templateIndex = -1;
      let templates = this.state.templates;
      let selectedTemplateInt = parseInt(this.state.selectedTemplate);
      if (selectedTemplateInt != -1 && this.state.templateName != this.state.templates[selectedTemplateInt].name) {
        templateId = this.state.templates[selectedTemplateInt].id || "";
        if (templateId != '') {
          await updateTimesheetTemplate(templateId, { name: this.state.templateName });
          var foundIndex = templates.findIndex(x => x.id == templateId);
          templates[foundIndex].name = this.state.templateName;
          templates = this.orderTemplates(templates);
          templateIndex = templates.findIndex(x => x.id == templateId);
          templateSuccess = "Your template has been renamed!";
        }
      }
      this.setState({templateSubmitting: false, templateSuccess: templateSuccess, templates: templates, selectedTemplate: templateIndex.toString(), templateNameMatchesTemplate: true});
    } catch (err: any) {
      this.setState({ templateError: err.message, templateSubmitting: false });
    }
  }

  public orderTemplates(templates: TimeSheetTemplate[]) {
    templates.sort(function(a:TimeSheetTemplate, b:TimeSheetTemplate) {
      return a.name.localeCompare(b.name)
    });
    return templates;
  }

  public getTimesheetObject(id: string | null | undefined = "", fromTemplate: string | null = null) {
    let startDateTime = this.convertDateToTimezone(this.state.startDate, this.state.startHour, this.state.startAmPm, this.state.timezone);
    let endDateTime = this.convertDateToTimezone(this.state.endDate, this.state.endHour, this.state.endAmPm, this.state.timezone);

    let timesheet: TimeSheet = {
      id: id && id != "" ? id : null,
      title: this.state.title,
      start: Timestamp.fromDate(startDateTime.toDate()),
      end: Timestamp.fromDate(endDateTime.toDate()),
      interval: parseInt(this.state.interval),
      timezone: this.state.timezone,
      botTextTemplate: this.state.botTextTemplate,
      signups: {
        usersSignedUp: [],
        signups: []
      },
      owner: this.props.user.uid,
      lineup: {
        usersLinedUp: [],
        lineups: []
      },
      fromTemplate: fromTemplate
    }
      return timesheet;
  }

  public getTimesheetTemplateObject(id: string | null | undefined = "") {
    let timesheet = this.getTimesheetObject();
    timesheet.start = null;
    timesheet.end = null;
    let template: TimeSheetTemplate = {
      id: id && id == "" ? null : id,
      name: this.state.templateName,
      owner: this.props.user.uid,
      template: timesheet
    }
    return template;
  }

  public templateSaveable() {
    if (this.state.title == "" || this.state.botTextTemplate == "") {
      return false;
    }
    let selectedTemplate: TimeSheetTemplate = this.state.templates[parseInt(this.state.selectedTemplate)];
    if (selectedTemplate != null) {
      if (this.state.title != selectedTemplate.template.title ||
        this.state.timezone != selectedTemplate.template.timezone ||
        this.state.botTextTemplate != selectedTemplate.template.botTextTemplate) {
          return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  render() {
    return (
      <>
      <CustomScrollbar addClass='scrollbarDefault'>
        <Container className="topPagePadding bottomPagePadding">
          <Row className="justify-content-center">
            <Col className="small floatCol">
            <Form>
              <h2 className='mb-4 inlineH2'>Event Templates</h2>&nbsp;&nbsp;
                <span className="infoIcon" ref={this.tooltipTarget}>
                  <BiInfoCircle onClick={() => this.setState({ showTemplateTooltip: !this.state.showTemplateTooltip})} />
                </span>
                <Overlay target={this.tooltipTarget.current} show={this.state.showTemplateTooltip} placement="bottom">
                  {(props) => (
                    <Tooltip id="tooltipOverlay" {...props}>
                      Event templates make reaccuring events easier. Fill out the form, give your template a name and click save. Select a template and click apply to prefill the values.Saving a template with the same name as an existing template will overwrite the template. To rename a template, select it, type the new name in, and click Rename.
                    </Tooltip>
                  )}
                </Overlay>
                <Alert variant="success" hidden={this.state.templateSuccess == ''} onClose={() => this.setState({ templateSuccess: '' })} dismissible>
                    {this.state.templateSuccess}
                </Alert>
                <Alert variant="danger" hidden={this.state.templateError == ''} onClose={() => this.setState({ templateError: '' })} dismissible>
                    {this.state.templateError}
                </Alert>
                <Form.Group className="eventSetupForm mb-3" controlId="formBasicTemplateSelect">
                  <Form.Label>Event Template</Form.Label>&nbsp;&nbsp;<br/>
                  <Form.Control
                      as="select"
                      className="form-select templateSelect"
                      value={this.state.selectedTemplate}
                      onChange={this.onChangeSelectedTemplate}
                    >
                      <option key={"default"} value={-1}>Default</option>
                      {this.getTemplateOptions()}
                    </Form.Control>&nbsp;&nbsp;
                  <Button disabled={this.state.templateSubmitting} className="inlineButton" variant="primary" type="button" onClick={this.onTemplateApply}>
                    Apply
                  </Button>&nbsp;&nbsp;
                  <Button disabled={this.state.templateSubmitting || this.state.selectedTemplate == "-1"} className="inlineButton" variant="danger" type="button" onClick={this.confirmDelete}>
                    Delete
                  </Button>
                </Form.Group>
                <Form.Group className="eventSetupForm mb-3" controlId="formBasicTemplateCreate">
                  <Form.Label>Event Template Name</Form.Label>&nbsp;&nbsp;<br/>
                  <Form.Control type="text" placeholder="Template Name" value={this.state.templateName} onChange={this.onChangeTemplateName} /><br/>
                  <Button disabled={
                    this.state.templateSubmitting || this.state.templateName == "" || 
                    !this.templateSaveable()
                    } className="inlineButton" variant="primary" type="button" onClick={this.onTemplateSave}>
                    {this.state.selectedTemplateAndNameMatch ? "Overwrite" : "Save" }
                  </Button>&nbsp;&nbsp;
                  <Button disabled={this.state.templateSubmitting || this.state.templateName == "" || this.state.selectedTemplate == "-1" || this.state.templateNameMatchesTemplate} className="inlineButton" variant="primary" type="button" onClick={this.onTemplateRename}>
                    Rename
                  </Button>
                </Form.Group>
              </Form><br/>
              <Form>
                <h2 className='mb-4'>Event Setup</h2>
                <Form.Group className="eventSetupForm mb-3" controlId="formBasicTitle">
                  <Form.Label>Event Title</Form.Label>&nbsp;&nbsp;
                  <Form.Label
                    hidden={!this.state.validated || this.state.titleError == ''}
                    className="text-muted form-text invalid">
                    {this.state.titleError}
                  </Form.Label>
                  <Form.Control type="text" placeholder="My Event" value={this.state.title} onChange={this.onChangeTitle} />
                </Form.Group>
                <Form.Group className="eventSetupForm mb-3" controlId="formBasicInterval">
                  <Form.Label>Signup Interval</Form.Label>&nbsp;&nbsp;<br/>
                  <Form.Label className="text-muted form-text">
                    Set the amount of time that each cell on the signup sheet is worth.
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className="form-select"
                    value={this.state.interval}
                    onChange={this.onChangeInterval}
                  >
                    <option value="30">30 minutes</option>
                    <option value="60">1 hour</option>
                    <option value="120">2 hours</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicStart">
                  <Form.Label>Start Date and Time</Form.Label>&nbsp;&nbsp;<br/>
                  <Form.Label className="text-muted form-text">
                    The start and end date and time will control the minimum and maximum timeslots that streamers can sign up for.
                  </Form.Label>
                  <Form.Label
                    hidden={!this.state.validated || this.state.startError == ''}
                    className="text-muted form-text invalid">
                    {this.state.startError}
                  </Form.Label><br/>
                  <DatePicker className="form-control datePicker" selected={this.state.startDate} onChange={(date:Date) => this.onChangeStartDate(date)} />&nbsp;&nbsp;
                  <Form.Control
                    as="select"
                    className="hourPicker form-select"
                    value={this.state.startHour}
                    onChange={this.onChangeStartHour}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </Form.Control>:00&nbsp;&nbsp;
                  <Button variant="primary" type="button" className="inlineButton" onClick={this.toggleStartAmPm}>
                    {this.state.startAmPm}
                  </Button>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEnd">
                  <Form.Label>End Date and Time</Form.Label>&nbsp;&nbsp;
                  <Form.Label
                    hidden={!this.state.validated || this.state.endError == ''}
                    className="text-muted form-text invalid">
                    {this.state.endError}
                  </Form.Label><br/>
                  <DatePicker className="form-control datePicker" selected={this.state.endDate} onChange={(date:Date) => this.onChangeEndDate(date)} />&nbsp;&nbsp;
                  <Form.Control
                    as="select"
                    className="hourPicker form-select"
                    value={this.state.endHour}
                    onChange={this.onChangeEndHour}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </Form.Control>:00&nbsp;&nbsp;
                  <Button variant="primary" type="button" className="inlineButton" onClick={this.toggleEndAmPm}>
                    {this.state.endAmPm}
                  </Button>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicTimezone">
                  <Form.Label>Timezone</Form.Label>&nbsp;&nbsp;
                  <TimezoneSelect
                    value={this.state.timezone}
                    onChange={this.onChangeTimezone}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicBotTextTemplate">
                  <Form.Label>Bot Text Template</Form.Label>&nbsp;&nbsp;
                  <Form.Label
                    hidden={!this.state.validated || this.state.botTextTemplateError == ''}
                    className="text-muted form-text invalid">
                    {this.state.botTextTemplateError}
                  </Form.Label><br/>
                  <Form.Label className="text-muted form-text">
                    We will use this template to create text that event streamers can use to put in their Twitch Chatbots.
                  </Form.Label>
                  <Form.Control
                    className="textareaPreserveWhitespace botTextTemplateInput"
                    as="textarea"
                    placeholder={this.botTextTemplateDefault}
                    rows={this.state.botTextTemplate.split(/\r\n|\r|\n/).length > 5 ? this.state.botTextTemplate.split(/\r\n|\r|\n/).length : 6}
                    value={this.state.botTextTemplate}
                    onChange={this.onChangeBotTextTemplate} 
                  /><br/>
                  <Form.Label className="text-muted form-text">
                    <strong>Variable Legend:</strong><br/>
                    &#123;OwnerUrl&#125; - Prints the owner's twitch url i.e. "twitch.tv/skrillex"<br/>
                    &#123;OwnerUsername&#125; - Prints the owner's twitch username i.e. "@skrillex"<br/>
                    &#123;EventTitle&#125; - Prints the title of your event.<br/>
                    &#123;Timezone&#125; - Prints the timezone of your event.<br/>
                    &#123;&#123;RepeatPerSignup &#125;&#125; - This allows you to repeat a line for every signup for your event. Place text and variables after "RepeatPerSignup". The below variables can only be used in this variable.<br/>
                    &#123;TwitchUrl&#125; - Prints the streamer's twitch url i.e. "twitch.tv/skrillex"<br/>
                    &#123;TwitchUsername&#125; - Prints the streamer's twitch username i.e. "@skrillex".<br/>
                    &#123;StartTime&#125; - Prints the streamer's start time.<br/>
                    &#123;EndTime&#125; - Prints the streamer's end time.
                  </Form.Label>
                </Form.Group>
                <Alert variant="danger" hidden={this.state.formError == ''} onClose={() => this.setState({ formError: '' })} dismissible>
                    {this.state.formError}
                </Alert>
                <Button hidden={this.state.submitting} variant="primary" type="button" onClick={this.onSubmit}>
                  Submit
                </Button>
                <Button hidden={!this.state.submitting} variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
        </CustomScrollbar>
        { 
          this.state.redirectTo != '' && <Navigate to={this.state.redirectTo} replace={true}/>
        }
      </>
    );
  }
}

export default EventSetup;