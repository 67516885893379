import './global.css';
import './error.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomScrollbar from '../customScrollbar/customScrollbar';
import errorImage from "../../assets/images/404.jpg";

export default function Error() {
  return (
    <CustomScrollbar addClass='scrollbarDefault'>
      <Container className="topPagePadding bottomPagePadding">
        <Row className="justify-content-center">
          <Col className="small floatCol">
            <div className="textCenter">
              <h1>Page not found!</h1>
              <img className="errorImage" src={errorImage} alt="Set Lineup Screenshot" />
            </div>
          </Col>
        </Row>
      </Container>
    </CustomScrollbar>
  )
}