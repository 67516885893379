import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import { doc, getDocs, setDoc, getDoc, updateDoc, query, where, deleteDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { lineupsCol } from '../services/database';
import TSUser from './user';
import { Timestamp } from 'firebase/firestore';
import { addUserLinedUp, removeUserLinedUp } from './timesheet';

export default interface Lineup {
  id?: string;
  timesheetId: string;
  userId: string;
  userTwitch?: string;
  timeslots: Timestamp[] | Moment[];
}

export const getLineup = async (id: string) => {
  const lineupRef = doc(lineupsCol, id);
  const lineupSnap = await getDoc(lineupRef);

  if (lineupSnap.exists()) {
    return lineupSnap.data();
  } else {
    return null;
  }
}

export const getLineupsByTimesheet = async (id: string) => {
  const q = query(lineupsCol, where("timesheetId", "==", id));
  let lineupList: Lineup[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    lineupList.push(doc.data());
  });
  return lineupList;
}

export const getLineupsForUser = async (userId: string, timesheetId?: string) => {
  let q;
  if (timesheetId) {
    q = query(lineupsCol, where("userId", "==", userId), where("timesheetId", "==", timesheetId));
  } else {
    q = query(lineupsCol, where("userId", "==", userId));
  }
  let lineupList: Lineup[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    lineupList.push(doc.data());
  });
  return lineupList;
}

export const setLineup = async (data: Lineup) => {
  const lineupRef = doc(lineupsCol);
  data.id = lineupRef.id;
  await setDoc(lineupRef, data);
  await addUserLinedUp(data.timesheetId, data.userId);
  return lineupRef.id;
}

export const updateLineup = async (id: string, data: {}) => {
  const lineupDocRef = doc(lineupsCol, id)
  await updateDoc(lineupDocRef, data);
  return lineupDocRef.id;
}

export const addDateToLineup = async(id: string, item: Timestamp) => {
  const tsRef = doc(lineupsCol, id);
  await updateDoc(tsRef, {
    timeslots: arrayUnion(item)
  });
}

export const removeDateFromLineup = async(id: string, item: Timestamp) => {
  const tsRef = doc(lineupsCol, id);
  await updateDoc(tsRef, {
    timeslots: arrayRemove(item)
  });
}

export const updateTwitchOnLineups = async (userId: string, twitchUsername: string) => {
  let q = query(lineupsCol, where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, {userTwitch: twitchUsername});
  });
}

export const deleteLineup = async (id: string) => {
  const lineupDocRef = doc(lineupsCol, id);
  const lineupDoc = await getDoc(lineupDocRef);
  if (lineupDoc.exists()) {
    await removeUserLinedUp(lineupDoc.data().timesheetId, lineupDoc.data().userId);
    await deleteDoc(lineupDocRef);
  }
}

export const deleteUserLineups = async (id: string) => {
  let q = query(lineupsCol, where("userId", "==", id));
  const querySnapshot = await getDocs(q);
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    let doc = querySnapshot.docs[i];
    if (doc.exists()) {
      await removeUserLinedUp(doc.data().timesheetId, id);
      await deleteDoc(doc.ref);
    }
  }
}

export const deleteTimesheetLineups = async (timesheetId: string) => {
  let q = query(lineupsCol, where("timesheetId", "==", timesheetId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    deleteDoc(doc.ref);
  });
}