import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './admin.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { Navigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import { confirmAlert } from 'react-confirm-alert';
import TSUser, { deleteUser, updateUser } from '../../models/user';
import CustomScrollbar from '../customScrollbar/customScrollbar';
import { Form } from 'react-bootstrap';

var _ = require('lodash');

type adminProps = {
  user: User;
  userData: TSUser;
  axios: any;
};

type adminState = {
  users: SiteUser[];
  submitting: boolean;
  success: string;
  error: string;
  redirectTo: string;
};

type SiteUser = {
  disabled: boolean;
  email: string;
  emailVerified: boolean;
  isManager: boolean | undefined;
  isAdmin: boolean | undefined;
  metadata: {
    creationTime: string;
    lastSignInTime: string;
  }
  passwordHash: string;
  passwordSalt: string;
  providerData: {
    email: string;
    providerId: string;
    uid: string;
    photoURL: string | undefined;
    displayName: string | undefined;
  }[];
  photoURL: string | undefined;
  timesheetsVisited: string[] | undefined;
  tokensValidAfterTime: string;
  twitchUsername: string | undefined;
  uid: string;
}

class Admin extends React.Component<adminProps, adminState> {
  constructor(props: adminProps) {
    super(props);
    
    this.state = {
      users: [],
      submitting: false,
      success: '',
      error: '',
      redirectTo: '',
    };

    this.getAllUsers = this.getAllUsers.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
    this.confirm = this.confirm.bind(this);
    this.deleteSiteUser = this.deleteSiteUser.bind(this);
    this.toggleManager = this.toggleManager.bind(this);
    this.toggleAdmin = this.toggleAdmin.bind(this);
    this.getUserCards = this.getUserCards.bind(this);
  }
  
  public async componentDidMount() {
    if (this.props.userData.id && this.props.userData.isAdmin) {
      this.getAllUsers();
    }
  }
  
  public async componentDidUpdate(prevProps: adminProps) {
    if (this.props.userData != prevProps.userData && this.props.userData.isAdmin && this.state.users.length == 0) {
      this.getAllUsers();
    }
  }

  public async getAllUsers() {
    this.props.axios
      .get("/users")
      .then((response: any) => {
        this.setState({users: response.data});
      })
      .catch((errors: any) => {
        this.setState({error: errors});
        console.log(errors);
      });
  }
  
  public confirm(action: Function, message: string, user: SiteUser, userIndex: number) {
    this.setState({ submitting: true });
    confirmAlert({
      title: 'Confirm',
      message: message,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => action(user, userIndex)
        },
        {
          label: 'Cancel',
          onClick: () => this.setState({ submitting: false })
        }
      ]
    });
  }

  public async deleteSiteUser(user: SiteUser, userIndex: number) {
    this.props.axios
      .delete("/user/" + user.uid)
      .then(async (response: any) => {
        await deleteUser(user.uid);
        let users = this.state.users;
        users.splice(userIndex, 1);
        this.setState({users: users, submitting: false});
      })
      .catch((errors: any) => {
        this.setState({error: errors, submitting: false});
        console.log(errors);
      });
  }

  public async toggleManager(user: SiteUser, userIndex: number) {
    await updateUser(user.uid, {isManager: !user.isManager});
    let users = this.state.users;
    users[userIndex].isManager = !users[userIndex].isManager;
    this.setState({ users: users, submitting: false });
  }
  
  public async toggleAdmin(user: SiteUser, userIndex: number) {
    await updateUser(user.uid, {isAdmin: !user.isAdmin});
    let users = this.state.users;
    users[userIndex].isAdmin = !users[userIndex].isAdmin;
    this.setState({ users: users, submitting: false });
  }

  public navigateTo(route: string) {
    this.setState({redirectTo: route});
  }
  
  public getUserCards() {
    let items = [];
     for (let i = 0; i < this.state.users.length; i++) {
      let thisUser = this.state.users[i];
      let username = thisUser.twitchUsername ? thisUser.twitchUsername : thisUser.email;
      let managerButtonText = thisUser.isManager ? "Revoke Manager" : `Make Manager`;
      let adminButtonText = thisUser.isAdmin ? "Revoke Admin" : `Make Admin`;
      let toggleManagerMessage = thisUser.isManager ? `Are you sure you want remove manager permissions from ${username}?` : `Are you sure you want give manager permissions to ${username}?`;
      let toggleAdminMessage = thisUser.isAdmin ? `Are you sure you want remove admin permissions from ${username}?` : `Are you sure you want give admin permissions to ${username}?`;
      let deleteUserMessage = `Are you sure you want to delete ${username}?`;
        items.push(
          <Card key={thisUser.uid} className={'fullWidth'}>
            <Card.Body>
              <Card.Title>{username}</Card.Title>
              <Card.Text>
                <>
                <strong>disabled:</strong> {thisUser.disabled ? "true" : "false"}<br/>
                {thisUser.twitchUsername && <><strong>email:</strong> {thisUser.email}<br/></>}
                <strong>emailVerified:</strong> {thisUser.emailVerified ? "true" : "false"}<br/>
                <strong>creationTime:</strong> {thisUser.metadata.creationTime}<br/>
                <strong>lastSignInTime:</strong> {thisUser.metadata.lastSignInTime}<br/>
                <strong>passwordHash:</strong> {thisUser.passwordHash}<br/>
                <strong>passwordSalt:</strong> {thisUser.passwordSalt}<br/>
                {thisUser.providerData.map((x, i) => {
                    return <span key={thisUser.uid + "-ProviderData" + i}>
                      <strong>Provider Data {i+1}</strong><br/>
                      &nbsp;&nbsp;&nbsp;&nbsp;<strong>email:</strong> {x.email}<br/>
                      &nbsp;&nbsp;&nbsp;&nbsp;<strong>providerId:</strong> {x.providerId}<br/>
                      &nbsp;&nbsp;&nbsp;&nbsp;<strong>uid:</strong> {x.uid}<br/>
                      {x.photoURL && <>&nbsp;&nbsp;&nbsp;&nbsp;<strong>photoURL:</strong> {x.photoURL}<br/></>}
                      {x.displayName && <>&nbsp;&nbsp;&nbsp;&nbsp;<strong>displayName:</strong>  {x.displayName}<br/></>}
                    </span>
                })}
                {thisUser.photoURL && <><strong>photoURL:</strong> {thisUser.photoURL}<br/></>}
                <strong>timesheetsVisited:</strong> {thisUser.timesheetsVisited && thisUser.timesheetsVisited.length > 0 ? thisUser.timesheetsVisited.join(", ") : "none"}<br/>
                <strong>tokensValidAfterTime:</strong> {thisUser.tokensValidAfterTime}<br/>
                <strong>uid:</strong> {thisUser.uid};
                </>
              </Card.Text>
                <Button variant="primary" disabled={this.state.submitting} onClick={() => this.confirm(this.toggleManager, toggleManagerMessage, thisUser, i)}>{managerButtonText}</Button>&nbsp;&nbsp;
                <Button variant="primary" disabled={this.state.submitting} onClick={() => this.confirm(this.toggleAdmin, toggleAdminMessage, thisUser, i)}>{adminButtonText}</Button>&nbsp;&nbsp;
                <Button variant="danger" disabled={this.state.submitting} onClick={() => this.confirm(this.deleteSiteUser, deleteUserMessage, thisUser, i)}>Delete</Button>&nbsp;&nbsp;
            </Card.Body>
          </Card>
        );
     }
     return items;
  }


  render() {
    return (
      <>
      <CustomScrollbar addClass='scrollbarDefault'>
      <Container className="topPagePadding bottomPagePadding">
        <Row className="justify-content-center">
          <Col className="large floatCol">
            <Alert variant="success" hidden={this.state.success == ''} onClose={() => this.setState({ success: '' })} dismissible>
                {this.state.success}
            </Alert>
            <Alert variant="danger" hidden={this.state.error == ''} onClose={() => this.setState({ error: '' })} dismissible>
                {this.state.error}
            </Alert><br/>
            { this.props.userData.isAdmin &&
              <>
                { this.state.users.length > 0 &&
                  <>
                    <h2>Site Users</h2><br/>
                    {this.getUserCards()}
                  </>
                }
                { this.state.users.length == 0 &&
                  <>
                    <h2>Loading Site Users...</h2>
                  </>
                }
              </>
            }
          </Col>
        </Row>
      </Container>
      </CustomScrollbar>
      { 
        this.state.redirectTo != '' && <Navigate to={this.state.redirectTo} replace={true}/>
      }
      </>
    );
  }
}

export default Admin;