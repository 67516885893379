import React from 'react';
import ReactDOM from 'react-dom/client';
import './lineupGrid.css';
import '../pages/global.css';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import { LineupGridCell } from '../pages/setLineup';
import Lineup from '../../models/userLineup';
import _, { last } from 'lodash';
import TSUser from '../../models/user';
import { TimeGridDataCell } from '../../models/userSignup';
import { Form } from 'react-bootstrap';
import MobileDropdown, { MobileDropdownOption } from '../mobileDropdown/mobileDropdown';
import { text } from 'node:stream/consumers';
import { signInAnonymously } from 'firebase/auth';

export interface lineupGridProps {
  gridData: LineupGridCell[];
  selectedRows: number[];
  updateSelectedRows: Function;
  updateSelectedUser: Function;
  updateGridData: Function;
};
export interface lineupGridState {
  dropdownOpenIndex: number;
  touchEnd: Moment;
};
export default class Lineupgrid extends React.Component<lineupGridProps, lineupGridState> {
  touchStart = false;
  touchMove = false;
  constructor(props: lineupGridProps) {
    super(props);

    this.state = {
      dropdownOpenIndex: -1,
      touchEnd: moment.default().subtract(1, "days")
    };
    this.onChangeLineupSlot = this.onChangeLineupSlot.bind(this);
    this.updateTouchEnd = this.updateTouchEnd.bind(this);
  }
  
  public async componentDidMount() {

  }

  public async componentDidUpdate(prevProps: lineupGridProps) {
    
  }

  private onTouchStart() {
    this.touchStart = true;
  }
  
  private onTouchEnd(e: React.TouchEvent<HTMLDivElement>, rowNumber:number) {
    this.touchStart = false;
    this.setState({touchEnd: moment.default()});

    if (this.touchMove) {
      this.touchMove = false;
    } else {
      if (this.props.selectedRows.length > 0 && this.props.selectedRows.includes(rowNumber)) {
        this.props.updateSelectedRows([]);
        this.props.updateSelectedUser({id: ""});
      } else {
        this.props.updateSelectedRows([rowNumber]);
        let user = {
          id: this.props.gridData[rowNumber].userId,
          twitchUsername: this.props.gridData[rowNumber].userTwitch
        }
        this.props.updateSelectedUser(user);
      }
    }
  }

  private onTouchMove() {
    if (this.touchStart && !this.touchMove) {
      this.touchMove = true;
    }
  }

  private onMouseDown(e: React.MouseEvent<HTMLDivElement | HTMLSpanElement>, rowNumber:number = -1) {
    let sinceLastTouchEnd = moment.default().diff(this.state.touchEnd);
    if (this.touchStart || sinceLastTouchEnd < 500) return; 
    if (rowNumber != -1) {
      if (this.props.selectedRows.length > 0 && this.props.selectedRows.includes(rowNumber)) {
        this.props.updateSelectedRows([]);
        this.props.updateSelectedUser({id: ""});
      } else {
        this.props.updateSelectedRows([rowNumber]);
        let user = {
          id: this.props.gridData[rowNumber].userId,
          twitchUsername: this.props.gridData[rowNumber].userTwitch
        }
        this.props.updateSelectedUser(user);
      }
    }
  }

  private onMouseUp(e: React.MouseEvent<HTMLDivElement | HTMLSpanElement>, rowNumber:number = -1) {
    
  }

  private updateTouchEnd(time: Moment) {
    this.setState({ touchEnd: time });
  }

  // public onChangeLineupSlot(event: any, rowNumber: number) {
  //   let gridData = this.props.gridData;
  //   let lastValue = {
  //     userId: gridData[rowNumber].userId,
  //     userTwitch: gridData[rowNumber].userTwitch
  //   }
  //   if (event.target.value != "") {
  //     let newValue = JSON.parse(event.target.value);
  //     gridData[rowNumber].userId = newValue.userId;
  //     gridData[rowNumber].userTwitch = newValue.userTwitch;
  //   } else {
  //     gridData[rowNumber].userId = undefined;
  //     gridData[rowNumber].userTwitch = undefined;
  //   }
  //   if (!((!gridData[rowNumber].userId && !lastValue.userId) || 
  //       (gridData[rowNumber].userId == lastValue.userId))) {
  //         this.props.updateGridData(gridData);
  //   }
  // }

  public onChangeLineupSlot(value: string, rowNumber: number) {
    let gridData = this.props.gridData;
    let lastValue = {
      userId: gridData[rowNumber].userId,
      userTwitch: gridData[rowNumber].userTwitch
    }
    if (value != "") {
      let newValue = JSON.parse(value);
      gridData[rowNumber].userId = newValue.userId;
      gridData[rowNumber].userTwitch = newValue.userTwitch;
    } else {
      gridData[rowNumber].userId = undefined;
      gridData[rowNumber].userTwitch = undefined;
    }
    if (!((!gridData[rowNumber].userId && !lastValue.userId) || 
        (gridData[rowNumber].userId == lastValue.userId))) {
          this.props.updateGridData(gridData);
    }
  }

  getLineupContent() {
    let numberStyle = {};
    let maxAvailNum = _.maxBy(this.props.gridData, function(o) {
      return o.numberAvailable;
    })?.numberAvailable;
    let maxPrefNum = _.maxBy(this.props.gridData, function(o) {
      return o.numberPrefered;
    })?.numberPrefered;
    let availableDoubleDigits = maxAvailNum && maxAvailNum >= 10;
    let preferedDoubleDigits = maxPrefNum && maxPrefNum >= 10;
    if (availableDoubleDigits && preferedDoubleDigits) {
      numberStyle = {
        width: 65
      };
    } else if (availableDoubleDigits || preferedDoubleDigits) {
      numberStyle = {
        width: 60
      };
    } else {
      numberStyle = {
        width: 50
      };
    }
    let content = [];
    for (let i = 0; i < this.props.gridData.length; i++) {
      // let signups = [<option key={"row" + i + "signupOptionDefault"} className={"unassigned"} value="">Available</option>];
      let signups: MobileDropdownOption[] = [
        {
          addClass: "unassigned",
          value: "",
          text: "Available"
        }
      ];
      let thisSignups = this.props.gridData[i].signups || [];
      let selectedAvailablility: string = "";
      for (let j = 0; j < thisSignups.length; j++) {
        // signups.push(<option key={"row" + i + "signupOption" + j} className={thisSignups[j].availability} value={'{"userId": "' + thisSignups[j].userId + '", "userTwitch": "' + thisSignups[j].userTwitch + '"}'}>{thisSignups[j].userTwitch}</option>);
        signups.push(
          {
            addClass: (thisSignups[j].availability as string),
            value: '{"userId": "' + thisSignups[j].userId + '", "userTwitch": "' + thisSignups[j].userTwitch + '"}',
            text: thisSignups[j].userTwitch
          }
        );
        // if (thisSignups[j].userId == this.props.gridData[i].userId) {
        //   selectedAvailablility = thisSignups[j].availability;
        // }
      }
      content.push(
        <div
        data-i={i}
        key={"row" + i}
        className={"lineupGridRow time"}
        onMouseDown={(e) => this.onMouseDown(e, i)}
        onMouseUp={(e) => this.onMouseUp(e,i)}
        onTouchStart={() => this.onTouchStart()}
        onTouchEnd={(e) => this.onTouchEnd(e, i)}
        >

          <div id={"time" + i} className={'lineupCell timeCell'}>
            {this.props.gridData[i].timeDisplay}
          </div>

          <div id={"lineupSlot" + i} className={'lineupCell lineupSlotCell ' + selectedAvailablility}>
            <MobileDropdown
              addClass={"form-select"}
              value={'{"userId": "' + this.props.gridData[i].userId + '", "userTwitch": "' + this.props.gridData[i].userTwitch + '"}'}
              onChange={this.onChangeLineupSlot}
              rowNumber={i}
              touchEnd={this.state.touchEnd}
              updateTouchEnd={this.updateTouchEnd}
              options={signups}
            />
          </div>

          <div className={'lineupCell availabilityCell'} style={numberStyle}>
            <span className='prefered'>{this.props.gridData[i].numberPrefered}</span> <span className='available'>{this.props.gridData[i].numberAvailable}</span>
          </div>

          <div className="selectionOverlay" hidden={!this.props.selectedRows.includes(i)}></div>
        </div>
      );
    }
    return content;
  };

  render() {

    return (
      <span
      className={"lineupGrid"}
      onMouseDown={(e) => this.onMouseDown(e)}
      onMouseUp={(e) => this.onMouseUp(e)}
      onTouchMove={() => this.onTouchMove()}
      >
        <span className='lineupGridRow header'>
          Lineup
        </span>
          {this.getLineupContent()}
      </span>
    );
  }
}