import firebase from '../firebase';
import { getFirestore, CollectionReference, collection, DocumentData } from 'firebase/firestore';

import TSUser from '../models/user';
import TimeSheet from '../models/timesheet';
import TimeSheetTemplate from '../models/timesheetTemplate';
import UserSignup from '../models/userSignup';
import Lineup from '../models/userLineup';


// Export firestore incase we need to access it directly
export const firestore = getFirestore();

// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

// export all your collections
export const usersCol = createCollection<TSUser>('users');
export const timesheetsCol = createCollection<TimeSheet>('timesheets');
export const timesheetTemplatesCol = createCollection<TimeSheetTemplate>('timesheetTemplates');
export const userSignupsCol = createCollection<UserSignup>('userSignups');
export const lineupsCol = createCollection<Lineup>('lineups');