import React from 'react';
import ReactDOM from 'react-dom/client';
import '../pages/global.css';
import './lineupBottomBar.css';
import {
  Container, Row, Col, Button
} from 'react-bootstrap';
import * as moment from 'moment-timezone';
import { Timestamp } from 'firebase/firestore';
import { LineupGridCell } from '../pages/setLineup';

export interface lineupBottomBarProps {
  gridData: LineupGridCell[];
  selectedRows: number[];
  updateGridData: Function;
  selectedUser: {
    id: string;
    twitchUsername?: string;
  };
  disabled: boolean;
};
export interface lineupBottomBarState {
  
};
export default class LineupBottomBar extends React.Component<lineupBottomBarProps, lineupBottomBarState> {
  constructor(props: lineupBottomBarProps) {
    super(props);

    this.state = {
      
    };

  }
  
  public async componentDidMount() {

  }

  public async componentDidUpdate(prevProps: lineupBottomBarProps) {

  }

  private setActionClick(isClear: boolean = false) {
    if (isClear || (!isClear && this.props.selectedUser && this.props.selectedUser.twitchUsername)) {
      let newGridData = this.props.gridData;
      for (let i = 0; i < this.props.selectedRows.length; i++) {
        if (isClear) {
          newGridData[this.props.selectedRows[i]].userId = undefined;
          newGridData[this.props.selectedRows[i]].userTwitch = undefined;
        } else {
          newGridData[this.props.selectedRows[i]].userId = this.props.selectedUser.id;
          newGridData[this.props.selectedRows[i]].userTwitch = this.props.selectedUser.twitchUsername;
        }
      }
      this.props.updateGridData(newGridData);
    }
  }

  render() {
    return (
      <Container fluid className='lineupBottomBar'>
        <Row>
          <Col className='noPadding'>
            <Button disabled={this.props.disabled} onClick={() => this.setActionClick()} className="setAction set">Set Timeslot</Button>
          </Col>
          <Col className='noPadding'>
            <Button disabled={this.props.disabled} onClick={() => this.setActionClick(true)} className="setAction clear">Clear Timeslot</Button>
          </Col>
        </Row>
      </Container>
    );
  }
}