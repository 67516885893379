import React, { Component, UIEventHandler } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import './customScrollbar.css';

interface CustomScrollbarProps {
  children: React.ReactNode;
  addClass?: string;
  style?: React.CSSProperties;
  onScroll?: any;
  scrollbarRef?: any;
}

export default class CustomScrollbar extends Component<CustomScrollbarProps> {
  render() {
    return (
      <Scrollbars
        className={'scrollbar ' + this.props.addClass ? this.props.addClass : ""}
        style={this.props.style}
        ref={this.props.scrollbarRef ? this.props.scrollbarRef : undefined}
        onScroll={this.props.onScroll ? this.props.onScroll : undefined}
        universal
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        >
        {this.props.children}
      </Scrollbars>
    );
  }
}