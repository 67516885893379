import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import { doc, getDocs, setDoc, getDoc, updateDoc, query, where, deleteDoc, arrayRemove, arrayUnion, FieldValue } from 'firebase/firestore';
import { timesheetsCol } from '../services/database';
import UserSignup, { deleteTimesheetSignups, getUserSignup, getUserSignupsByTimesheet } from './userSignup';
import { Timestamp } from 'firebase/firestore';
import TSUser, { removeViewedTimesheetFromAllUsers } from './user';
import UserLineup, { deleteTimesheetLineups, getLineupsByTimesheet } from './userLineup';

export interface UserSignups {
  usersSignedUp: string[];
  signups: UserSignup[];
}
export interface UserLineups {
  usersLinedUp: string[];
  lineups: UserLineup[];
}
export default interface TimeSheet {
  id?: string | null;
  title: string;
  start: Timestamp | null;
  end: Timestamp | null;
  interval: number;
  timezone: string;
  botTextTemplate: string;
  signups: UserSignups;
  lineup: UserLineups;
  owner: string;
  fromTemplate: string | null;
}

export const getTimesheet = async (id: string, withSignups: boolean = false, withLineups: boolean = false) => {
  const tsRef = doc(timesheetsCol, id);
  const tsSnap = await getDoc(tsRef);

  if (tsSnap.exists()) {
    let data = tsSnap.data();
    if (withSignups) {
      let signups = await getUserSignupsByTimesheet(id);
      data.signups.signups = signups;
    }
    if (withLineups) {
      let lineups = await getLineupsByTimesheet(id);
      data.lineup.lineups = lineups;
    }
    return data;
  } else {
    return null;
  }
}

export const getTimesheetsById = async (ids: string[]) => {
  const q = query(timesheetsCol, where("id", "in", ids));
  let tsList: TimeSheet[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    tsList.push(doc.data());
  });
  return tsList;
}

export const getAllTimesheets = async () => {
  const tsDocs = await getDocs(timesheetsCol);
  let tsList: any[] = [];
  tsDocs.docs.forEach((doc) => {
    tsList.push(doc.data());
  });
  return tsList;
}

export const getTimesheetsByOwner = async (id: string) => {
  const q = query(timesheetsCol, where("owner", "==", id));
  let tsList: TimeSheet[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    tsList.push(doc.data());
  });
  return tsList;
}

export const getTimesheetsBySignup = async (id: string) => {
  const q = query(timesheetsCol, where("signups.usersSignedUp", "array-contains", id));
  let tsList: TimeSheet[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    tsList.push(doc.data());
  });
  return tsList;
}

export const addUserSignedUp = async(id: string, item: string) => {
  const tsRef = doc(timesheetsCol, id);
  await updateDoc(tsRef, {
    // @ts-ignore
    "signups.usersSignedUp": arrayUnion(item)
  });
}

export const removeUserSignedUp = async(id: string, item: string) => {
  const tsRef = doc(timesheetsCol, id);
  await updateDoc(tsRef, {
    // @ts-ignore
    "signups.usersSignedUp": arrayRemove(item)
  });
}

export const removeUserSignedUps = async (id: string) => {
  const q = query(timesheetsCol, where("signups.usersSignedUp", "array-contains", id));
  const querySnapshot = await getDocs(q);
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    let doc = querySnapshot.docs[i];
    await updateDoc(doc.ref, {
      // @ts-ignore
      "signups.usersSignedUp": arrayRemove(id)
    });
  }
}

export const addUserLinedUp = async(id: string, item: string) => {
  const tsRef = doc(timesheetsCol, id);
  await updateDoc(tsRef, {
    // @ts-ignore
    "lineup.usersLinedUp": arrayUnion(item)
  });
}

export const removeUserLinedUp = async(id: string, item: string) => {
  const tsRef = doc(timesheetsCol, id);
  await updateDoc(tsRef, {
    // @ts-ignore
    "lineup.usersLinedUp": arrayRemove(item)
  });
}

export const removeUserLinedUps = async (id: string) => {
  const q = query(timesheetsCol, where("signups.usersLinedUp", "array-contains", id));
  const querySnapshot = await getDocs(q);
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    let doc = querySnapshot.docs[i];
    await updateDoc(doc.ref, {
      // @ts-ignore
      "signups.usersLinedUp": arrayRemove(id)
    });
  }
}

export const setTimesheet = async (data: TimeSheet) => {
  const tsRef = doc(timesheetsCol);
  data.id = tsRef.id;
  await setDoc(tsRef, data);
  return tsRef.id;
}

export const updateTimesheet = async (id: string, data: {}) => {
  const tsDocRef = doc(timesheetsCol, id);
  await updateDoc(tsDocRef, data);
  return tsDocRef.id;
}

export const deleteTimesheet = async (id: string) => {
  const tsDocRef = doc(timesheetsCol, id);
  await deleteDoc(tsDocRef);
  await deleteTimesheetSignups(id);
  await deleteTimesheetLineups(id);
  await removeViewedTimesheetFromAllUsers(id);
}

export const deleteUserTimesheets = async (id: string) => {
  const q = query(timesheetsCol, where("owner", "==", id));
  const querySnapshot = await getDocs(q);
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    let timesheet = querySnapshot.docs[i].data();
    if (timesheet.id) {
      await deleteTimesheet(timesheet.id);
    }
  }
}