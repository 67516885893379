import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './home.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomScrollbar from '../customScrollbar/customScrollbar';
import signupScreenshot from "../../assets/images/signup-screenshot.jpg";
import viewLineupScreenshot from "../../assets/images/viewLineup-screenshot.jpg";
import botTextScreenshot from "../../assets/images/botText-screenshot.jpg";
import setLineupScreenshot from "../../assets/images/setLineup-screenshot.jpg";

type homeProps = {
  
};

type homeState = {

};
class Home extends React.Component<homeProps, homeState> {
  state: homeState = {
    
  };
  render() {
    return (
      <CustomScrollbar addClass='scrollbarDefault'>
      <Container className="topPagePadding bottomPagePadding">
        <Row className="justify-content-center">
          <Col className="large floatCol">
            <h2>Welcome to Twitch Scheduler!</h2>
            <p>Twitch Scheduler is the next evolution of the google spreadsheets that we all know and love (or maybe hate)!</p>
            <p>No more:</p>
            <ul>
              <li>getting confused about timezones. Here, times are automatically converted to your timezone.</li>
              <li>putting your availability in the wrong column. Here you can only signup in your own column.</li>
              <li>forgetting to put type in your Twitch username. Here your Twitch username is already linked to your signup.</li>
            </ul>
            <p>You can also view the lineup once it's set and get the lineup text for your chatbots in your own timezone!</p>
            <p>As an event manager* you'll find many useful features like:</p>
            <ul>
              <li>Event Templates for creating recurring events.</li>
              <li>Create signups with timeslots of half an hour, an hour, or two hours</li>
              <li>Restricting the minimum and maximum timeslots that streamers can sign up for.</li>
              <li>Auto generated and customizable lineup text for your streamers' chatbots.</li>
              <li>Streamlined process for setting your lineup.</li>
            </ul>
            <p>For multiday events it is recommended to create a new event for each day. The signup sheet does not display the date with each timeslot. The lineup will still be generated correctly if there is a break in the raid train, however you may want separate bot text for each day of the event.</p>
            <h3>How to Use</h3>
            <p><strong>Signup</strong>: Select a row or multiple rows in any of the columns, then click prefered to mark that time as your prefered time, available to mark it as available time, or unavailable to remove prefered or available status from the selection. The row of two numbers in your column gives you a quick view of the most popular times that people have signed up for. Click the arrow to the left of your column to collapse it, giving you a better view of the current signups (especially on mobile). You can still edit your availability when it's collapsed. Click the timezone to toggle between your detected timezone and the event timezone.</p>
            <img className="screenshot" src={signupScreenshot} alt="Signup Screenshot" />
            <p><strong>View Lineup</strong>: Your timeslot in the lineup will be colored blue. Click the timezone to toggle between your timezone and the event timezone. Set lineup button is only available for the manager who owns the event.</p>
            <img className="screenshot" src={viewLineupScreenshot} alt="View Lineup Screenshot" />
            <p><strong>Bot Text</strong>: Bot text is automatically generated using the lineup data formatted by the template text under "Edit Bot Text." Variables wrapped in curly braces are used to insert text that comes from the event data. You can edit the template to adjust it to your liking. After editing, click the reset button to reset the template back to the template the event manager has set up. Click the timezone at the top of the page to toggle between your timezone and the event timezone. If you are the owner of the event, you can save the bot text template to the event by clicking "Save" or to the event template that was used to create the event by clicking "Save to Template".</p>
            <img className="screenshot" src={botTextScreenshot} alt="Bot Text Screenshot" />
            <p><strong>Set Lineup</strong>: This view is only available to the event manager that owns the event. The collapsable column to the right is the lineup. The lineup can still be set while collapsed. There are two ways to edit the lineup. 1) Click on the middle column of the lineup column to open up a dropdown of people who have signed up for that time, or 2) select time in a user column then click "Set Timeslot" to assign it to that user, or "Clear Timeslot" to make the selected time available again. Once at least one user has been added to the lineup, signups will be closed for the event and the "View Lineup" page will be available. Click the timezone at the top of the page to toggle between your timezone and the event timezone. </p>
            <img className="screenshot" src={setLineupScreenshot} alt="Set Lineup Screenshot" />
            <p><em><small>*To create events, your account must have event manager permissions. Currently, event manager status is by invite only. Contact <a target="_blank" href="https://discordapp.com/channels/@me/PapaBearVibes#1800/">PapaBearVibes#1800</a> on discord for more info.</small></em></p>
          </Col>
        </Row>
      </Container>
      </CustomScrollbar>
    );
  }
}

export default Home;