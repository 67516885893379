import React from 'react';
import ReactDOM from 'react-dom/client';
import '../pages/global.css';
import './signupBottomBar.css';
import {
  Container, Row, Col, Button
} from 'react-bootstrap';
import { TimeGridDataCell, SignupStatus } from '../../models/userSignup';
import * as moment from 'moment-timezone';
import { Timestamp } from 'firebase/firestore';

export interface signupBottomBarProps {
  gridData: TimeGridDataCell[];
  selectedRows: number[];
  updateGridData: Function;
  disabled: boolean;
};
export interface signupBottomBarState {
  
};
export default class SignupBottomBar extends React.Component<signupBottomBarProps, signupBottomBarState> {
  constructor(props: signupBottomBarProps) {
    super(props);

    this.state = {
      
    };

  }
  
  public async componentDidMount() {

  }

  public async componentDidUpdate(prevProps: signupBottomBarProps) {

  }

  private signupActionClick(action: SignupStatus) {
    let newGridData = this.props.gridData;
    for (let i = 0; i < this.props.selectedRows.length; i++) {
      newGridData[this.props.selectedRows[i]].status = action;
    }
    this.props.updateGridData(newGridData);
  }

  render() {
    return (
      <Container fluid className='signupBottomBar'>
        <Row>
          <Col className='noPadding'>
            <Button disabled={this.props.disabled} onClick={() => this.signupActionClick("prefered")} className="signupAction prefered">Prefered</Button>
          </Col>
          <Col className='noPadding'>
            <Button disabled={this.props.disabled} onClick={() => this.signupActionClick("available")} className="signupAction available">Available</Button>
          </Col>
          <Col className='noPadding'>
            <Button disabled={this.props.disabled} onClick={() => this.signupActionClick("unavailable")} className="signupAction unavailable">Unavailable</Button>
          </Col>
        </Row>
      </Container>
    );
  }
}