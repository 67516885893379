import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  User,
  sendEmailVerification,
  ActionCodeSettings,
  getIdToken,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult
} from "firebase/auth";
import { auth } from "../firebase";
import firebase from 'firebase/app';
import TSUser, { getUser as getTSUser, setUser as setTSUser } from '../models/user';
import { useLocalStorage } from 'usehooks-ts';

export interface userAuthContextType {
  user: User;
  userToken: string;
  userData: TSUser;
  login: Function;
  signup: Function;
  logout: Function;
  sendConfirmationEmail: Function;
  refreshUser: Function;
  refreshUserData: Function;
  sendResetPasswordEmail: Function;
  googleLogin: Function;
  googleLoginGetRedirect: Function;
}

export const userAuthContext = createContext<userAuthContextType | any>({});

export function UserAuthContextProvider({ children }: {children: any}) {
  const [user, setUser] = useState({});
  const userDataDefault: TSUser = {
    id: "",
    email: "",
    isManager: false,
    isAdmin: false,
    twitchUsername: "",
    timesheetsVisited: []
  };
  const [userToken, setUserToken] = useState("");
  const [userData, setUserData] = useState(userDataDefault);
  const [loggedIn, setLoggedIn] = useLocalStorage('loggedIn', false);
  
  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signup(email:string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function googleLogin() {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  }
  function googleLoginGetRedirect(success: Function, error: Function) {
    getRedirectResult(auth)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        if (result) {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential ? credential.accessToken : null;
          const user = result.user;
          success();
        }
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("googleLoginGetRedirect Error");
        console.log("Error code: " + errorCode + " Error Message: " + errorMessage);
        error(errorCode, errorMessage);
      });
  }
  function logout() {
    return signOut(auth);
  }
  function sendConfirmationEmail(thisUser: User, actionCode?: ActionCodeSettings) {
    if (actionCode) {
      return sendEmailVerification(thisUser, actionCode);
    } else {
      return sendEmailVerification(thisUser);
    }
  }
  async function refreshUserData() {
    let TSUser = await getTSUser(userData.id);
    if (TSUser != null) {
      setUserData(TSUser);
    }
  }
  async function refreshUser(thisUser: User) {
    let thisUserToken = await thisUser.getIdToken();
    setUserToken(thisUserToken);
    return thisUserToken;
  }
  function sendResetPasswordEmail(email: string, actionCode?: ActionCodeSettings) {
    if (actionCode) {
      return sendPasswordResetEmail(auth, email, actionCode);
    } else {
      return sendPasswordResetEmail(auth, email);
    }
  }

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, async (currentuser) => {
      console.log("Auth", currentuser);
      if (currentuser) {
        setLoggedIn(true);
        setUser(currentuser);
        if (userData.id != currentuser.uid) {
          let TSUser = await getTSUser(currentuser.uid);
          if (TSUser == null) {
            TSUser = {
              id: currentuser.uid,
              email: currentuser.email || '',
              isManager: false,
              isAdmin: false,
              twitchUsername: '',
              timesheetsVisited: []
            };
            await setTSUser(TSUser);
          } else {
            setUserData(TSUser);
            setUserData(TSUser);
            let thisUserToken = await currentuser.getIdToken();
            setUserToken(thisUserToken);
          }
        }
      } else {
        setLoggedIn(false);
        setUser(userDataDefault);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, userToken, userData, refreshUserData, login, signup, logout, sendConfirmationEmail, refreshUser, sendResetPasswordEmail, googleLogin, googleLoginGetRedirect }}
    >
      {children}
    </userAuthContext.Provider>
  );
}