import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './login.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { Link, Navigate } from 'react-router-dom';
import CustomScrollbar from '../customScrollbar/customScrollbar';
import GoogleButton from "react-google-button";

type loginProps = {
  login: Function;
  googleLogin: Function;
  googleLoginGetRedirect: Function;
};

type loginState = {
  email: string;
  emailError: string;
  password: string;
  passwordHasUppercase: boolean;
  passwordHasLowercase: boolean;
  passwordHasNumber: boolean;
  passwordLongEnough: boolean;
  passwordError: string;
  formError: string;
  submitting: boolean;
  validated: boolean;
  redirectTo: string;
};
class Login extends React.Component<loginProps, loginState> {
  redirectTo: string | null;

  constructor(props: loginProps) {
    super(props);

    this.state = {
      email: '',
      emailError: '',
      password: '',
      passwordHasUppercase: false,
      passwordHasLowercase: false,
      passwordHasNumber: false,
      passwordLongEnough: false,
      passwordError: '',
      formError: '',
      submitting: false,
      validated: false,
      redirectTo: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
    this.runAllValidators = this.runAllValidators.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.handleGoogleSignIn = this.handleGoogleSignIn.bind(this);

    let urlParams = new URLSearchParams(window.location.search);
    this.redirectTo = urlParams.get('redirectTo');

  }

  
  public async componentDidMount() {
    this.props.googleLoginGetRedirect(
      () => {
        this.setState({ redirectTo: this.redirectTo ? this.redirectTo : "/dashboard" });
      },
      (errorCode: any, errorMessage: any) => {
        let error = "Error (" + errorCode + "): " + errorMessage;
        this.setState({ formError: error, submitting: false });
      }
    );
  }

  public async componentDidUpdate(prevProps: loginProps) {

  }

  public async onSubmit() {
    if (this.isValid()) {
      this.setState({ submitting: true, formError: '' });
      try {
        await this.props.login(this.state.email, this.state.password);
        this.setState({ redirectTo: this.redirectTo ? this.redirectTo : "/dashboard" });
      } catch (err: any) {
        this.setState({ formError: err.message, submitting: false });
      }
    }
  }

  public async handleGoogleSignIn(e: any) {
    await this.props.googleLogin();
  }

  public isValid() {
    let valid = this.runAllValidators();
    this.setState({ validated: true });
    if (valid) {
      return true;
    } else {
      this.setState({ formError: "Please fix invalid field(s)." });
      return false;
    }
  }

  public runAllValidators() {
    let emailValid = this.validateEmail(this.state.email);
    let passwordValid = this.validatePassword(this.state.password);
    return emailValid && passwordValid;
  }

  public onEmailChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let newValue = event.target.value;
    this.validateEmail(newValue);
    this.setState({ email: newValue || '' });
  }

  public validateEmail(value?: string) {
    if (value && value != "" && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      let formError = this.state.formError;
      if (this.state.passwordError == '') {
        formError = '';
      }
      this.setState({ emailError: "", formError: formError });
      return true;
    } else {
      this.setState({ emailError: "Please enter a valid email." });
      return false;
    }
  }

  public onPasswordChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let newValue = event.target.value;
    this.validatePassword(newValue);
    this.setState({ password: newValue || '' });
  }

  public validatePassword(value?: string) {
    let hasLower = false;
    let hasUpper = false;
    let hasNumber = false;
    let longEnough = false;
    if (value && value != '') {
      if (/[a-z]/.test(value)) {
        hasLower = true;
      }
      if (/[A-Z]/.test(value)) {
        hasUpper = true;
      }
      if (/[0-9]/.test(value)) {
        hasNumber = true;
      }
      if (value.length >= 10) {
        longEnough = true;
      }
    }

    let isValid;
    let passwordError;
    let formError = this.state.formError;
    if (hasLower && hasUpper && hasNumber && longEnough) {
      if (this.state.emailError == '') {
        formError = '';
      }
      passwordError = '';
      isValid = true;
    } else {
      passwordError = 'Your password is invalid.';
      isValid = false;
    }

    this.setState({
      passwordHasLowercase: hasLower,
      passwordHasUppercase: hasUpper,
      passwordHasNumber: hasNumber,
      passwordLongEnough: longEnough,
      passwordError: passwordError,
      formError: formError
    });
    return isValid;
  }

  render() {
    return (
      <>
      <CustomScrollbar addClass='scrollbarDefault'>
        <Container className="topPagePadding bottomPagePadding">
          <Row className="justify-content-center">
            <Col className="small floatCol">
            <h2 className='mb-4'>Login</h2>
            <Alert variant="danger" hidden={this.state.formError == ''} onClose={() => this.setState({ formError: '' })} dismissible>
                {this.state.formError}
            </Alert>
              <Form>
                <Form.Group className="loginForm mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address</Form.Label>&nbsp;&nbsp;
                  <Form.Label
                    hidden={!this.state.validated || this.state.emailError == ''}
                    className="text-muted form-text invalid">
                    {this.state.emailError}
                  </Form.Label>
                  <Form.Control type="email" placeholder="email@email.com" value={this.state.email} onChange={this.onEmailChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>&nbsp;&nbsp;
                  <Form.Label
                    hidden={!this.state.validated || this.state.passwordError == ''}
                    className="text-muted form-text invalid">
                    {this.state.passwordError}
                  </Form.Label>
                  <Form.Control type="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange} />
                  <Form.Text className="text-muted">
                    Your password must meet this criteria:<br/>
                    <span className={this.state.passwordHasLowercase ? "valid" : ""}>1 lowercase letter</span><br/>
                    <span className={this.state.passwordHasUppercase ? "valid" : ""}>1 uppercase letter</span><br/>
                    <span className={this.state.passwordHasNumber ? "valid" : ""}>1 number</span><br/>
                    <span className={this.state.passwordLongEnough ? "valid" : ""}>10 characters total</span>
                  </Form.Text>
                </Form.Group>
                <Button hidden={this.state.submitting} variant="primary" type="button" onClick={this.onSubmit}>
                  Submit
                </Button>
                <Button hidden={!this.state.submitting} variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
                </Button><br/><br/>
                <div>
                  <GoogleButton
                    className="g-btn"
                    type="dark"
                    onClick={this.handleGoogleSignIn}
                  />
                </div><br/>
                <Form.Text className="text-muted">
                  Don't have an account? <Link to={{ pathname: "/register" }}>Please register here</Link>.
                </Form.Text><br/><br/>
                <Form.Text className="text-muted">
                  Forgot your password? <Link to={{ pathname: "/resetPassword" }}>Click here to reset.</Link>.
                </Form.Text>
              </Form>
            </Col>
          </Row>
        </Container>
        </CustomScrollbar>
        { 
          this.state.redirectTo != '' && <Navigate to={this.state.redirectTo} replace={true}/>
        }
      </>
    );
  }
}

export default Login;