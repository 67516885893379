import React from 'react';
import ReactDOM from 'react-dom/client';
import './timeGrid.css';
import '../pages/global.css';
import * as moment from 'moment-timezone'
import { Moment } from 'moment';
import { BiCommentEdit, BiCommentDetail } from 'react-icons/bi';
import { TimeGridDataCell, SignupStatus } from '../../models/userSignup';
import _, { last } from 'lodash';
import TSUser from '../../models/user';
import { Button, Form } from 'react-bootstrap';

export interface timeGridProps {
  user: {
    id: string;
    twitchUsername?: string;
    currentUser?: boolean;
    notes: string;
  };
  gridData: TimeGridDataCell[];
  selectedRows: number[];
  updateSelectedRows: Function;
  columnSelected: boolean;
  updateSelectedUser: Function;
  showTime?: boolean;
  showSignupNumbers?: boolean;
  updateNotes: Function;
};
export interface timeGridState {
  dragging: boolean;
  multiselecting: boolean;
  lastTouchMovedOver: number;
  beforeClickingSelection: number[];
  beforeDraggingSelection: number[];
  lastSelectedRow: number;
  notesHover: boolean;
  notesOpen: boolean;
  notesInput: string;
  user: {
    id: string;
    twitchUsername?: string;
    currentUser?: boolean;
    notes: string;
  };
  notEmpty: boolean;
  isCurrentUser: boolean;
  hasNotes: boolean;
};
export default class TimeGrid extends React.Component<timeGridProps, timeGridState> {
  touchStart = false;
  wrapperRefNotes: any;
  textRef: any;
  touchEnd: Moment = moment.default().subtract(1, "days");
  touchMove = false;
  constructor(props: timeGridProps) {
    super(props);

    this.state = {
      beforeClickingSelection: [],
      beforeDraggingSelection: [],
      dragging: false,
      lastTouchMovedOver: -1,
      multiselecting: false,
      lastSelectedRow: -1,
      notesHover: false,
      notesOpen: false,
      notesInput: this.props.user.notes,
      user: this.props.user,
      notEmpty: this.props.user.twitchUsername != undefined && this.props.user.twitchUsername != "",
      isCurrentUser: this.props.user.currentUser as boolean,
      hasNotes: this.props.user.notes != undefined && this.props.user.notes != "",
    };
    this.wrapperRefNotes = React.createRef();
    this.textRef = React.createRef();
    this.commentClick = this.commentClick.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onNotesMouseEnter = this.onNotesMouseEnter.bind(this);
    this.onNotesMouseLeave = this.onNotesMouseLeave.bind(this);
    this.onNotesTouchEnd = this.onNotesTouchEnd.bind(this);
    this.onNotesTouchStart = this.onNotesTouchStart.bind(this);
    this.getTimesContent = this.getTimesContent.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onChangeNotes = this.onChangeNotes.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.getTimesContent = this.getTimesContent.bind(this);
  }
  
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  
  public async componentDidUpdate(prevProps: timeGridProps) {
    if (this.textRef && this.textRef.current) {
      this.textRef.current.style.height = "0px";
      const taHeight = this.textRef.current.scrollHeight + 2;
      this.textRef.current.style.height = taHeight + "px";
    }
    if (prevProps.user != this.props.user) {
      this.setState({ 
        notesInput: this.props.user.notes,
        user: this.props.user,
        notEmpty: this.props.user.twitchUsername != undefined && this.props.user.twitchUsername != "",
        isCurrentUser: this.props.user.currentUser as boolean,
        hasNotes: this.props.user.notes != undefined && this.props.user.notes != "" 
      });
    }
  }
  
  handleClickOutside(event: any) {
    if (!event.target.classList.contains("notesIcon") && 
        !event.target.classList.contains('txtEditNotes') && 
        !event.target.classList.contains('btn') && 
        this.wrapperRefNotes && this.wrapperRefNotes.current && !this.wrapperRefNotes.current.contains(event.target) &&
        this.state.notesOpen) {
      this.setState({ notesOpen: false, notesHover: false, notesInput: this.state.user.notes || "" || "" });
    }
  }

  private onTouchStart() {
    this.touchStart = true;
  }
  
  private onTouchEnd(e: React.TouchEvent<HTMLDivElement>, rowNumber:number) {
    this.touchStart = false;
    this.touchEnd = moment.default();

    if (this.touchMove) {
      this.touchMove = false;
    } else {
      let newSelectedRows: number[] = [];
      if (this.props.selectedRows.length == 0) {
        newSelectedRows = [rowNumber];
      } else if (this.props.selectedRows.length == 1) {
        if (rowNumber > this.props.selectedRows[0]) {
          for (let i = this.props.selectedRows[0]; i <= rowNumber; i++) {
            newSelectedRows.push(i);
          }
        } else if (rowNumber < this.props.selectedRows[0]) {
          for (let i = this.props.selectedRows[0]; i >= rowNumber; i--) {
            newSelectedRows.push(i);
          }
        } else {
          newSelectedRows = [];
        }
      } else {
        if (!this.props.selectedRows.includes(rowNumber)) {
          newSelectedRows = [rowNumber];
        } else {
          newSelectedRows = [];
        }
      }
      this.props.updateSelectedRows(newSelectedRows);
      this.props.updateSelectedUser(this.state.user);
    }
  }

  private onTouchMove() {
    if (this.touchStart && !this.touchMove) {
      this.touchMove = true;
    }
  }

  private onMouseDown(e: React.MouseEvent<HTMLDivElement | HTMLSpanElement>, rowNumber:number = -1) {
    let sinceLastTouchEnd = moment.default().diff(this.touchEnd);
    if (this.touchStart || sinceLastTouchEnd < 500) return; 
    if (rowNumber != -1) {
      if (this.state.dragging == true) {
        let newSelectedRows: number[] = this.props.selectedRows;
        newSelectedRows.push(rowNumber);
        this.props.updateSelectedRows(newSelectedRows);
      } else {
        let lastSelectedRow = this.state.lastSelectedRow;
        let selectedRows = this.props.selectedRows;
        let beforeClickingSelection = this.state.beforeClickingSelection;
        if (e.ctrlKey && e.shiftKey) {
          if (this.props.selectedRows.length > 0 && lastSelectedRow != -1) {
            if (rowNumber > lastSelectedRow) {
              for (let i = lastSelectedRow; i <= rowNumber; i++) {
                selectedRows.push(i);
              }
            } else if (rowNumber < lastSelectedRow) {
              for (let i = lastSelectedRow; i >= rowNumber; i--) {
                selectedRows.push(i);
              }
            }
            this.props.updateSelectedRows(selectedRows);
          } else {
            this.props.updateSelectedRows([rowNumber]);
            lastSelectedRow = rowNumber;
          }
        } else if (e.shiftKey) {
          if (this.props.selectedRows.length > 0 && lastSelectedRow != -1) {
            if (rowNumber > lastSelectedRow) {
              selectedRows = [];
              for (let i = lastSelectedRow; i <= rowNumber; i++) {
                selectedRows.push(i);
              }
              this.props.updateSelectedRows(selectedRows);
            } else if (rowNumber < lastSelectedRow) {
              selectedRows = [];
              for (let i = lastSelectedRow; i >= rowNumber; i--) {
                selectedRows.push(i);
              }
              this.props.updateSelectedRows(selectedRows);
            } else {
              this.props.updateSelectedRows([rowNumber]);
              lastSelectedRow = rowNumber;
            }
          } else {
            this.props.updateSelectedRows([rowNumber]);
            lastSelectedRow = rowNumber;
          }
        } else if (e.ctrlKey) {
          if (!selectedRows.includes(rowNumber)) {
            selectedRows.push(rowNumber);
            this.props.updateSelectedRows(selectedRows);
            lastSelectedRow = rowNumber;
          }
        } else {
          this.props.updateSelectedRows([rowNumber]);
          lastSelectedRow = rowNumber;
          beforeClickingSelection = [this.state.lastSelectedRow];
        }
        this.setState({ lastSelectedRow: lastSelectedRow, beforeClickingSelection: beforeClickingSelection });
      }
    } else {
      this.setState({ dragging: true, beforeDraggingSelection: this.props.selectedRows });
    }
    this.props.updateSelectedUser(this.state.user);
  }

  private onMouseUp(e: React.MouseEvent<HTMLDivElement | HTMLSpanElement>, rowNumber:number = -1) {
    if (rowNumber != -1) {
      let selectedRows = [...this.props.selectedRows];
      let beforeClickingSelection = [...this.state.beforeClickingSelection];
      if (!beforeClickingSelection.includes(rowNumber)) {
        beforeClickingSelection.push(rowNumber);
      }
      let lastSelectedRow = this.state.lastSelectedRow;
      if (e.ctrlKey && this.state.beforeClickingSelection.includes(rowNumber) && this.state.beforeDraggingSelection.includes(rowNumber)) {
        let i = 0;
        while(i < beforeClickingSelection.length) {
          if (beforeClickingSelection[i] === rowNumber) {
            beforeClickingSelection.splice(i, 1);
          } else {
            ++i;
          }
        }
        i = 0;
        while(i < selectedRows.length) {
          if (selectedRows[i] === rowNumber) {
            selectedRows.splice(i, 1);
          } else {
            ++i;
          }
        }
        this.props.updateSelectedRows(selectedRows);
      } else if (this.props.selectedRows.length == 1 && this.state.beforeClickingSelection.length == 1 && this.state.beforeClickingSelection[0] == rowNumber) {
        beforeClickingSelection = [];
        selectedRows = [];
        lastSelectedRow = -1;
        this.props.updateSelectedRows(selectedRows);
      }
      this.setState({ beforeClickingSelection: beforeClickingSelection, lastSelectedRow: lastSelectedRow });
    } else {
      this.setState({ dragging: false, beforeDraggingSelection: [], multiselecting: false });
    }
  }

  private onMouseLeave() {
    this.setState({ dragging: false, beforeDraggingSelection: [], multiselecting: false, lastTouchMovedOver: -1 });
  }

  private onMouseEnter(e: React.MouseEvent<HTMLDivElement>, rowNumber: number) {

    e.preventDefault();
    e.stopPropagation();

    if (this.state.dragging) {
      let newSelectedRows: number[] = this.props.selectedRows;
      let beforeDraggingSelection = [...this.state.beforeDraggingSelection];
      if (rowNumber > this.state.lastSelectedRow) {
        newSelectedRows = [];
        for (let i = this.state.lastSelectedRow; i <= rowNumber; i++) {
          newSelectedRows.push(i);
          if (this.state.beforeDraggingSelection.includes(i)) {
            let i = 0;
            while(i < beforeDraggingSelection.length) {
              if (beforeDraggingSelection[i] === rowNumber) {
                beforeDraggingSelection.splice(i, 1);
              } else {
                ++i;
              }
            }
          }
        }
      } else if (rowNumber < this.state.lastSelectedRow) {
        newSelectedRows = [];
        for (let i = this.state.lastSelectedRow; i >= rowNumber; i--) {
          newSelectedRows.push(i);
          if (this.state.beforeDraggingSelection.includes(i)) {
            let i = 0;
            while(i < beforeDraggingSelection.length) {
              if (beforeDraggingSelection[i] === rowNumber) {
                beforeDraggingSelection.splice(i, 1);
              } else {
                ++i;
              }
            }
          }
        }
      } else {
        newSelectedRows = [rowNumber];
      }
      
      if (e.ctrlKey) {
        newSelectedRows = newSelectedRows.concat(beforeDraggingSelection);
      }

      let multiselecting = newSelectedRows.length > 1 ? true : this.state.multiselecting ? true : false;
      this.props.updateSelectedRows(newSelectedRows);
      this.setState({ multiselecting: multiselecting, beforeDraggingSelection: beforeDraggingSelection, beforeClickingSelection: newSelectedRows });
    }
  };

  private getTimesContent() {
    let numberStyle = {};
    if (this.props.showSignupNumbers) {
      let maxAvailNum = _.maxBy(this.props.gridData, function(o) {
        return o.numberAvailable;
      })?.numberAvailable;
      let maxPrefNum = _.maxBy(this.props.gridData, function(o) {
        return o.numberPrefered;
      })?.numberPrefered;
      let availableDoubleDigits = maxAvailNum && maxAvailNum >= 10;
      let preferedDoubleDigits = maxPrefNum && maxPrefNum >= 10;
      if (availableDoubleDigits && preferedDoubleDigits) {
        numberStyle = {
          width: 65
        };
      } else if (availableDoubleDigits || preferedDoubleDigits) {
        numberStyle = {
          width: 60
        };
      } else {
        numberStyle = {
          width: 50
        };
      }
    }
    let content = [];
    for (let i = 0; i < this.props.gridData.length; i++) {
      content.push(
        <div
        data-i={i}
        key={"row" + i}
        className={"timeGridRow time " + this.props.gridData[i].status}
        onMouseDown={(e) => this.onMouseDown(e, i)}
        onMouseUp={(e) => this.onMouseUp(e,i)}
        onMouseEnter={e => this.onMouseEnter(e, i)}
        onTouchStart={() => this.onTouchStart()}
        onTouchEnd={(e) => this.onTouchEnd(e, i)}
        //onTouchMove={e => this.onTouchMove(e)}
        >
          <div id={"time" + i} className={'signupCell timeCell ' + (!this.props.showSignupNumbers ? "noSignupNumbers" : "")}>
            {this.props.showTime ?
                this.props.gridData[i].timeDisplay
              :
              <br/>
            }
          </div>
          {this.props.showSignupNumbers &&
          <div className={'signupCell availabilityCell'} style={numberStyle}>
            <span className='prefered'>{this.props.gridData[i].numberPrefered}</span> <span className='available'>{this.props.gridData[i].numberAvailable}</span>
          </div>
          }
          <div className="selectionOverlay" hidden={!this.props.selectedRows.includes(i)}></div>
        </div>
      );
    }
    return content;
  };

  private commentClick(event: any) {
    let sinceLastTouchEnd = moment.default().diff(this.touchEnd);
    if (this.touchStart || sinceLastTouchEnd < 500) return; 
    if (!event.target.classList.contains('txtEditNotes') && 
    !event.target.classList.contains('btn')) {
      this.setState({ notesHover: false, notesOpen: !this.state.notesOpen });
    }
  }

  private onNotesMouseEnter(e: React.MouseEvent<HTMLElement>) {
    if (!this.state.notesOpen) {
      this.setState({ notesHover: true });
    }
  }

  private onNotesMouseLeave(e: React.MouseEvent<HTMLElement>) {
    if (!this.state.notesOpen && this.state.notesHover) {
      this.setState({ notesHover: false });
    }
  }

  private onNotesTouchStart(e: React.TouchEvent<HTMLElement>) {
    this.touchStart = true;
  }

  private onNotesTouchEnd(e: any) {
    this.touchStart = false;
    this.touchEnd = moment.default();

    if (this.touchMove) {
      this.touchMove = false;
    } else if (this.state.isCurrentUser || this.state.hasNotes) {
      if (!e.target.classList.contains('txtEditNotes') && 
          !e.target.classList.contains('btn')) {
        this.setState({ notesHover: false, notesOpen: !this.state.notesOpen });
      }
    }
  }

  private onChangeNotes(event: any) {
    let newValue = event.target.value || '';
    if (newValue.length < 200) {
      this.setState({ notesInput: newValue });
    }
  }

  private async saveNotes() {
    let user = this.state.user;
    user.notes = this.state.notesInput;
    await this.props.updateNotes(user);
    this.setState({ notesHover: false, notesOpen: false });
  }

  private onKeyDown(event: any) {
    //console.log("key pressed: " + event.key);
    if(event.key === 'Enter'){
      event.preventDefault();
      this.saveNotes();
    }
  }

  render() {

    return (
      <span
      className={"timeGrid"}
      onMouseDown={(e) => this.onMouseDown(e)}
      onMouseUp={(e) => this.onMouseUp(e)}
      onMouseLeave={() => this.onMouseLeave()}
      onTouchMove={() => this.onTouchMove()}
      >
        <span
          ref={this.wrapperRefNotes}
          className={'timeGridRow username ' + 
          (this.state.isCurrentUser ? 'currentUser ' : '') +
          (this.state.hasNotes ? 'hasNotes' : '')
          }
          onTouchStart={(e) => this.onNotesTouchStart(e)}
          onTouchEnd={(e) => this.onNotesTouchEnd(e)}
          onTouchMove={() => this.onTouchMove()}
          onMouseEnter={(e) => this.onNotesMouseEnter(e)}
          onMouseLeave={(e) => this.onNotesMouseLeave(e)}
          onClick={this.commentClick}
          >
          {this.state.notEmpty ? this.state.user.twitchUsername : <br/>}
          {this.state.isCurrentUser &&
          <>
            &nbsp;<BiCommentEdit
             className='notesIcon edit'
             />
          </>
          }
          {!this.state.isCurrentUser && this.state.hasNotes &&
          <>
            &nbsp;<BiCommentDetail
             className='notesIcon'
             />
          </>
          }
          <div className="selectionOverlay" hidden={!this.props.columnSelected}></div>
          {this.state.hasNotes &&
            <div className={"notesPopup view " + (this.state.notesOpen && "open")}
              hidden={
                (!this.state.isCurrentUser && (!this.state.notesHover && !this.state.notesOpen)) ||
                (this.state.isCurrentUser && (this.state.notesOpen || !this.state.notesHover))
              }
            >
              <p><strong>Notes</strong></p>
              <p>{this.state.user.notes}</p>
            </div>
          }
          {this.state.isCurrentUser &&
            <div
              className={"notesPopup edit " + (this.state.notesOpen && "open")}
              hidden={!this.state.notesOpen}
            >
              <p><strong>Edit Notes</strong></p>
              <Form.Control
                ref={this.textRef}
                className="txtEditNotes"
                as="textarea"
                placeholder={"Enter signup notes."}
                value={this.state.notesInput}
                onChange={this.onChangeNotes} 
                onKeyDown={this.onKeyDown}
                  /><br/>
                  <Button className='save' variant="primary" type="submit" onClick={this.saveNotes}>Save</Button><br/>
                  <Button className='cancel' variant="danger" type="button" onClick={() => this.setState({ notesOpen: false, notesInput: this.state.user.notes || "" })}>Cancel</Button>
            </div>
          }
        </span>
        {this.getTimesContent()}
        <div className="colSelectionOverlay" hidden={!this.props.columnSelected}></div>
      </span>
    );
  }
}