import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './setLineup.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import { Navigate } from 'react-router-dom';
import TimeGrid from '../timeGrid/timeGrid';
import TSUser from '../../models/user';
import { User } from 'firebase/auth';
import { confirmAlert } from 'react-confirm-alert';
import TimeSheet, { getTimesheet } from '../../models/timesheet';
import UserSignup, { TimeGridDataCell, SignupStatus, updateUserSignup } from '../../models/userSignup';
import { Timestamp } from 'firebase/firestore';
import CustomScrollbar from '../customScrollbar/customScrollbar';
import Lineup, { addDateToLineup, deleteLineup, getLineupsByTimesheet, removeDateFromLineup, setLineup } from '../../models/userLineup';
import LineupBottomBar from '../lineupBottomBar/lineupBottomBar';
import LineupGrid from '../lineupGrid/lineupGrid';
import { iDictionary, sDictionary } from '../../utils/myTypes';
import { Link } from 'react-router-dom';

var _ = require('lodash');

export interface LineupGridCell {
  userId?: string;
  userTwitch?: string;
  lineupId?: string;
  signups?: {
    userId: string;
    userTwitch: string;
    availability: SignupStatus;
  }[];
  timeslot: Moment;
  timeDisplay: string;
  endDisplay?: string;
  numberPrefered: number;
  numberAvailable: number;
}

export interface setLineupProps {
  user: User;
  userData: TSUser;
};

export interface setLineupState {
  lineupGrid: LineupGridCell[];
  usersGrid: {
    user: {
      id: string;
      twitchUsername?: string;
      currentUser?: boolean;
      notes: string;
    };
    grid: TimeGridDataCell[];
  }[];
  preferedNums: number[];
  availableNums: number[];
  selectedRows: number[];
  selectedUser: {
    id: string;
    twitchUsername?: string;
    currentUser?: boolean;
    notes: string;
  };
  redirectTo: string;
  timesheet: TimeSheet | null;
  lineupColWidth: number;
  lineupColCollapsed: boolean;
  viewTimezone: string;
  submitting: boolean;
  thisUserSignup: UserSignup | null,
};

export default class SetLineup extends React.Component<setLineupProps, setLineupState> {
  timesheetId: string | null;
  emptyGrid: TimeGridDataCell[];
  resizeObserver: any;
  lineupColRef: any;
  usersScrollbar: any;
  lineupScrollbar: any;
  userTimezone: string;
  timesheetTimezone: string;

  constructor(props: setLineupProps) {
    super(props);

    this.emptyGrid = [];
    this.lineupColRef = React.createRef();
    this.usersScrollbar = React.createRef();
    this.lineupScrollbar = React.createRef();
    this.userTimezone = moment.tz.guess();
    this.timesheetTimezone = "";

    this.state = {
      lineupGrid: [],
      usersGrid: [],
      preferedNums: [],
      availableNums: [],
      selectedRows: [],
      selectedUser: {
        id: "",
        notes: ""
      },
      redirectTo: "",
      timesheet: null,
      lineupColWidth: 0,
      lineupColCollapsed: false,
      viewTimezone: this.userTimezone,
      submitting: true,
      thisUserSignup: null
    };

    this.getTimeGridData = this.getTimeGridData.bind(this);
    this.updateSelectedRows = this.updateSelectedRows.bind(this);
    this.updateSelectedUser = this.updateSelectedUser.bind(this);
    this.updateLineupGrid = this.updateLineupGrid.bind(this);
    this.alertIdError = this.alertIdError.bind(this);
    this.getData = this.getData.bind(this);
    this.getAvailabilityNum = this.getAvailabilityNum.bind(this);
    this.getUsersCol = this.getUsersCol.bind(this);
    this.getStartDate = this.getStartDate.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onCollapseClick = this.onCollapseClick.bind(this);
    this.toggleTimezone = this.toggleTimezone.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    
    let urlParams = new URLSearchParams(window.location.search);
    this.timesheetId = urlParams.get('timesheetId');
    let hasTimesheetId = this.timesheetId && this.timesheetId != "";
    if (!hasTimesheetId) {
      this.alertIdError();
    }

  }

  public async componentDidMount() {
    if (this.props.userData.id && this.props.userData.id != "") {
      this.getData();
    }
    this.resizeObserver = new ResizeObserver((entries) => {
      if (this.lineupColRef.current && this.lineupColRef.current.offsetWidth != this.state.lineupColWidth) {
        this.setState({ lineupColWidth: this.lineupColRef.current.offsetWidth });
      }
    });
    if (this.lineupColRef.current) {
      this.resizeObserver.observe(this.lineupColRef.current);
    }
  }

  public async componentDidUpdate(prevProps: setLineupProps) {
    if (this.props.userData != prevProps.userData) {
      await this.getData();
    }
    if (this.lineupColRef.current) {
      this.resizeObserver.observe(this.lineupColRef.current);
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  public async getData() {
    if (this.timesheetId && this.timesheetId != "") {
      let timesheet = await getTimesheet(this.timesheetId, true, true);
      if (timesheet?.owner != this.props.user.uid && !this.props.userData.isAdmin) {
        this.alertIdError(true);
        return;  // don't populate data unless user is owner
      }
      if (timesheet) {
        this.timesheetTimezone = timesheet.timezone;
        if (this.emptyGrid.length == 0) {
          this.emptyGrid = this.getTimeGridData(timesheet);
        }
        let preferedNums = this.getAvailabilityNum(timesheet, "prefered");
        let availableNums = this.getAvailabilityNum(timesheet, "available");
        let userGrids: {
          user: {
            id: string;
            twitchUsername?: string;
            currentUser?: boolean;
            notes: string;
          };
          grid: TimeGridDataCell[];
        }[] = [];
        let currentUserSignup;
        for (let i = 0; i < timesheet.signups.signups.length; i++) {
          let signup = timesheet.signups.signups[i];
          let currentUser = signup.userId == this.props.user.uid;
          if (currentUser) {
            currentUserSignup = signup;
          }
          userGrids.push({
            user: {
              id: signup.userId,
              twitchUsername: signup.userTwitch,
              currentUser: currentUser,
              notes: signup.notes,
            },
            grid: this.getTimeGridData(timesheet, signup)
          });
        }
        let lineupGrid = this.getLineupData(timesheet, preferedNums, availableNums)
        this.setState({ submitting: false, timesheet: timesheet, lineupGrid: lineupGrid, usersGrid: userGrids, thisUserSignup: currentUserSignup || null });
      } else {
        this.alertIdError();
      }
    }
  }

  public alertIdError(ownerError: boolean = false) {
    if (ownerError) {
      confirmAlert({
        title: 'Access Denied',
        message: 'You must be the owner of the event to access this page.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => this.setState({ redirectTo: "/dashboard" })
          }
        ]
      });
    } else {
      confirmAlert({
        title: 'Invalid ID',
        message: 'Invalid Timesheet ID. The event may have been deleted.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => this.setState({ redirectTo: "/dashboard" })
          }
        ]
      });
    }
  }

  private getAvailabilityNum (timesheet: TimeSheet, availability: SignupStatus) {
    let availabilityData: number[] = [];
    if (timesheet && timesheet.start && timesheet.end) {
      let start = moment.tz(timesheet.start.toDate(), timesheet.timezone).local();
      let end = moment.tz(timesheet.end.toDate(), timesheet.timezone).local();
      let gridInterval = timesheet.interval; // time gridInterval of grid in minutes
      let numRows = (moment.duration(end.diff(start)).asMinutes() / gridInterval) + 1;
      for (let i = 0; i < numRows; i++) {
        let datetime = start.clone().local();
        datetime.add(i * gridInterval, 'minutes'); // .add adds time to the original datetime object
        let numStatus = 0;
        for (let u = 0; u < timesheet.signups.signups.length; u++) {
          let userSignup = timesheet.signups.signups[u];

          for (let s = 0; s < userSignup.availability.length; s++) {
            let userDataCell = userSignup.availability[s];
            if (userDataCell.status == availability) {
              let timeMatches = false;
              if (userDataCell.datetime instanceof Timestamp) {
                timeMatches = datetime.diff(moment.tz(userDataCell.datetime.toDate(), timesheet.timezone).local()) == 0;
              } else {
                timeMatches = datetime.diff(userDataCell.datetime.local()) == 0;
              }
              if (timeMatches) {
                numStatus++;
              }
            }
          }
        }
        availabilityData.push(numStatus);
      }
    }
    return availabilityData;
  }

  private getLineupData(timesheet: TimeSheet | null, preferednums: number[], availableNums: number[]): LineupGridCell[] {
    let lineupGridData: LineupGridCell[] = [];

    if (timesheet && timesheet.start && timesheet.end) {
      let start = moment.tz(timesheet.start.toDate(), timesheet.timezone).local();
      let end = moment.tz(timesheet.end.toDate(), timesheet.timezone).local();
      let gridInterval = timesheet.interval; // time gridInterval of grid in minutes
      let numRows = (moment.duration(end.diff(start)).asMinutes() / gridInterval) + 1;

      for (let i = 0; i < numRows; i++) {
        let datetime = start.clone().local();
        let time = datetime.add(i * gridInterval, 'minutes'); // .add adds time to the original datetime object
        let lineups = timesheet.lineup.lineups;
        let signups = timesheet.signups.signups;
        let thisSignups = [];

        for (const signup of signups) {
          let found = _.find(signup.availability, function(x: TimeGridDataCell) {
            let thisSignupDate = x.datetime;
            if (thisSignupDate instanceof Timestamp) {
              return datetime.diff(moment.tz(thisSignupDate.toDate(), timesheet.timezone).local()) == 0;
            } else {
              return datetime.diff(thisSignupDate.local()) == 0;
            }
          });
          if (found) {
            thisSignups.push({
                userId: signup.userId,
                userTwitch: signup.userTwitch,
                availability: found.status
            });
          }
        };
        
        let thisData: LineupGridCell = {
          timeslot: datetime,
          timeDisplay: time.tz(this.state.viewTimezone).format('h:mm a'),
          numberPrefered: preferednums[i],
          numberAvailable: availableNums[i],
          signups: thisSignups.length > 0 ? thisSignups : undefined
        }

        for (const lineup of lineups) {
          let found = _.find(lineup.timeslots, function(x: Moment | Timestamp) {
            if (x instanceof Timestamp) {
              return datetime.diff(moment.tz(x.toDate(), timesheet.timezone).local()) == 0;
            } else {
              return datetime.diff(x.local()) == 0;
            }
          });
          if (found) {
            thisData.userId = lineup.userId;
            thisData.userTwitch = lineup.userTwitch;
            thisData.lineupId = lineup.id
            break;
          }
        };

        lineupGridData.push(thisData);
      }
    }
    return lineupGridData;
  }

  private getTimeGridData(timesheet: TimeSheet | null, userSignup?: UserSignup, preferednums?: number[], availableNums?: number[]): TimeGridDataCell[] {
    let timeGridData: TimeGridDataCell[] = [];

    if (timesheet && timesheet.start && timesheet.end) {
      let start = moment.tz(timesheet.start.toDate(), timesheet.timezone).local();
      let end = moment.tz(timesheet.end.toDate(), timesheet.timezone).local();
      let gridInterval = timesheet.interval; // time gridInterval of grid in minutes
      let numRows = (moment.duration(end.diff(start)).asMinutes() / gridInterval) + 1;

      for (let i = 0; i < numRows; i++) {
        let datetime = start.clone().local();
        let time = datetime.add(i * gridInterval, 'minutes'); // .add adds time to the original datetime object
        let availability: SignupStatus = "unavailable";
        if (userSignup) {
          let found = _.find(userSignup.availability, function(x: TimeGridDataCell) {
            if (x.datetime instanceof Timestamp) {
              return datetime.diff(moment.tz(x.datetime.toDate(), timesheet.timezone).local()) == 0;
            } else {
              return datetime.diff(x.datetime.local()) == 0;
            }
          });
          if (found) {
            availability = found.status;
          }
        }
        let thisData: TimeGridDataCell = {
          datetime: datetime,
          timeDisplay: time.tz(this.state.viewTimezone).format('h:mm a'),
          numberPrefered: preferednums ? preferednums[i] : undefined,
          numberAvailable: availableNums ? availableNums[i] : undefined,
          status: availability
        }
        timeGridData.push(thisData);
      }
    }
    return timeGridData;
  }

  private updateSelectedRows(newValue: number[]) {
    this.setState({ selectedRows: newValue });
  }

  private updateSelectedUser(user: {id: string, twitchUsername?: string, currentUser?: boolean, notes: string}) {
    this.setState({ selectedUser: user });
  }

  private async updateLineupGrid(newValue: LineupGridCell[]) {
    if (this.timesheetId && !this.state.submitting) {
      this.setState({ submitting: true });
      let currentLineups: Lineup[] = await getLineupsByTimesheet(this.timesheetId);
      let currentLineupTimeslotCounts: iDictionary = {};
      let newLineupAdded: sDictionary = {};
        for (let i = 0; i < newValue.length; i++) {
          const cell = newValue[i];
          let isClear = !cell.userId;
          let cellUserSignedUpForSlot = _.find(cell.signups, function(signup: any) {
            return cell.userId == signup.userId;
          });
          if (cellUserSignedUpForSlot || isClear) {
          let thisUserLineup: Lineup | undefined;
          let foundUserId;
          let found;
          for (let j = 0; j < currentLineups.length; j++) {
            const lineup = currentLineups[j];
            if (!currentLineupTimeslotCounts[lineup.userId]) {
              currentLineupTimeslotCounts[lineup.userId] = lineup.timeslots.length;
            }
            
            if (cell.userId && cell.userId == lineup.userId) {
              thisUserLineup = lineup;
            }
            found = _.find(lineup.timeslots, function(x: Moment | Timestamp) {
              if (x instanceof Timestamp) {
                return cell.timeslot.diff(moment.default(x.toDate()).local()) == 0;
              } else {
                return cell.timeslot.diff(x.local()) == 0;
              }
            });

            if (found) {
              foundUserId = lineup.userId;
              let removedFromLineup = false;
              if ((cell.userId != lineup.userId || !cell.userId) && lineup.id) {
                await removeDateFromLineup(lineup.id, Timestamp.fromDate(cell.timeslot.toDate()));
                removedFromLineup = true;
                currentLineupTimeslotCounts[lineup.userId]--;
              }
              if (removedFromLineup && currentLineupTimeslotCounts[lineup.userId] == 0 && lineup.id) {
                await deleteLineup(lineup.id);
              }
            }
          }

          if (cell.userId && cell.userId != foundUserId) {
            if (newLineupAdded[cell.userId]) {
              await addDateToLineup(newLineupAdded[cell.userId], Timestamp.fromDate(cell.timeslot.toDate()));
            } else if (thisUserLineup && thisUserLineup.id) {
              await addDateToLineup(thisUserLineup.id, Timestamp.fromDate(cell.timeslot.toDate()));
            } else {
              let newLineupId = await setLineup({
                timesheetId: this.timesheetId,
                userId: cell.userId,
                userTwitch: cell.userTwitch,
                timeslots: [Timestamp.fromDate(cell.timeslot.toDate())]
              });
              newLineupAdded[cell.userId] = newLineupId;
            }
          }
        }
      }
      this.getData();
    }
  }

  public getUsersCol() {
    let content = [];
    for (let i = 0; i < this.state.usersGrid.length; i++) {
      let user = this.state.usersGrid[i].user;
      let grid = this.state.usersGrid[i].grid;
      content.push(<TimeGrid key={"otherUser"+i} selectedRows={this.state.selectedRows} updateSelectedRows={this.updateSelectedRows} gridData={grid} user={user} columnSelected={JSON.stringify(this.state.selectedUser) == JSON.stringify(user)} updateSelectedUser={this.updateSelectedUser} updateNotes={this.updateNotes} showTime />)
    }
    if (content.length < 20) {
      let numExtraCols = 20 - content.length;
      for (let i = 0; i < numExtraCols; i++) {
        let user = {id: "emptyColumn" + i, notes: ""};
        content.push(<TimeGrid key={"emptyCol"+i} selectedRows={this.state.selectedRows} updateSelectedRows={this.updateSelectedRows} gridData={this.emptyGrid} user={user} columnSelected={JSON.stringify(this.state.selectedUser) == JSON.stringify(user)} updateSelectedUser={this.updateSelectedUser} updateNotes={() => {}} />)
      }
    }
    return content;
  }

  
  private getStartDate() {
    if (this.state.lineupGrid.length > 0) {
      let startDate = this.state.lineupGrid[0].timeslot;
      let formatString = "ddd M/D/YY";
      if (startDate instanceof Timestamp) {
        return moment.default(startDate.toDate()).local().format(formatString);
      } else {
        return startDate.local().format(formatString);
      }
    } else {
      return "";
    }
  }

  private onScroll(event: any) {
    let scrollbarClassname = event.srcElement.parentNode.className;
    if (scrollbarClassname.includes("scrollbarSignupVertical")) {
      this.usersScrollbar.current.scrollTop(event.target.scrollTop);
    } else if (scrollbarClassname.includes("scrollbarSignupOtherUsers")) {
      this.lineupScrollbar.current.scrollTop(event.target.scrollTop);
    }
  }

  private onCollapseClick() {
    this.setState({lineupColCollapsed: !this.state.lineupColCollapsed});
  }

  private toggleTimezone() {
    if (this.state.viewTimezone == this.userTimezone) {
      this.setState({ viewTimezone: this.timesheetTimezone });
    } else {
      this.setState({ viewTimezone: this.userTimezone });
    }
    this.getData();
  }

  private async updateNotes(user: {
    id: string;
    twitchUsername?: string;
    currentUser?: boolean;
    notes: string;
  }) {
    let userSignup = this.state.thisUserSignup;
    if (userSignup && userSignup.id) {
      userSignup.notes = user.notes;
      await updateUserSignup(userSignup.id, userSignup);
      this.getData();
    }
  }

  public render(): React.ReactElement<setLineupProps> {
    let collapseBtnStyle = {
      right: this.state.lineupColCollapsed ? 0 : (this.state.lineupColWidth)
    };

    let lineupScrollbarStyle = {
      width: this.state.lineupColCollapsed ? 0 : this.state.lineupColWidth
    }

    return (
      <>
      <Container fluid>
        <Row>
          <Col className='titleCol noselect'>
            <CustomScrollbar addClass='scrollbarSignupTitle'>
              <h2 className='inlineH2'>{this.state.timesheet ? this.state.timesheet.title : ""}</h2>&nbsp;&nbsp;&nbsp;
              {this.getStartDate()}&nbsp;&nbsp;&nbsp;
              Timezone: <a className="linkBtn" onClick={this.toggleTimezone}>{this.state.viewTimezone}</a>&nbsp;&nbsp;&nbsp;
              { this.state.timesheet && this.state.timesheet.lineup.usersLinedUp.length == 0 &&
                <>
                  <Link to={"/signup?timesheetId=" + this.state.timesheet?.id} replace>Signup</Link>&nbsp;&nbsp;&nbsp;
                </>
              }
              { this.state.timesheet && this.state.timesheet?.lineup.usersLinedUp.length > 0 &&
                <Link to={"/viewLineup?timesheetId=" + this.state.timesheet?.id} replace>View Lineup</Link>
              }
            </CustomScrollbar>
          </Col>
        </Row>
        <Row className="signupGridRow">
          <Col className='containerCol noPadding'>
            <div className='flexContainer'>
              <div className='otherUsersCol noPadding'>
              <CustomScrollbar onScroll={this.onScroll} scrollbarRef={this.usersScrollbar} addClass={this.state.lineupColCollapsed ? "scrollbarSignupOtherUsers fullWidth" : "scrollbarSignupOtherUsers"}>
                {this.getUsersCol()}
              </CustomScrollbar>
              </div>
              <div className={this.state.lineupColCollapsed ? "lineupCol noPadding hide" : "lineupCol noPadding"}>
                <CustomScrollbar onScroll={this.onScroll} scrollbarRef={this.lineupScrollbar} addClass='scrollbarSignupVertical' style={lineupScrollbarStyle}>
                  <div ref={this.lineupColRef} style={{display: "inline-block"}}>
                    <LineupGrid selectedRows={this.state.selectedRows} updateSelectedRows={this.updateSelectedRows} gridData={this.state.lineupGrid} updateSelectedUser={this.updateSelectedUser} updateGridData={this.updateLineupGrid} />
                  </div>
                </CustomScrollbar>
              </div>
            </div>
            <div className="collapseBtn" style={collapseBtnStyle} onClick={this.onCollapseClick}>
              {this.state.lineupColCollapsed ? "<" : ">"}
            </div>
          </Col>
        </Row>
      </Container>
      <LineupBottomBar disabled={this.state.submitting} selectedRows={this.state.selectedRows} gridData={this.state.lineupGrid} updateGridData={this.updateLineupGrid} selectedUser={this.state.selectedUser} />
      { 
        this.state.redirectTo != '' && <Navigate to={this.state.redirectTo} replace={true}/>
      }
      </>
    );
  }
};