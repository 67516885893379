import React from 'react';
import ReactDOM from 'react-dom/client';
import './myNav.css';
import '../pages/global.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';
import { User } from 'firebase/auth';
import TSUser from '../../models/user';

type myNavProps = {
  user: User;
  userData: TSUser;
};
type myNavState = {
  authenticated: boolean;
  emailVerified: boolean;
  expanded: boolean;
};
export default class MyNav extends React.Component<myNavProps, myNavState> {

  constructor(props: myNavProps) {
    super(props);

    this.state = {
      authenticated: false,
      emailVerified: false,
      expanded: false
    };
  }

  public async componentDidMount() {

  }

  public async componentDidUpdate(prevProps: myNavProps) {
    let authenticated = this.props.user != null && this.props.user.emailVerified != undefined;
    let emailVerified = authenticated ? this.props.user.emailVerified : false;

    if (authenticated != this.state.authenticated || emailVerified != this.state.emailVerified) {
      this.setState({
        authenticated: authenticated,
        emailVerified: emailVerified
      });
    }
  }

  render() {

    return (
      <div className='navbarContainer'>
        <Navbar bg="light" expand="lg" expanded={this.state.expanded}>
          <Container>
            <LinkContainer to="/">
              <Navbar.Brand>Twitch Scheduler</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle onClick={() => this.setState({expanded: !this.state.expanded})} aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer hidden={this.state.authenticated} to="/">
                  <Nav.Link onClick={() => this.setState({ expanded: false })}>Home</Nav.Link>
                </LinkContainer>
                <LinkContainer hidden={!this.state.authenticated || !this.state.emailVerified} to="/dashboard">
                  <Nav.Link onClick={() => this.setState({ expanded: false })}>Dashboard</Nav.Link>
                </LinkContainer>
                <LinkContainer hidden={!this.state.authenticated || !this.state.emailVerified || !this.props.userData.isManager} to="/eventSetup">
                  <Nav.Link onClick={() => this.setState({ expanded: false })}>Create Event</Nav.Link>
                </LinkContainer>
              </Nav>
              <Nav className='justify-content-end'>
                <LinkContainer hidden={this.state.authenticated} to="/register">
                  <Nav.Link onClick={() => this.setState({ expanded: false })}>Register</Nav.Link>
                </LinkContainer>&nbsp;&nbsp;
                <LinkContainer hidden={this.state.authenticated} to="/login">
                  <Nav.Link onClick={() => this.setState({ expanded: false })}>Login</Nav.Link>
                </LinkContainer>&nbsp;&nbsp;
                <NavDropdown id="basic-nav-dropdown" hidden={!this.state.authenticated} title={
                  this.props.userData.twitchUsername != '' ? this.props.userData.twitchUsername : this.props.userData.email
                  }>
                  <LinkContainer hidden={!this.state.authenticated || this.state.emailVerified} to="/confirmEmail">
                    <NavDropdown.Item onClick={() => this.setState({ expanded: false })}>Confirm Email</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer hidden={!this.state.authenticated} to="/editTwitchUsername">
                    <NavDropdown.Item onClick={() => this.setState({ expanded: false })}>Edit Twitch Username</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer hidden={!this.state.authenticated} to="/logout" >
                    <NavDropdown.Item onClick={() => this.setState({ expanded: false })}>Logout</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
};