import React, { ChangeEvent, UIEventHandler } from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './signup.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as moment from 'moment-timezone';
import { Link, Navigate } from 'react-router-dom';
import TimeGrid from '../timeGrid/timeGrid';
import SignupBottomBar from '../signupBottomBar/signupBottomBar';
import TSUser, { addViewedTimesheet } from '../../models/user';
import { User } from 'firebase/auth';
import { confirmAlert } from 'react-confirm-alert';
import TimeSheet, { getTimesheet, removeUserSignedUp } from '../../models/timesheet';
import UserSignup, { TimeGridDataCell, SignupStatus, setUserSignup, getUserSignupsForUser, updateUserSignup, deleteUserSignup } from '../../models/userSignup';
import { Timestamp } from 'firebase/firestore';
import CustomScrollbar from '../customScrollbar/customScrollbar';

var _ = require('lodash');
export interface signupProps {
  user: User;
  userData: TSUser;
  refreshUserData: Function;
};
export interface signupState {
  thisUser: {
    id: string,
    twitchUsername?: string,
    currentUser?: boolean,
    notes: string,
  };
  thisUserSignup: UserSignup | null,
  userGrid: TimeGridDataCell[];
  otherUsersGrids: {
    user: {
      id: string;
      twitchUsername?: string;
      notes: string;
    };
    grid: TimeGridDataCell[];
  }[];
  preferedNums: number[];
  availableNums: number[];
  selectedRows: number[];
  selectedUser: {
    id: string;
    twitchUsername?: string;
    currentUser?: boolean;
    notes: string;
  };
  redirectTo: string;
  timesheet: TimeSheet | null;
  userColWidth: number;
  userColCollapsed: boolean;
  viewTimezone: string;
  submitting: boolean;
};

export default class Signup extends React.Component<signupProps, signupState> {
  timesheetId: string | null;
  emptyGrid: TimeGridDataCell[];
  resizeObserver: any;
  userColRef: any;
  otherUsersScrollbar: any;
  userScrollbar: any;
  userTimezone: string;
  timesheetTimezone: string;

  constructor(props: signupProps) {
    super(props);

    this.emptyGrid = [];
    this.userColRef = React.createRef();
    this.otherUsersScrollbar = React.createRef();
    this.userScrollbar = React.createRef();
    this.userTimezone = moment.tz.guess();
    this.timesheetTimezone = "";

    this.state = {
      thisUser: {
        id: this.props.user.uid,
        twitchUsername: this.props.userData.twitchUsername,
        currentUser: true,
        notes: ""
      },
      thisUserSignup: null,
      userGrid: [],
      otherUsersGrids: [],
      preferedNums: [],
      availableNums: [],
      selectedRows: [],
      selectedUser: {
        id: "",
        twitchUsername: "",
        notes: ""
      },
      redirectTo: "",
      timesheet: null,
      userColWidth: 0,
      userColCollapsed: false,
      viewTimezone: this.userTimezone,
      submitting: true,
    };

    this.getTimeGridData = this.getTimeGridData.bind(this);
    this.updateSelectedRows = this.updateSelectedRows.bind(this);
    this.updateSelectedUser = this.updateSelectedUser.bind(this);
    this.updateUserGrid = this.updateUserGrid.bind(this);
    this.alert = this.alert.bind(this);
    this.getData = this.getData.bind(this);
    this.getAvailabilityNum = this.getAvailabilityNum.bind(this);
    this.getOtherUsersCol = this.getOtherUsersCol.bind(this);
    this.getStartDate = this.getStartDate.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onCollapseClick = this.onCollapseClick.bind(this);
    this.toggleTimezone = this.toggleTimezone.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    
    let urlParams = new URLSearchParams(window.location.search);
    this.timesheetId = urlParams.get('timesheetId');
    let hasTimesheetId = this.timesheetId && this.timesheetId != "";
    if (!hasTimesheetId) {
      this.alert();
    }
  }

  public async componentDidMount() {
    if (this.props.userData.id && this.props.userData.id != "") {
      this.getData();
    }
    this.resizeObserver = new ResizeObserver((entries) => {
      if (this.userColRef.current && this.userColRef.current.offsetWidth != this.state.userColWidth) {
        this.setState({ userColWidth: this.userColRef.current.offsetWidth });
      }
    });
    if (this.userColRef.current) {
      this.resizeObserver.observe(this.userColRef.current);
    }
  }

  public async componentDidUpdate(prevProps: signupProps) {
    if (this.props.userData != prevProps.userData) {
      await this.getData();
    }
    if (this.props.userData.id && this.props.userData.id != "" && this.timesheetId && !this.props.userData.timesheetsVisited.includes(this.timesheetId)) {
      await addViewedTimesheet(this.props.user.uid, this.timesheetId);
      await this.props.refreshUserData();
    }
    if (this.userColRef.current) {
      this.resizeObserver.observe(this.userColRef.current);
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  public async getData() {
    if (this.timesheetId && this.timesheetId != "") {
      let timesheet = await getTimesheet(this.timesheetId, true);
      if (timesheet) {
        if (timesheet.lineup.usersLinedUp.length > 0) {
          this.alert("signupDisabled");
        }
        this.timesheetTimezone = timesheet.timezone;
        if (this.emptyGrid.length == 0) {
          this.emptyGrid = this.getTimeGridData(timesheet);
        }
        let preferedNums = this.getAvailabilityNum(timesheet, "prefered");
        let availableNums = this.getAvailabilityNum(timesheet, "available");
        let userId = this.props.user.uid;
        let userSignup = _.find(timesheet?.signups.signups, function(x: UserSignup) {
          if (x.userId == userId) {
            return true;
          }
        });
        
        if (!userSignup && this.state.thisUserSignup) {
          userSignup = this.state.thisUserSignup;
        } else if (!userSignup) {
          userSignup = {
            id: "",
            timesheetId: this.timesheetId,
            userId: userId,
            userTwitch: this.props.userData.twitchUsername,
            availablility: [],
            notes: ""
          }
        }
        
        let thisUser = {
          id: this.props.user.uid,
          twitchUsername: this.props.userData.twitchUsername,
          currentUser: true,
          notes: userSignup.notes || ''
        };

        let userGrid = this.getTimeGridData(timesheet, userSignup, preferedNums, availableNums);
        let otherUserGrids: {
          user: {
            id: string;
            twitchUsername?: string;
            notes: string;
          };
          grid: TimeGridDataCell[];
        }[] = [];
        for (let i = 0; i < timesheet.signups.signups.length; i++) {
          let signup = timesheet.signups.signups[i];
          if (signup.userId != userId) {
            otherUserGrids.push({
              user: {
                id: signup.userId,
                twitchUsername: signup.userTwitch,
                notes: signup.notes || ""
              },
              grid: this.getTimeGridData(timesheet, signup)
            });
          }
        }
        this.setState({ submitting: false, timesheet: timesheet, userGrid: userGrid, thisUser: thisUser, thisUserSignup: userSignup, otherUsersGrids: otherUserGrids });
      } else {
        this.alert();
      }
    }
  }

  public alert(type: string = "") {
    if (type == "signupDisabled") {
      confirmAlert({
        title: 'Signups Closed',
        message: 'The event is no longer accepting signups.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => this.setState({ redirectTo: "/dashboard" })
          }
        ]
      });
    } else {
      confirmAlert({
        title: 'Invalid ID',
        message: 'Invalid Timesheet ID. The event may have been deleted.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => this.setState({ redirectTo: "/dashboard" })
          }
        ]
      });
    }
  }

  private getAvailabilityNum (timesheet: TimeSheet, availability: SignupStatus) {
    let availabilityData: number[] = [];
    if (timesheet && timesheet.start && timesheet.end) {
      let start = moment.tz(timesheet.start.toDate(), timesheet.timezone).local();
      let end = moment.tz(timesheet.end.toDate(), timesheet.timezone).local();
      let gridInterval = timesheet.interval; // time gridInterval of grid in minutes
      let numRows = (moment.duration(end.diff(start)).asMinutes() / gridInterval) + 1;
      for (let i = 0; i < numRows; i++) {
        let datetime = start.clone().local();
        datetime.add(i * gridInterval, 'minutes'); // .add adds time to the original datetime object
        let numStatus = 0;
        for (let u = 0; u < timesheet.signups.signups.length; u++) {
          let userSignup = timesheet.signups.signups[u];

          for (let s = 0; s < userSignup.availability.length; s++) {
            let userDataCell = userSignup.availability[s];
            if (userDataCell.status == availability) {
              let timeMatches = false;
              if (userDataCell.datetime instanceof Timestamp) {
                timeMatches = datetime.diff(moment.tz(userDataCell.datetime.toDate(), timesheet.timezone).local()) == 0;
              } else {
                timeMatches = datetime.diff(userDataCell.datetime.local()) == 0;
              }
              if (timeMatches) {
                numStatus++;
              }
            }
          }
        }
        availabilityData.push(numStatus);
      }
    }
    return availabilityData;
  }

  private getTimeGridData(timesheet: TimeSheet | null, userSignup?: UserSignup, preferednums?: number[], availableNums?: number[]): TimeGridDataCell[] {
    let timeGridData: TimeGridDataCell[] = [];

    if (timesheet && timesheet.start && timesheet.end) {
      let start = moment.tz(timesheet.start.toDate(), timesheet.timezone).local();
      let end = moment.tz(timesheet.end.toDate(), timesheet.timezone).local();
      let gridInterval = timesheet.interval; // time gridInterval of grid in minutes
      let numRows = (moment.duration(end.diff(start)).asMinutes() / gridInterval) + 1;

      for (let i = 0; i < numRows; i++) {
        let datetime = start.clone().local();
        let time = datetime.add(i * gridInterval, 'minutes'); // .add adds time to the original datetime object
        let availability: SignupStatus = "unavailable";
        if (userSignup) {
          let found = _.find(userSignup.availability, function(x: TimeGridDataCell) {
            if (x.datetime instanceof Timestamp) {
              return datetime.diff(moment.tz(x.datetime.toDate(), timesheet.timezone).local()) == 0;
            } else {
              return datetime.diff(x.datetime.local()) == 0;
            }
          });
          if (found) {
            availability = found.status;
          }
        }
        let thisData: TimeGridDataCell = {
          datetime: datetime,
          timeDisplay: time.tz(this.state.viewTimezone).format('h:mm a'),
          numberPrefered: preferednums ? preferednums[i] : undefined,
          numberAvailable: availableNums ? availableNums[i] : undefined,
          status: availability
        }
        timeGridData.push(thisData);
      }
    }
    return timeGridData;
  }

  private updateSelectedRows(newValue: number[]) {
    this.setState({ selectedRows: newValue });
  }

  private updateSelectedUser(user: {id: string, twitchUsername?: string, currentUser?: boolean, notes: string}) {
    if (user.id == this.props.user.uid) {
      user = {
        id: "",
        notes: ""
      }
    }
    this.setState({ selectedUser: user });
  }

  private async updateUserGrid(newValue: TimeGridDataCell[]) {
    if (this.timesheetId && this.state.selectedRows.length > 0 && !this.state.submitting) {
      this.setState({ submitting: true });
      let userId = this.props.user.uid;
      let currentSignupList: UserSignup[] = await getUserSignupsForUser(userId, this.timesheetId);
      let currentSignup: UserSignup = currentSignupList[0];
      let strippedNewValue: TimeGridDataCell[] = [];
        newValue.forEach((item: TimeGridDataCell) => {
          if (item.status != "unavailable") {
            strippedNewValue.push(item);
          }
        });
        strippedNewValue.forEach((signup: TimeGridDataCell) => {
          signup.datetime = Timestamp.fromDate(signup.datetime.toDate());
          delete signup.numberAvailable;
          delete signup.numberPrefered;
          delete signup.timeDisplay;
        });
      if (currentSignup) {
        if (strippedNewValue.length == 0) {
          await deleteUserSignup(currentSignup.id ? currentSignup.id : "");
        } else {
          await updateUserSignup(currentSignup.id ? currentSignup.id : "", {availability: strippedNewValue});
        }
      } else {
        await setUserSignup({
          timesheetId: this.timesheetId,
          userId: userId,
          userTwitch: this.props.userData.twitchUsername,
          availability: strippedNewValue,
          notes: this.state.thisUser.notes
        });
      }
      this.setState({ thisUserSignup: null });
      this.getData();
    }
  }

  public getOtherUsersCol() {
    let content = [];
    for (let i = 0; i < this.state.otherUsersGrids.length; i++) {
      let user = this.state.otherUsersGrids[i].user;
      let grid = this.state.otherUsersGrids[i].grid;
      content.push(<TimeGrid key={"otherUser"+i} selectedRows={this.state.selectedRows} updateSelectedRows={this.updateSelectedRows} gridData={grid} user={user} columnSelected={JSON.stringify(this.state.selectedUser) == JSON.stringify(user)} updateSelectedUser={this.updateSelectedUser} updateNotes={() => {}} showTime />)
    }
    if (content.length < 20) {
      let numExtraCols = 20 - content.length;
      for (let i = 0; i < numExtraCols; i++) {
        let user = {id: "emptyColumn" + i, notes: ""};
        content.push(<TimeGrid key={"emptyCol"+i} selectedRows={this.state.selectedRows} updateSelectedRows={this.updateSelectedRows} gridData={this.emptyGrid} user={user} columnSelected={JSON.stringify(this.state.selectedUser) == JSON.stringify(user)} updateSelectedUser={this.updateSelectedUser} updateNotes={() => {}} />)
      }
    }
    return content;
  }

  
  private getStartDate() {
    if (this.state.userGrid.length > 0) {
      let startDate = this.state.userGrid[0].datetime;
      let formatString = "ddd M/D/YY";
      if (startDate instanceof Timestamp) {
        return moment.default(startDate.toDate()).local().format(formatString);
      } else {
        return startDate.local().format(formatString);
      }
    } else {
      return "";
    }
  }

  private onScroll(event: any) {
    let scrollbarClassname = event.srcElement.parentNode.className;
    if (scrollbarClassname.includes("scrollbarSignupVertical")) {
      this.otherUsersScrollbar.current.scrollTop(event.target.scrollTop);
    } else if (scrollbarClassname.includes("scrollbarSignupOtherUsers")) {
      this.userScrollbar.current.scrollTop(event.target.scrollTop);
    }
  }

  private onCollapseClick() {
    this.setState({userColCollapsed: !this.state.userColCollapsed});
  }

  private toggleTimezone() {
    if (this.state.viewTimezone == this.userTimezone) {
      this.setState({ viewTimezone: this.timesheetTimezone });
    } else {
      this.setState({ viewTimezone: this.userTimezone });
    }
    this.getData();
  }

  private async updateNotes(user: {
    id: string;
    twitchUsername?: string;
    currentUser?: boolean;
    notes: string;
  }) {
    let userSignup = this.state.thisUserSignup;
    if (userSignup) {
      userSignup.notes = user.notes;
      if (userSignup.id && userSignup.id != "") {
        await updateUserSignup(userSignup.id, userSignup);
        this.getData();
      } else {
        this.setState({ thisUser: user, thisUserSignup: userSignup });
      }
    }
  }

  public render(): React.ReactElement<signupProps> {
    let collapseBtnStyle = {
      right: this.state.userColCollapsed ? 0 : (this.state.userColWidth)
    };

    let userScrollbarStyle = {
      width: this.state.userColCollapsed ? 0 : this.state.userColWidth
    }

    return (
      <>
      <Container fluid>
        <Row>
          <Col className='titleCol noselect'>
            <CustomScrollbar addClass='scrollbarSignupTitle'>
            <h2 className='inlineH2'>{this.state.timesheet ? this.state.timesheet.title : ""}</h2>&nbsp;&nbsp;&nbsp;
              {this.getStartDate()}&nbsp;&nbsp;&nbsp;
              Timezone: <a className="linkBtn" onClick={this.toggleTimezone}>{this.state.viewTimezone}</a>&nbsp;&nbsp;&nbsp;
              { this.state.timesheet?.owner == this.props.user.uid &&
                <>
                  <Link to={"/setLineup?timesheetId=" + this.state.timesheet?.id} replace>Set Lineup</Link>&nbsp;&nbsp;&nbsp;
                </>
              }
              { this.state.timesheet && this.state.timesheet?.lineup.usersLinedUp.length > 0 &&
                <>
                  <Link to={"/viewLineup?timesheetId=" + this.state.timesheet?.id} replace>View Lineup</Link>&nbsp;&nbsp;&nbsp;
                </>
              }
            </CustomScrollbar>
          </Col>
        </Row>
        <Row className="signupGridRow">
          <Col className='containerCol noPadding'>
            <div className='flexContainer'>
              <div className='otherUsersCol noPadding'>
                <CustomScrollbar onScroll={this.onScroll} scrollbarRef={this.otherUsersScrollbar} addClass={this.state.userColCollapsed ? "scrollbarSignupOtherUsers fullWidth" : "scrollbarSignupOtherUsers"}>
                  {this.getOtherUsersCol()}
                </CustomScrollbar>
              </div>
              <div className={this.state.userColCollapsed ? "userCol noPadding hide" : "userCol noPadding"}>
                <CustomScrollbar onScroll={this.onScroll} scrollbarRef={this.userScrollbar} addClass='scrollbarSignupVertical' style={userScrollbarStyle}>
                  <div ref={this.userColRef} style={{display: "inline-block"}}>
                    <TimeGrid selectedRows={this.state.selectedRows} updateSelectedRows={this.updateSelectedRows} gridData={this.state.userGrid} user={this.state.thisUser} columnSelected={JSON.stringify(this.state.selectedUser) == JSON.stringify(this.state.thisUser)} updateSelectedUser={this.updateSelectedUser} updateNotes={this.updateNotes} showTime showSignupNumbers />
                  </div>
                </CustomScrollbar>
              </div>
            </div>
            <div className="collapseBtn" style={collapseBtnStyle} onClick={this.onCollapseClick}>
              {this.state.userColCollapsed ? "<" : ">"}
            </div>
          </Col>
        </Row>
      </Container>
      <SignupBottomBar disabled={this.state.submitting} selectedRows={this.state.selectedRows} gridData={this.state.userGrid} updateGridData={this.updateUserGrid} />
      { 
        this.state.redirectTo != '' && <Navigate to={this.state.redirectTo} replace={true}/>
      }
      </>
    );
  }
};