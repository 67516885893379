import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './dashboard.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import * as moment from 'moment-timezone';
import { Navigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import { confirmAlert } from 'react-confirm-alert';
import TSUser from '../../models/user';
import TimeSheet, { getAllTimesheets, getTimesheetsByOwner, deleteTimesheet, getTimesheetsById } from '../../models/timesheet';
import CustomScrollbar from '../customScrollbar/customScrollbar';

var _ = require('lodash');

type dashboardProps = {
  user: User;
  userData: TSUser;
};

type dashboardState = {
  timesheets: TimeSheet[];
  timesheetsSignupNow: TimeSheet[];
  timesheetsMySignups: TimeSheet[];
  timesheetsMyLineups: TimeSheet[];
  timesheetsMyEvents: TimeSheet[];
  submitting: boolean;
  success: string;
  error: string;
  redirectTo: string;
};

class Dashboard extends React.Component<dashboardProps, dashboardState> {
  constructor(props: dashboardProps) {
    super(props);
    
    this.state = {
      timesheets: [],
      submitting: false,
      success: '',
      error: '',
      redirectTo: '',
      timesheetsSignupNow: [],
      timesheetsMySignups: [],
      timesheetsMyLineups: [],
      timesheetsMyEvents: []
    };

    this.navigateTo = this.navigateTo.bind(this);
    this.getEventCards = this.getEventCards.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.deleteTimesheet = this.deleteTimesheet.bind(this);
  }
  
  public async componentDidMount() {
    if (this.props.userData.id && this.props.userData.id != "") {
      this.getTimesheets();
    }
  }

  public async componentDidUpdate(prevProps: dashboardProps) {
    if (this.props.userData != prevProps.userData) {
      await this.getTimesheets();
    }
  }

  public async getTimesheets() {
    let timesheets: TimeSheet[] = [];
    let viewedTimesheets: TimeSheet[] = [];
    if (this.props.userData.isAdmin) {
      timesheets = await getAllTimesheets();
    } else if (this.props.userData.isManager) {
      timesheets = await getTimesheetsByOwner(this.props.user.uid);
      viewedTimesheets = await getTimesheetsById(this.props.userData.timesheetsVisited);
    } else {
      viewedTimesheets = await getTimesheetsById(this.props.userData.timesheetsVisited);
    }
    timesheets = _.union(timesheets, viewedTimesheets);
    timesheets = this.orderTimesheets(timesheets);
    let timesheetsSignupNow: TimeSheet[] = [];
    let timesheetsMySignups: TimeSheet[] = [];
    let timesheetsMyLineups: TimeSheet[] = [];
    let timesheetsMyEvents: TimeSheet[] = [];
    for (let i = 0; i < timesheets.length; i++) {
      let thisTimesheet = timesheets[i];

      let signupEnabled = thisTimesheet.lineup.usersLinedUp.length == 0;
      
      if (thisTimesheet.id) {
        if (thisTimesheet.owner == this.props.user.uid) {
          timesheetsMyEvents.push(thisTimesheet);
        } else if (thisTimesheet.lineup.usersLinedUp.includes(this.props.user.uid)) {
          timesheetsMyLineups.push(thisTimesheet);
        } else if (signupEnabled && thisTimesheet.signups.usersSignedUp.includes(this.props.user.uid)) {
          timesheetsMySignups.push(thisTimesheet);
        } else if (signupEnabled && this.props.userData.timesheetsVisited.includes(thisTimesheet.id)) {
          timesheetsSignupNow.push(thisTimesheet);
        }
      }
      
    }
    if (timesheets != this.state.timesheets) {
      this.setState({
        timesheets: timesheets,
        timesheetsSignupNow: timesheetsSignupNow,
        timesheetsMySignups: timesheetsMySignups,
        timesheetsMyLineups: timesheetsMyLineups,
        timesheetsMyEvents: timesheetsMyEvents
      });
    }
  }

  public orderTimesheets(timesheets: TimeSheet[]) {
    timesheets.sort(function(a: TimeSheet, b: TimeSheet) {
      let momentA = moment.default(a.start?.toDate());
      let momentB = moment.default(b.start?.toDate());
      return momentA.diff(momentB);
    });
    return timesheets;
  }

  public navigateTo(route: string) {
    this.setState({redirectTo: route});
  }

  public getEventCards(timesheets: TimeSheet[]) {
    let items = [];
     for (let i = 0; i < timesheets.length; i++) {
        let thisTimesheet = timesheets[i];
        if (thisTimesheet.start != null && thisTimesheet.end != null) {
          let start = moment.tz(thisTimesheet.start.toDate(), thisTimesheet.timezone).local().format("M-D-YYYY h:mm A");
          let end = moment.tz(thisTimesheet.end.toDate(), thisTimesheet.timezone).local().format("M-D-YYYY h:mm A");
          let editSignup = thisTimesheet.signups.usersSignedUp.includes(this.props.user.uid);
          let editLineup = thisTimesheet.lineup.usersLinedUp.length > 0;
          items.push(
            <Card key={thisTimesheet.id} style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>{thisTimesheet.title}</Card.Title>
                <Card.Text>
                  <>
                  Start: {start}<br/>
                  End: {end}
                  </>
                </Card.Text>
                { !editLineup && 
                  <>
                    <Button variant="primary" onClick={() => this.navigateTo("/signup?timesheetId=" + thisTimesheet.id)}>{editSignup ? "Edit Signup" : "Sign Up"}</Button>&nbsp;&nbsp;
                  </>
                }
                { thisTimesheet && thisTimesheet?.lineup.usersLinedUp.length > 0 &&
                  <>
                    <Button variant="primary" onClick={() => this.navigateTo("/viewLineup?timesheetId=" + thisTimesheet.id)}>View Lineup</Button>&nbsp;&nbsp;
                  </>
                }
                { (this.props.userData.isAdmin || thisTimesheet.owner == this.props.user.uid) &&
                  <>
                  <Button variant="primary" onClick={() => this.navigateTo("/setLineup?timesheetId=" + thisTimesheet.id)}>{editLineup ? "Edit Lineup" : "Set Lineup"}</Button>&nbsp;&nbsp;
                  <Button variant="danger"  onClick={() => this.confirmDelete(thisTimesheet.id)}>Delete</Button>
                  </>
                }
              </Card.Body>
            </Card>
          );
        }
     }
     return items;
  }

  public confirmDelete(id: string | null | undefined) {
    if (id && id != "") {
      confirmAlert({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete the selected event?',
        buttons: [
          {
            label: 'Delete',
            onClick: () => this.deleteTimesheet(id)
          },
          {
            label: 'Cancel'
          }
        ]
      });
    } else {
      alert("Error: Timesheet does not have an id.");
    }
  }

  public async deleteTimesheet(id: string | null | undefined) {
    if (id && id != "") {
      this.setState({ submitting: true });
      try {
        let timesheetSuccess = '';
        let timesheets = this.state.timesheets;
        await deleteTimesheet(id);
        timesheetSuccess = "Your timesheet has been deleted!";
        this.setState({submitting: false, success: timesheetSuccess, timesheets: timesheets});
        this.getTimesheets();
      } catch (err: any) {
        this.setState({ error: err.message, submitting: false });
      }
    } else {
      alert("Error: Timesheet does not have an id.");
    }
  }


  render() {
    return (
      <>
      <CustomScrollbar addClass='scrollbarDefault'>
      <Container className="topPagePadding bottomPagePadding">
        <Row className="justify-content-center">
          <Col className="large floatCol">
            <Alert variant="success" hidden={this.state.success == ''} onClose={() => this.setState({ success: '' })} dismissible>
                {this.state.success}
            </Alert>
            <Alert variant="danger" hidden={this.state.error == ''} onClose={() => this.setState({ error: '' })} dismissible>
                {this.state.error}
            </Alert><br/>
            { this.props.userData.isAdmin && this.state.timesheets.length == 0 &&
              <>
                <h2>No Events</h2>
                <p>There are currently no events on the site.</p>
              </>
            }
            {!this.props.userData.isAdmin && this.state.timesheetsSignupNow.length == 0 && this.state.timesheetsMySignups.length == 0 && this.state.timesheetsMyLineups.length == 0 && this.state.timesheetsMyEvents.length == 0 &&
              <>
                <h2>No Events</h2>
                <p>Visit a signup link from an event manager {this.props.userData.isManager ? "or create an event yourself" : ""} to get started.</p>
              </>
            }
            { this.state.timesheetsSignupNow.length > 0 &&
              <>
                <h2>Sign Up Now!</h2><br/>
                {this.getEventCards(this.state.timesheetsSignupNow)}<br/><br/>
              </>
            }
            { this.state.timesheetsMySignups.length > 0 &&
              <>
                <h2>My Signups</h2><br/>
                {this.getEventCards(this.state.timesheetsMySignups)}<br/><br/>
              </>
            }
            { this.state.timesheetsMyLineups.length > 0 &&
              <>
                <h2>My Lineups</h2><br/>
                {this.getEventCards(this.state.timesheetsMyLineups)}<br/><br/>
              </>
            }
            { this.state.timesheetsMyEvents.length > 0 &&
              <>
                <h2>My Events</h2><br/>
                {this.getEventCards(this.state.timesheetsMyEvents)}<br/><br/>
              </>
            }
            { this.props.userData.isManager && 
              <>
                <Button variant="primary" type="button" onClick={() => this.navigateTo("/eventSetup")}>
                  Create Event
                </Button>
              </>
            }
            { this.props.userData.isAdmin && this.state.timesheets.length > 0 &&
              <>
                <br/><br/><h2>All Site Timesheets (Admin Only)</h2><br/>
                {this.getEventCards(this.state.timesheets)}<br/><br/>
              </>
            }
          </Col>
        </Row>
      </Container>
      </CustomScrollbar>
      { 
        this.state.redirectTo != '' && <Navigate to={this.state.redirectTo} replace={true}/>
      }
      </>
    );
  }
}

export default Dashboard;