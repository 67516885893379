import firebase from 'firebase/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getFunctions } from 'firebase/functions';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAy8tN4m3udbnpfFq1M3slU9oNdTcrwGgM",
  authDomain: "twitch-scheduler.com",
  projectId: "twitch-scheduler-748f5",
  storageBucket: "twitch-scheduler-748f5.appspot.com",
  messagingSenderId: "866214824992",
  appId: "1:866214824992:web:23a3b6741e286651534045",
  measurementId: "G-3DK3DSXXT5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
export const auth = getAuth(app);
export default app;
