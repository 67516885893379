import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import { doc, getDocs, setDoc, getDoc, updateDoc, query, where, deleteDoc } from 'firebase/firestore';
import { userSignupsCol } from '../services/database';
import TSUser from './user';
import { Timestamp } from 'firebase/firestore';
import TimeSheet, { addUserSignedUp, removeUserSignedUp } from './timesheet';

export type SignupStatus = "unavailable" | "available" | "prefered";

export interface TimeGridDataCell {
  datetime: Timestamp | Moment;
  timeDisplay?: string;
  numberPrefered?: number;
  numberAvailable?: number;
  status: SignupStatus;
}

export default interface UserSignup {
  id?: string;
  timesheetId: string;
  userId: string;
  userTwitch: string;
  availability: TimeGridDataCell[];
  notes: string;
}

export const getUserSignup = async (id: string) => {
  const signupRef = doc(userSignupsCol, id);
  const signupSnap = await getDoc(signupRef);

  if (signupSnap.exists()) {
    return signupSnap.data();
  } else {
    return null;
  }
}

export const getUserSignupsByTimesheet = async (id: string) => {
  const q = query(userSignupsCol, where("timesheetId", "==", id));
  let signupList: UserSignup[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    signupList.push(doc.data());
  });
  return signupList;
}

export const getUserSignupsForUser = async (userId: string, timesheetId?: string) => {
  let q;
  if (timesheetId) {
    q = query(userSignupsCol, where("userId", "==", userId), where("timesheetId", "==", timesheetId));
  } else {
    q = query(userSignupsCol, where("userId", "==", userId));
  }
  let signupList: UserSignup[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    signupList.push(doc.data());
  });
  return signupList;
}

export const setUserSignup = async (data: UserSignup) => {
  const signupRef = doc(userSignupsCol);
  data.id = signupRef.id;
  await setDoc(signupRef, data);
  await addUserSignedUp(data.timesheetId, data.userId);
  return signupRef.id;
}

export const updateUserSignup = async (id: string, data: {}) => {
  const signupDocRef = doc(userSignupsCol, id)
  await updateDoc(signupDocRef, data);
  return signupDocRef.id;
}

export const updateTwitchOnSignups = async (userId: string, twitchUsername: string) => {
  let q = query(userSignupsCol, where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, {userTwitch: twitchUsername});
  });
}

export const deleteUserSignup = async (id: string) => {
  const signupDocRef = doc(userSignupsCol, id);
  const signupDoc = await getDoc(signupDocRef);
  if (signupDoc.exists()) {
    await removeUserSignedUp(signupDoc.data().timesheetId, signupDoc.data().userId);
    await deleteDoc(signupDocRef);
  }
}

export const deleteUserSignups = async (id: string) => {
  let q = query(userSignupsCol, where("userId", "==", id));
  const querySnapshot = await getDocs(q);
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    let doc = querySnapshot.docs[i];
    if (doc.exists()) {
      await removeUserSignedUp(doc.data().timesheetId, id);
      await deleteDoc(doc.ref);
    }
  }
}

export const deleteTimesheetSignups = async (timesheetId: string) => {
  let q = query(userSignupsCol, where("timesheetId", "==", timesheetId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    deleteDoc(doc.ref);
  });
}